import { CompanyTypeModel } from './../../model/company-types-model';
import { CompanyDetailsModel } from './../../model/company-details-model';
import { ValidationError } from './../../validators/validation-error';
import { Component, OnInit } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/providers/auth-service';
import { CommonService } from 'src/app/providers/common-service';
import { HandleRequestError } from 'src/app/providers/handle-request-error';
import { CompanyManagementService } from 'src/app/providers/company-management-service';

@Component({
  selector: 'app-manage-company-page',
  templateUrl: './manage-company-page.component.html',
  styleUrls: ['./manage-company-page.component.css']
})
export class ManageCompanyPageComponent implements OnInit {

  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService,
    private authService: AuthService, private modalService: BsModalService, private companyMgmtService: CompanyManagementService,
    private commonService: CommonService, private handleReqErr: HandleRequestError) { }

    adminCompanyId: number;
    adminCompanyName: string;
    userRole: number;
    companyNameError: string;

    editingCompanyName: string;


    ngOnInit() {
      this.isEditCompany = this.modalService.config.initialState['isEditCompany'];
      this.editingCompanyName = this.modalService.config.initialState['editingCompanyName'];
      this.CompanyId = this.modalService.config.initialState['CompanyId'];
      this.TypeId = this.modalService.config.initialState['TypeId'];

      this.adminCompanyId = this.authService.userDetails.companyId;
      this.adminCompanyName = this.authService.userDetails.companyName;
      this.userRole = this.authService.userDetails.userRole;

      this.company = new CompanyDetailsModel();

      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.getAllCompanyType());
      if (this.isEditCompany){
        baseDataCalls.push(this.getCompanyDetailsById());
      }

      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.iziToast.error({
          title: 'Error occurred',
          message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
          position: 'center',
          timeout: 3000,
          close: true,
          overlay: true
        });
        //  this.handleReqErr.handlePageLoadError(e);
      });
    }

    company: CompanyDetailsModel;
    errors: string[] = [];
    CompanyId: number;
    TypeId: number;
    validationError: ValidationError = new ValidationError();
    isEditCompany: boolean = true;
    companytype: CompanyTypeModel[];

    getCompanyDetailsById() {
      return new Promise(((resolve, reject) => {
        this.companyMgmtService.getCompanyDetailsById(this.CompanyId).subscribe((res) => {
          console.log(res);
          this.company = new CompanyDetailsModel();
          this.company['CompanyId'] = res['CompanyId'];
          this.company['CompanyName'] = res['CompanyName'];
          this.company['CompanyAddress'] = res['CompanyAddress'];
          this.company['CompanyCity'] = res['CompanyCity'];
          this.company['CompanyState'] = res['CompanyState'];
          this.company['CompanyDomain'] = res['CompanyDomain'];
          this.company['CompanyEmailID'] = res['CompanyEmailID'];
          this.company['CompanyPhoneNumber'] = res['CompanyPhoneNumber'];
          this.company['CompanyZipCode'] = res['CompanyZipCode'];
          this.company['Status'] = res['Status'];
          this.company['TypeId'] = res['TypeId'];
          this.company['TypeName'] = res['TypeName'];
          resolve(true);
        }, (err) => {
          reject(err);
        });
      }));
    }

    getAllCompanyType() {
      return new Promise(((resolve, reject) => {
        this.commonService.getAllCompanyTypes().subscribe((res) => {
          console.log(res);
          this.companytype = res;
          resolve(true);
        }, (err) => {
          reject(err);
        });
      }));
    }

    getCompanyName() {
      this.companyNameError = null;
      this.validateFields();
      if(this.isEditCompany && this.company.CompanyName === this.editingCompanyName){
        return;
      }
      if (this.company.CompanyName) {
        this.authService.checkExistingDuplicateCompanyName(this.company.CompanyName).subscribe((res) => {
          if (res && res['CompanyName']) {
            console.log(res);
            this.showCompanyNameChangeInfo = true;
          } else {
            this.showCompanyNameChangeInfo = false;
          }
        }, (err) => {
          this.spinner.hide();
          this.validationError = this.handleReqErr.handleReqError(err, this.errors);
        });
      }
    }

    showCompanyNameChangeInfo = false;

    toggleShowCompanyNameChangeInfo(){
      this.showCompanyNameChangeInfo = !this.showCompanyNameChangeInfo;
    }

    submitAttempted = false;

    validateFields() {
      if (!this.submitAttempted){
        return;
      }
      this.validationError = new ValidationError();
      let err = this.company.validateAddCompany();
      if (err && Object.keys(err).length > 0) {
        this.validationError = err;
      }
    }

    addCompany(close: boolean) {
      this.submitAttempted = true;
      this.errors = [];
      this.validationError = new ValidationError();
      let err = this.company.validateAddCompany();
      if (err && Object.keys(err).length > 0) {
        this.validationError = err;
        return;
      }

      this.spinner.show();
      this.companyMgmtService.addCompany(this.company).subscribe((res) => {
        this.spinner.hide();

        this.iziToast.success({
          title: 'New Company Added',
          message: 'New Company Added Successfully.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.company = new CompanyDetailsModel();
        this.submitAttempted = false;
        if (close) {
          this.bsModalRef.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.validationError = this.handleReqErr.handleReqError(err, this.errors);
      });
    }

    updateCompany() {
      this.submitAttempted = true;
      this.errors = [];
      this.validationError = new ValidationError();
      let err = this.company.validateOnUpdateCompany();
      if (err && Object.keys(err).length > 0) {
        this.validationError = err;
        return;
      }
      console.log('1');
      this.spinner.show();
      this.companyMgmtService.updateCompany(this.company).subscribe((res) => {
        this.spinner.hide();

        this.iziToast.success({
          title: 'Company Details Updated',
          message: 'Company Details Updated Successfully',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.bsModalRef.hide();
      }, (err) => {
        this.spinner.hide();
        this.validationError = this.handleReqErr.handleReqError(err, this.errors);
      });
    }

    close(){
      this.bsModalRef.hide();
    }
}
