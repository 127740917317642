import {Component, OnInit, Optional} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginRequestModel} from '../../model/login-request-model';
import {ValidationError} from '../../validators/validation-error';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {AuthService} from '../../providers/auth-service';
import {LocalDataService} from '../../providers/local-data-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {Router} from '@angular/router';
import {LoginPageComponent} from '../login-page/login-page.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-verify-mfa-page',
  templateUrl: './verify-mfa-page.component.html',
  styleUrls: ['./verify-mfa-page.component.css']
})
export class VerifyMfaPageComponent implements OnInit {

  loginReq: LoginRequestModel;
  verificationCode: number;
   errors: string[] = [];
   info: string[] = [];
  validationError: ValidationError = new ValidationError();

  constructor(@Optional() public bsModalRef: BsModalRef, private modalService: BsModalService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private localDataService: LocalDataService,  private router: Router,
              private handleReqErr: HandleRequestError) { }

  ngOnInit() {
    this.loginReq = new LoginRequestModel();
    if(!this.authService.inSignUpProcess){
      this.router.navigate(['/auth','login']);
    }
  }
  closeMFA(){
    this.bsModalRef.hide();
  }
  verifyCode(){
    if(this.verificationCode){
      this.loginReq.verificationCode = ''+this.verificationCode;
    }
    this.errors = [];
    this.info = [];
    this.validationError = new ValidationError();
    let err = this.loginReq.validateVerificationCode();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.loginReq.verificationCode = this.loginReq.verificationCode.trim();
    this.spinner.show();
    this.authService.verifyCode(this.loginReq).subscribe((res)=>{
      if (res && res.token) {
        if(res.status === environment.moduleStatus.UserManagementActive){
          this.authService.setToken(res.token);
          this.router.navigate(['/home']);
        }else{
          this.router.navigate(['/auth','login']);
        }

        this.spinner.hide();
      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  resendVerificationCode(){
    this.errors = [];
    this.info = [];
    this.spinner.show();
    this.authService.resendVerificationCode().subscribe((res)=>{
      this.info.push('New Verification Code is sent to your registered Email. Please check it.');
      this.spinner.hide();
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

}
