import {Component, OnInit} from '@angular/core';
import {RosterCrewDetailsModel} from '../../model/roster-crew-details-model';
import {ValidationError} from '../../validators/validation-error';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {UserManagementService} from '../../providers/user-mgmt-service';
import {CommonService} from '../../providers/common-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {RosterService} from '../../providers/roster-service';
import {ProfileModel} from '../../model/profile-model';

@Component({
  selector: 'app-modify-crew-details-page',
  templateUrl: './modify-crew-details-page.component.html',
  styleUrls: ['./modify-crew-details-page.component.css']
})
export class ModifyCrewDetailsPageComponent implements OnInit {

  crewDetails: RosterCrewDetailsModel;

  genders: string[] = ['Male', 'Female'];
  status = [{label:'Active', value:true},{label:'In-Active',value:false}];
  yesNo: string[] = ['Yes', 'No'];
  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  isEditCrew: boolean = false;
  rosterCrewDetailsId: number;
  rosterId: number;
  companyEventId: number;
  rosterStatus: number;
  teamTypeValues: string[] = ["Damage Assessors","Line","Logisitics","Network UG","Other","Safety","Service","Storm Management","Substation","Transmission","Vegetation"];
  resourceTypeValues: string[] = ["Call Center Support","Communications Support","Crew Lead","Damage Assessor","Damage Assessor Driver","Damage Assessor Lead","Dispatcher Support","Equipment Operator","Finance Support","Fleet Mechanic","General Foreman","IT Support","Line - Apprentice","Line - Foreman","Line - Groundman","Line - Journeyman","Line - Mechanic","Line - Support","Line - UG Lineman","Logistics Support","Manager","Material Support","Network Lineman","Other","Pilot","Planning Support","Safety Support","Staging Site Manager","Staging Site Support","Substation","Supervisor","Support","Team Lead","Trans Lineman","Vegetation - Foreman","Vegetation - Groundman","Vegetation - Mechanic","Vegetation - Operator","Vegetation - Safety","Vegetation - Support","Vegetation - Trimmer","Wire Guards"];


  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService,
              private authService: AuthService, private modalService: BsModalService, private rosterService: RosterService,
              private commonService: CommonService, private handleReqErr: HandleRequestError) {
  }

  ngOnInit() {
    this.crewDetails = new RosterCrewDetailsModel();
    this.isEditCrew = this.modalService.config.initialState['isEditCrew'];
    this.rosterCrewDetailsId = this.modalService.config.initialState['rosterCrewDetailsId'];
    this.rosterId = this.modalService.config.initialState['rosterId'];
    this.companyEventId = this.modalService.config.initialState['companyEventId'];
    this.rosterStatus = this.modalService.config.initialState['rosterStatus'];

    this.crewDetails.CompanyEventId = this.companyEventId;
    if (this.isEditCrew) {
      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.fetchAllRosterCrewsDetails());
      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.iziToast.error({
          title: 'Error occurred',
          message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
          position: 'center',
          timeout: 3000,
          close: true,
          overlay: true
        });
      });
    }else{
      this.crewDetails.RosterId = this.rosterId;
    }
  }


  fetchAllRosterCrewsDetails() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchRosterCrewsDetails(this.rosterCrewDetailsId).subscribe((res) => {
        console.log(res);
        if (res) {
          this.crewDetails.mapDetails(res);
          resolve(true);
        } else {
          reject(false);
        }
      }, (err) => {
        reject(err);
      });
    }));
  }


  submitAttempted = false;

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    if (this.isEditCrew) {
      err = this.crewDetails.validateUpdate();
    } else {
      err = this.crewDetails.validateInsert();
    }
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }

  addCrew(close: boolean) {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.crewDetails.validateInsert();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.prepareTeamAndCrewLead();
    this.spinner.show();
    this.rosterService.addCrew(this.crewDetails).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'New Crew Added',
        message: 'New Crew added successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.crewDetails = new RosterCrewDetailsModel();
      this.crewDetails.RosterId = this.rosterId;
      this.submitAttempted = false;
      if(close){
        this.bsModalRef.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  prepareTeamAndCrewLead(){
    if(this.crewDetails.TeamLeadTxt === 'Yes'){
      this.crewDetails.TeamLead = true;
    }else{
      this.crewDetails.TeamLead = false;
    }

    if(this.crewDetails.CrewLeadTxt === 'Yes'){
      this.crewDetails.CrewLead = true;
    }else{
      this.crewDetails.CrewLead = false;
    }
  }


  updateCrew() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.crewDetails.validateUpdate();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.prepareTeamAndCrewLead();
    this.spinner.show();
    this.rosterService.updateCrew(this.crewDetails).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Crew Details Updated',
        message: 'Crew Details Updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.crewDetails = new RosterCrewDetailsModel();
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }
}
