import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[inputFocus]'
})
export class InputFocusDirective {
  public constructor(private el: ElementRef) {

  }

  public ngAfterContentInit() {

    setTimeout(() => {

      this.el.nativeElement.focus();

    }, 100);

  }
}
