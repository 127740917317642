import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {RosterService} from '../../providers/roster-service';
import {AuthService} from '../../providers/auth-service';
import {CommonService} from '../../providers/common-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {CommonUtil} from '../../util/common-util';
import {TicketService} from '../../providers/ticket-service';
import {Subscription} from 'rxjs';
import {
  TicketCrewModel,
  TicketDeleteCloseRequestModel, TicketDocument,
  TicketModel,
  TicketSearchModel,
  TicketWACModel,
  TicketWorkLocationModel
} from '../../model/ticket-model';
import {CompanyEventModel, DynamicField, DynamicFieldMapper} from '../../model/company-event-model';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {UploadRosterPageComponent} from '../upload-roster-page/upload-roster-page.component';
import {CreateTicketPageComponent} from '../create-ticket-page/create-ticket-page.component';
import {UpdateTicketPageComponent} from '../update-ticket-page/update-ticket-page.component';
import {Router} from '@angular/router';
import {TicketDocumentViewerPageComponent} from '../ticket-document-viewer-page/ticket-document-viewer-page.component';
import {environment} from '../../../environments/environment';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {StatusDetailsModel} from '../../model/status-details-model';
import {MasterEventModel} from '../../model/master-event-model';
import {RosterModel} from '../../model/roster-model';
import {CompanyDetailsModel} from '../../model/company-details-model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-tickets-page',
  templateUrl: './tickets-page.component.html',
  styleUrls: ['./tickets-page.component.css']
})
export class TicketsPageComponent implements OnInit {

  ticketSearch: TicketSearchModel;
  ticketModuleStatus: StatusDetailsModel[];
  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }
  bsModalRef: BsModalRef;
  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  tickets: TicketModel[];
  ticketsRows: TicketModel[];
  selected: TicketModel[] = [];

  disableEditBtn = true;
  disableAssignBtn = true;
  disableDeleteBtn = true;
  disableCloseBtn = true;

  showCreateTicket = false;
  showAssignCrew = false;
  showEditTicket = false;
  showDeleteTicket = false;
  showCloseTicket = false;
  showUploadTicket = false;
  showWACFilter = false;

  checkAndEnableModifyOption(){
    if(this.userRole === environment.userRoles.SuperAdministrator
      || this.userRole === environment.userRoles.Administrator
      || this.userRole === environment.userRoles.TicketAssignmentCoordinator
      || this.userRole === environment.userRoles.SupportPersonnel
    ){
      this.showCreateTicket = true;
      this.showDeleteTicket = true;
      this.showCloseTicket = true;
      this.showEditTicket = true;
      this.showUploadTicket = true;
      this.showWACFilter = true;
    }else if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator) {
      this.showEditTicket = true;
      this.showAssignCrew = true;
    }
  }
  prepareRosterName(rosterId) {
    return this.commonUtil.prepareRosterName(rosterId);
  }
  getRowClass(row): any {
    return {
      'disabled-row': row['Status'] === environment.moduleStatus.TicketingClosed || row['Deleted'] === true || row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }
  getClosedTicketCheckboxClass({row, column, value}): any {
    return {
      'hide-checkbox':  row['Status'] === environment.moduleStatus.TicketingClosed || row['Deleted'] === true || row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }
  modalRef: BsModalRef;
  deleteTicket(template: TemplateRef<any>){
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static'
    });
  }
  ticketCrew: TicketCrewModel[];
  ticketCrewRows: TicketCrewModel[];
  ticket: TicketModel;
  ticketDocuments: TicketDocument[];
  fetchTicketDocuments(ticketId) {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDocuments(ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchTicketDetails(ticketId) {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDetails(ticketId).subscribe((res) => {
        this.ticket = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  showTicketCrew(ticketId: number, template: TemplateRef<any>){
    this.spinner.show();
    this.fetchTicketDetails(ticketId).then((r)=>{
      return this.fetchTicketDocuments(ticketId);
    }).then((r2)=>{
      this.ticketService.fetchTicketCrews(ticketId).subscribe((res) => {
        this.ticketCrew = res;
        let ttc : TicketCrewModel[] = [];
        this.spinner.hide();
        if (this.ticketCrew && this.ticketCrew.length > 0) {
          for (let tc of this.ticketCrew) {
            if (tc.PrimaryCrew) {
              tc.PrimaryCrewSelectedValue = tc.RosterId + '-' + tc.RosterCrewDetailsId;
            }
            ttc.push(tc);
          }
          this.ticketCrewRows = [...ttc];
          this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static'
          });
        }else{
          this.ticketCrewRows = [];
          this.ticketCrew = [];
          this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static'
          });
        }


      }, (err) => {
        this.spinner.hide();
        if (err.status === 422 || err.status === 400) {
          if (err.error.message) {
            this.errors.push(err.error.message);
          } else {
            this.handleReqErr.handleReqError(err, this.validationError);
          }
        } else {
          this.handleReqErr.handlePageLoadError(err);
        }
      });
    }).catch((err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    })

  }

  deleteReason: string;

  confirmDeleteTicket(){
    this.submitAttempted = true;
    this.validateFields();
    if (this.validationError && (this.validationError['deleteReason'])) {
      return;
    }
    this.spinner.show();
    let ticketDeleteReq: TicketDeleteCloseRequestModel = new TicketDeleteCloseRequestModel();
    ticketDeleteReq.ticketIds = [];
    for(let tr of this.selected){
      ticketDeleteReq.ticketIds.push(tr.TicketId);
    }
    ticketDeleteReq.deleteReason = this.deleteReason;
    this.ticketService.deleteTicket(ticketDeleteReq).subscribe((res)=>{
      this.spinner.hide();
      this.modalRef.hide();
      this.iziToast.success({title: 'Ticket deleted', message: 'Ticket deleted successfully!.', position: 'topRight'});
      this.loadData();
    }, (err)=>{
      this.spinner.hide();
      if (err.status === 422 || err.status === 400) {
        if (err.error.message) {
          this.errors.push(err.error.message);
        } else {
          this.handleReqErr.handleReqError(err, this.validationError);
        }
      } else {
        this.handleReqErr.handlePageLoadError(err);
      }
    })
  }
  confirmCloseTicket(){
    this.spinner.show();
    let ticketCloseReq: TicketDeleteCloseRequestModel = new TicketDeleteCloseRequestModel();
    ticketCloseReq.ticketIds = [];
    for(let tr of this.selected){
      ticketCloseReq.ticketIds.push(tr.TicketId);
    }
    this.ticketService.closeTicket(ticketCloseReq).subscribe((res)=>{
      this.spinner.hide();
      this.iziToast.success({title: 'Ticket closed', message: 'Ticket closed successfully!.', position: 'topRight'});
      this.loadData();
    }, (err)=>{
      this.spinner.hide();
      if (err.status === 422 || err.status === 400) {
        if (err.error.message) {
          this.errors.push(err.error.message);
        } else {
          this.handleReqErr.handleReqError(err, this.validationError);
        }
      } else {
        this.handleReqErr.handlePageLoadError(err);
      }
    })
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;

  validateFields(){
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredString(this.validationError, 'deleteReason', this.deleteReason, 'Delete Reason');
    if (this.validationError && (this.validationError['deleteReason'])) {
      return;
    }
  }
  closeTicket(){
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Close the Ticket? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.confirmCloseTicket();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private ticketService: TicketService,
              private handleReqErr: HandleRequestError, private authService: AuthService, private router: Router, private rosterService: RosterService,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
              private masterEventService: MasterEventService, private companyEventService: CompanyEventsService,
  ) {

  }
  ticketClosedStatusId = environment.moduleStatus.TicketingClosed;
  companyEventClosedStatusId = environment.moduleStatus.CompanyEventClosed;
  ngOnInit() {
    this.ticketSearch = new TicketSearchModel();
    this.ticketSearch.TicketStatus = [];
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingUnassigned);
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingWACAssigned);
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingCrewAssigned);
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingVerifyRestored);
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingUnableToWork);
    this.ticketSearch.TicketStatus.push(environment.moduleStatus.TicketingHeld);

    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  loadData(){
    this.disableEditBtn = true;
    this.disableAssignBtn = true;

    this.disableDeleteBtn = true;
    this.disableCloseBtn = true;
    this.deleteReason = null;
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkAndEnableModifyOption();
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];

    baseDataCalls.push(this.getModuleStatus());
    baseDataCalls.push(this.fetchAllCompanyEventsForMasterEvent());
    baseDataCalls.push(this.fetchAllMasterEvents());
    baseDataCalls.push(this.fetchAllTicketsForCompanyWithFilter());
    baseDataCalls.push(this.fetchAllNonReleasedTicketsDynamicFields());

    /*baseDataCalls.push(this.fetchAllRespondingCompaniesForCompanyEvents());
    baseDataCalls.push(this.fetchAllWorkLocationForCompanyEvents());
    baseDataCalls.push(this.fetchAllWACForCompanyEvents());*/
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  resetSearch(){
    this.ticketSearch = new TicketSearchModel();
    this.loadData();
  }
  searchTickets(){
    this.loadData();
  }

  dynamicFieldMapper: {[key:string]: DynamicFieldMapper} = {};

  fetchCompanyEventLinkedDynamicFields(companyEventId){
    return new Promise((resolve, reject) => {
      this.ticketService.fetchAllDynamicFieldsOfTicketOfCompanyEvent(companyEventId).subscribe((res) => {
        console.log(res);
        if(res && res.length > 0){
          this.dynamicFieldMapper = {};
          for(let df of res){
            if(df.DynamicFieldValue && ''+df.DynamicFieldValue.trim() !== ''){
              if(this.dynamicFieldMapper[''+df.DynamicFieldId]){
                if(this.dynamicFieldMapper[''+df.DynamicFieldId].dynamicFieldValues.indexOf(df.DynamicFieldValue) === -1){
                  this.dynamicFieldMapper[''+df.DynamicFieldId].dynamicFieldValues.push(df.DynamicFieldValue);
                }
              }else{
                this.dynamicFieldMapper[''+df.DynamicFieldId] = new DynamicFieldMapper();
                this.dynamicFieldMapper[''+df.DynamicFieldId].DynamicFieldName = df.DynamicFieldName;
                this.dynamicFieldMapper[''+df.DynamicFieldId].dynamicFieldValues = [];
                this.dynamicFieldMapper[''+df.DynamicFieldId].dynamicFieldValues.push(df.DynamicFieldValue);
              }
            }

          }
          this.ticketSearch.dynamicFields = [];
          for(let dfId of Object.keys(this.dynamicFieldMapper)){
            let df = new DynamicField();
            df.DynamicFieldId = parseInt(dfId);
            df.DynamicFieldName = this.dynamicFieldMapper[dfId].DynamicFieldName;
            df.DynamicFieldValues = this.dynamicFieldMapper[dfId].dynamicFieldValues;
            this.ticketSearch.dynamicFields.push(df);
          }
        }
        resolve(true);
      },(err)=>{
        console.log(err);
        reject(err);
      });
    });

  }
  getModuleStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('Ticketing').subscribe((res) => {
        console.log(res);
        this.ticketModuleStatus = res;

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  masterEvents: MasterEventModel[];
  fetchAllMasterEvents() {
    return new Promise(((resolve, reject) => {
      this.masterEventService.fetchAllMasterEvents().subscribe((res) => {
        console.log(res);
        this.masterEvents = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  companyEvents: CompanyEventModel[];
 /* fetchAllRostersForCreateTicket() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllRostersForCreateTicket().subscribe((res) => {
        console.log(res);
        if (res) {
          this.prepareCompanyEventIDAndName(res);
        } else {
          this.companyEvents = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }*/

 onMasterEventChange(){
   this.ticketSearch.CompanyEventIds = [];
   this.ticketSearch.RespondingCompanyIds = [];
   this.ticketSearch.WACIds = [];
   this.ticketSearch.WorkLocations = [];
   this.fetchAllCompanyEventsForMasterEvent();

 }

 onCompanyEventChange(){
   this.ticketSearch.RespondingCompanyIds = [];
   this.ticketSearch.WACIds = [];
   this.ticketSearch.WorkLocations = [];
   this.fetchAllRespondingCompaniesForCompanyEvents();
   this.fetchAllWACForCompanyEvents();
   this.fetchAllWorkLocationForCompanyEvents();
   if(this.ticketSearch.CompanyEventIds.length === 1){
     this.fetchCompanyEventLinkedDynamicFields(this.ticketSearch.CompanyEventIds[0]);
   }else{
     this.ticketSearch.dynamicFields = [];
   }
 }



  fetchAllCompanyEventsForMasterEvent() {

    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchAllCompanyEventsForMasterEvent(this.ticketSearch.MasterEventIds).subscribe((res) => {
        console.log(res);

        if(res){
          this.prepareCompanyEventIDAndName(res);
        }else{
          this.companyEvents = [];
        }
        this.fetchAllRespondingCompaniesForCompanyEvents();
        this.fetchAllWACForCompanyEvents();
        this.fetchAllWorkLocationForCompanyEvents();
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  respondingCompanies: CompanyDetailsModel[];
  fetchAllRespondingCompaniesForCompanyEvents() {
    let tComEventId = [];
    if(!this.ticketSearch.CompanyEventIds || this.ticketSearch.CompanyEventIds.length === 0 ){
      if(this.companyEvents && this.companyEvents.length > 0){
        for(let ce of this.companyEvents){
          tComEventId.push(ce.CompanyEventId)
        }
      }
    }else{
      tComEventId = this.ticketSearch.CompanyEventIds;
    }
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchAllRespondingCompaniesForCompanyEvents(tComEventId).subscribe((res) => {
        console.log(res);
        if(res){
          this.respondingCompanies = res;
        }else{
          this.respondingCompanies = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  ticketWACs: TicketWACModel[];
  fetchAllWACForCompanyEvents() {
    this.ticketWACs = [];
    let tComEventId = [];
    if(!this.ticketSearch.CompanyEventIds || this.ticketSearch.CompanyEventIds.length === 0 ){
      if(this.companyEvents && this.companyEvents.length > 0){
        for(let ce of this.companyEvents){
          tComEventId.push(ce.CompanyEventId)
        }
      }
    }else{
      tComEventId = this.ticketSearch.CompanyEventIds;
    }
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllWACForCompanyEvents(tComEventId).subscribe((res) => {
        console.log(res);
        if(res){
          this.ticketWACs = res;
        }else{
          this.ticketWACs = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  ticketWorkLocations: TicketWorkLocationModel[];
  fetchAllWorkLocationForCompanyEvents() {
    this.ticketWorkLocations = [];
    let tComEventId = [];
    if(!this.ticketSearch.CompanyEventIds || this.ticketSearch.CompanyEventIds.length === 0 ){
      if(this.companyEvents && this.companyEvents.length > 0){
        for(let ce of this.companyEvents){
          tComEventId.push(ce.CompanyEventId)
        }
      }
    }else{
      tComEventId = this.ticketSearch.CompanyEventIds;
    }
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllWorkLocationForCompanyEvents(tComEventId).subscribe((res) => {
        console.log(res);
        if(res){
          this.ticketWorkLocations = res;
        }else{
          this.ticketWorkLocations = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareCompanyEventIDAndName(companyEvents: CompanyEventModel[]){
    this.companyEvents = [];
    let formattedCEIdAndNames = [];
    for(let ce of companyEvents){
      let formattedCEIdAndName = '';
      if(ce.CompanyEventName && ce.CompanyEventName !== 'null' && ce.CompanyEventName.trim() !== ''){
        formattedCEIdAndName = this.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.CompanyEventName;
      }else{
        formattedCEIdAndName = this.prepareCompanyEventName(ce.CompanyEventId);
      }

      if(formattedCEIdAndNames.indexOf(formattedCEIdAndName) === -1){
        let c = new CompanyEventModel();
        c.CompanyEventId = ce.CompanyEventId;
        c.FormattedCompanyEventIDAndName = formattedCEIdAndName;
        this.companyEvents.push(c);
        formattedCEIdAndNames.push(formattedCEIdAndName);
      }
    }
  }


  fetchAllTicketsForCompanyWithFilter() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllTicketsForCompanyWithFilter(this.ticketSearch).subscribe((res) => {
        console.log(res);
        if(res){
          this.tickets = res;
          this. prepareTickets();
          this.ticketsRows = [...this.tickets];
        }else{
          this.tickets = [];
          this.ticketsRows = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareTickets(){
    if(!this.tickets || this.tickets.length <=0){
      return;
    }
    for(let te of this.tickets){
      te['adminCompanyName'] = this.adminCompanyName;
      te['adminCompanyId'] = this.adminCompanyId;
    }
  }

  assignCrew(){
    this.router.navigate(['home','tickets','assignCrew',this.selected[0].TicketId]);
  }
  createMultipleTickets(){
    this.router.navigate(['home','tickets','multiTickets']);
  }
  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.tickets.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.ticketsRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  //  this.table.selected = [];

    this.disableEditBtn = true;
    this.disableAssignBtn = true;
    this.disableDeleteBtn = true;
    this.disableCloseBtn = true;
  }


  checkFilter(d:TicketModel, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    let primaryFieldFilter = this.matchContent(d.EventName, val) || this.matchContent(this.prepareTicketName(d.TicketId), val) ||
      this.matchContent(this.prepareCompanyEventName(d.CompanyEventId), val) || this.matchContent(d.RespondingCompanyName, val) ||
      this.matchContent(d.WACName, val) || this.matchContent(d.CompanyEventName, val) || this.matchContent(d.RequestingCompanyName, val) ||
      this.matchContent(d.StatusValue, val) || this.matchContent(d.WorkLocation, val) ||
      !val;

    let matchInDynamicField = false;
    let dynamicFields: DynamicField[] = this.ticketDynamicFieldMapping[d.TicketId];
    if (dynamicFields && dynamicFields.length > 0) {
      for (let dynamicField of dynamicFields) {
        if (this.matchContent(dynamicField.DynamicFieldValue, val)) {
          matchInDynamicField = true;
          break;
        }
      }
    }
    return primaryFieldFilter || matchInDynamicField;

  }

  prepareTicketName(ticketId){
    return this.commonUtil.prepareTicketName(ticketId);
  }

  prepareCompanyEventName(companyEventId){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }
  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  getTicketStatusClass({row, column, value}): any {
    return {
      'ticket-unassigned': row['Status'] === environment.moduleStatus.TicketingUnassigned,
      'ticket-WACAssigned': row['Status'] === environment.moduleStatus.TicketingWACAssigned,
      'ticket-CrewAssigned': row['Status'] === environment.moduleStatus.TicketingCrewAssigned,
      'ticket-Held': row['Status'] === environment.moduleStatus.TicketingHeld,
      'ticket-VerifyRestored': row['Status'] === environment.moduleStatus.TicketingVerifyRestored,
      'ticket-UnableToWork': row['Status'] === environment.moduleStatus.TicketingUnableToWork,
      'ticket-Closed': row['Status'] === environment.moduleStatus.TicketingClosed,
    };
  }

  getTicketIdStatusClass({row, column, value}): any {
    return {
      'ticket-br-unassigned': row['Status'] === environment.moduleStatus.TicketingUnassigned,
      'ticket-br-WACAssigned': row['Status'] === environment.moduleStatus.TicketingWACAssigned,
      'ticket-br-CrewAssigned': row['Status'] === environment.moduleStatus.TicketingCrewAssigned,
      'ticket-br-Held': row['Status'] === environment.moduleStatus.TicketingHeld,
      'ticket-br-VerifyRestored': row['Status'] === environment.moduleStatus.TicketingVerifyRestored,
      'ticket-br-UnableToWork': row['Status'] === environment.moduleStatus.TicketingUnableToWork,
      'ticket-br-Closed': row['Status'] === environment.moduleStatus.TicketingClosed,
    };
  }

  createTicket(){
    this.bsModalRef = this.modalService.show(CreateTicketPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static'
    });
  }
  editTicket(){
    this.bsModalRef = this.modalService.show(UpdateTicketPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {
        ticketId: this.selected[0].TicketId
      },
      backdrop: 'static'
    });
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.tickets) {
      if (this.selected.length === this.tickets.length) {
        this.iziToast.warning({
          title: 'All Tickets selected',
          message: 'You have selected all the Tickets. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    this.disableEditBtn = true;
    this.disableAssignBtn = true;
    this.disableDeleteBtn = true;
    this.disableCloseBtn = true;
    if (this.selected.length === 1) {
      if((this.selected[0].RequestingCompanyId === this.authService.userDetails.companyId)){
        this.disableEditBtn = false;

        this.disableDeleteBtn = false;
        this.disableCloseBtn = false;
      }
      if(this.authService.userDetails.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
        this.disableEditBtn = false;
      }
      this.disableAssignBtn = false;

      if(this.selected[0].CompanyEventStatus === environment.moduleStatus.CompanyEventClosed ||
        this.selected[0].Status === environment.moduleStatus.TicketingClosed ||
        this.selected[0].Deleted
      ){
        this.disableEditBtn = true;
        this.disableAssignBtn = true;
        this.disableDeleteBtn = true;
        this.disableCloseBtn = true;
      }
     /* if((this.selected[0].RespondingCompanyId === this.authService.userDetails.companyId)){
        this.disableAssignBtn = false;
      }*/

    }else{
      if(this.selected.length > 1){
        if(this.authService.userDetails.userRole !== environment.userRoles.WorkloadAssignmentCoordinator){
          let tDelBtn = true;
          let tCloBtn = true;
          for(let tt of this.selected){

            if((tt.RequestingCompanyId !== this.authService.userDetails.companyId)){
              tDelBtn = false;
              tCloBtn = false;
            }

            if(tt.CompanyEventStatus === environment.moduleStatus.CompanyEventClosed ||
              tt.Status === environment.moduleStatus.TicketingClosed ||
              tt.Deleted
            ){
              tDelBtn = false;
              tCloBtn = false;
              break;
            }
            /*if(this.authService.userDetails.userRole !== environment.userRoles.WorkloadAssignmentCoordinator){
              tCloBtn = false;
            }*/
          }
          this.disableDeleteBtn = !tDelBtn;
          this.disableCloseBtn = !tCloBtn;
        }
      }
    }

  }
  documentSrc: any;
  bsModalRefViewDocument: BsModalRef;
  viewTicketDocument(ticketId: number){
    this.spinner.show();
    this.ticketService.fetchTicketDocuments(ticketId).subscribe((res) => {
      let ticketDocuments = res;
      if( ticketDocuments && ticketDocuments.length > 0){
        this.bsModalRefViewDocument = this.modalService.show(TicketDocumentViewerPageComponent, {
          class: 'modal-dialog-centered modal-lg second',
          initialState: {
            ticketDocumentId: ticketDocuments[0]['TicketDocumentId'],
            ticketDocumentName: ticketDocuments[0]['OriginalFileName']
          },
          backdrop: 'static'
        });
      }else{
        this.iziToast.warning({
          title: 'No Documents found',
          message: 'No Documents found for the ticket.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.iziToast.error({
        title: 'Error getting Documents',
        message: 'Error while getting the documents, please try again.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
    });

  }


  downloadTicketDocument(ticketId: number) {
    this.spinner.show();
    this.ticketService.fetchTicketDocuments(ticketId).subscribe((res) => {
      let ticketDocuments = res;
      if(ticketDocuments && ticketDocuments.length > 0){
        this.ticketService.fetchTicketDocument(ticketDocuments[0]['TicketDocumentId']).subscribe((res) => {
          console.log(res);
          this.createAndDownloadBlobFile(res, ticketDocuments[0]['OriginalFileName']);
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          if(err.status === 404){
            this.iziToast.error({
              title: 'File Missing',
              message: 'Requested file is not available to download.',
              position: 'center', timeout: 2000
            });
          }else{
            this.iziToast.error({
              title: 'Error getting Documents',
              message: 'Error while getting the documents, please try again.',
              position: 'topRight',
              timeout: 5000,
              close: true,
              overlay: false
            });
            console.log(err);
          }
        });

      }else{
        this.iziToast.warning({
          title: 'No Documents found',
          message: 'No Documents found for the ticket.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.iziToast.error({
        title: 'Error getting Documents',
        message: 'Error while getting the documents, please try again.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
    });


  }

  createAndDownloadBlobFile(body, filename) {
    console.log(body);
   // var blob = new Blob([body], {type: 'application/octet-stream'});
    saveAs(body, filename);

    /*if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link['download'] !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }*/
  }

  dynamicFieldColumns = [];
  ticketDynamicFieldMapping: { [key: string]: DynamicField[] } = {};
  dynamicFields: DynamicField[];

  fetchAllNonReleasedTicketsDynamicFields() {
    return new Promise(((resolve, reject) => {
      //TODO
      this.ticketService.fetchAllTicketsDynamicFieldsForCompanyWithFilter(this.ticketSearch).subscribe((res) => {
        console.log(res);
        if (res) {
          this.dynamicFields = res;
        } else {
          this.dynamicFields = [];
        }
        this.dynamicFieldColumns = [];
        this.ticketDynamicFieldMapping = {};
        let maxColumns = 0;
        if (this.dynamicFields.length > 0) {
          for (let dynamicField of this.dynamicFields) {
            if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId]) {
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
              if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length > maxColumns) {
                maxColumns = this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length;
              }
            } else {
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId] = [];
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
            }
          }
        }
        for (let i = 1; i <= maxColumns; i++) {
          this.dynamicFieldColumns.push('Dynamic Field ' + i);
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchDynamicFieldNameOfTicketInIndex(ticketId, index) {
    if (this.ticketDynamicFieldMapping['' + ticketId] && this.ticketDynamicFieldMapping['' + ticketId].length > index) {
      return this.ticketDynamicFieldMapping['' + ticketId][index].DynamicFieldName;
    } else {
      return '';
    }
  }

  fetchDynamicFieldValueOfTicketIdInIndex(ticketId, index) {
    if (this.ticketDynamicFieldMapping['' + ticketId] && this.ticketDynamicFieldMapping['' + ticketId].length > index) {
      return this.ticketDynamicFieldMapping['' + ticketId][index].DynamicFieldValue;
    } else {
      return '';
    }
  }
}
