import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {RosterModel} from '../../model/roster-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {ActivatedRoute} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {RosterEquipmentDetailsModel} from '../../model/roster-equipment-details-model';
import {ModifyEquipmentDetailsPageComponent} from '../modify-equipment-details-page/modify-equipment-details-page.component';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../providers/auth-service';

@Component({
  selector: 'app-roster-equipment-details-page',
  templateUrl: './roster-equipment-details-page.component.html',
  styleUrls: ['./roster-equipment-details-page.component.css']
})
export class RosterEquipmentDetailsPageComponent implements OnInit, OnDestroy {

  isCompanyEventClosed =false;
  isRosterApproved = false;
  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }

  showEditEqipBtn: boolean = false;
  showDeleteEqipBtn: boolean = false;
  showAddEqipBtn: boolean = false;

  rosterEquipments: RosterEquipmentDetailsModel[];
  rosterEquipmentRows: RosterEquipmentDetailsModel[];
  selected: RosterEquipmentDetailsModel[] = [];
  rosterId: number;
  bsModalRef: BsModalRef;
  roster: RosterModel;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError,
              private commonService: CommonService, private modalService: BsModalService,
              private route: ActivatedRoute
  ) {
    this.route.params.subscribe( (params) => {
      this.rosterId = params['rosterId'];
      this.loadData();
    });
  }

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  loadData() {
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableDeleteBtn = true;
    this.showEditEqipBtn = false;
    this.showDeleteEqipBtn = false;
    this.showAddEqipBtn = false;
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllRosterEquipments());
    baseDataCalls.push(this.fetchRosterDetails());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }


  fetchAllRosterEquipments() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllRosterEquipments(this.rosterId).subscribe((res) => {
        console.log(res);
        if(res){
          this.rosterEquipments = this.prepareRosterEquipmentDetails(res);
          this.rosterEquipmentRows = [...this.rosterEquipments];
        }else{
          this.rosterEquipments = [];
          this.rosterEquipmentRows = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }
  fetchRosterDetails() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
        console.log(res);
        this.roster = res;
        this.isCompanyEventClosed =  (this.roster.CompanyEventStatus === environment.moduleStatus.CompanyEventClosed);
        this.isRosterApproved = (this.roster.Status === environment.moduleStatus.RosterApproved);
        /*if(this.roster && this.roster.Status === environment.moduleStatus.RosterInitiated){
          this.disableApproveBtn = false;
        }*/
        if(this.roster.Status === environment.moduleStatus.RosterApproved && this.adminCompanyId === this.roster.RequestingCompanyId){
          this.showEditEqipBtn = true;
          this.showDeleteEqipBtn = false;
          this.showAddEqipBtn = true;
        }else if(this.roster.Status === environment.moduleStatus.RosterInitiated){
          this.showEditEqipBtn = true;
          this.showDeleteEqipBtn = true;
          this.showAddEqipBtn = true;
        }else if(this.roster.Status === environment.moduleStatus.RosterReleased && this.adminCompanyId === this.roster.RespondingCompanyID ){
          this.showEditEqipBtn = true;
          this.showDeleteEqipBtn = true;
          this.showAddEqipBtn = true;
        }else{
          this.showEditEqipBtn = false;
          this.showDeleteEqipBtn = false;
          this.showAddEqipBtn = false;
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareRosterEquipmentDetails(equipments){
    let tempEquipments = [];
    if(!equipments){
      return tempEquipments;
    }
    for(let tc of equipments){
      let c = new RosterEquipmentDetailsModel();
      c.mapDetails(tc);
      tempEquipments.push(c);
    }
    return tempEquipments;
  }
  disableDeleteBtn = true;
  disableEditBtn = true;
  disableApproveBtn = true;
  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.rosterEquipments.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.rosterEquipmentRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableDeleteBtn = true;
  }


  checkFilter(d: RosterEquipmentDetailsModel, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.EquipmentId, val) || this.matchContent(d.EquipmentType, val) ||
      this.matchContent(d.EquipmentDesc, val) || this.matchContent(d.EquipmentFuelType, val) ||
      this.matchContent(d.AssignedEquipmentCrewID, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.rosterEquipments) {
      if (this.selected.length === this.rosterEquipments.length) {
        this.iziToast.warning({
          title: 'All Equipments selected',
          message: 'You have selected all the Equipments. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    /*let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      if (sel.Status === 1 && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      if (sel.Status === 2 && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }*/

    if (this.selected.length === 1) {
      this.disableEditBtn = false;
    } else {
      this.disableEditBtn = true;
    }

    /* if (this.selected.length > 0) {
       this.disableActivateBtn = !allInActiveUsers;
       this.disableInActivateBtn = !allActiveUsers;
     } else {
       this.disableActivateBtn = true;
       this.disableInActivateBtn = true;
     }*/

    let enableDeleteButton = true;
    for (let sel of this.selected) {
      /*if (sel.CompanyEventCounts > 1) {
        enableDeleteButton = false;
        break;
      }*/
    }
    if (this.selected.length > 0) {
      this.disableDeleteBtn = !enableDeleteButton;
    } else {
      this.disableDeleteBtn = true;
    }

  }



  deleteEquipment() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Delete the Roster Equipment? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onDeleteConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onDeleteConfirm() {
    this.spinner.show();
    let rcdIds = [];
    for (let sel of this.selected) {
      rcdIds.push(sel.RosterEquipmentDetailsId);
    }
    this.rosterService.deleteRosterEquipmentsDetails(rcdIds.join(','), this.roster.RosterId).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({title: 'Equipment Deleted',
        message: 'Equipment deleted successfully.', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false});
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  addEquipment() {
    this.bsModalRef = this.modalService.show(ModifyEquipmentDetailsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditEquipment: false, rosterId: this.rosterId},
      backdrop: 'static'
    });
  }


  editEquipment() {
    this.bsModalRef = this.modalService.show(ModifyEquipmentDetailsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditEquipment: true, rosterEquipmentDetailsId: this.selected[0].RosterEquipmentDetailsId},
      backdrop: 'static'
    });
  }

  close(){
    this.bsModalRef.hide();
  }


  /*deleteRosterEquipmentsDetails() {
    this.spinner.show();
    this.rosterService.deleteRosterEquipmentsDetails(this.selected[0].RosterEquipmentDetailsId).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({
        title: 'Equipment Deleted',
        message: 'Equipment deleted successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);

    });
  }*/


  /*approveRosterEvent() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Approve the Roster? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onApproveConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }*/

  /*onApproveConfirm() {
    this.spinner.show();
    /!*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*!/
    this.rosterService.approveRoster(this.rosterId).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({title: 'Roster Approved', message: 'Roster Approved Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false});
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }*/


}
