// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `this.environment.ts` with `this.environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  moduleStatus: {
    UserManagementActive: 1,
    UserManagementInActive: 2,
    UserManagementRegistered: 3,
    UserManagementLocked: 4,

    MasterEventActive: 5,
    MasterEventInActive: 6,
    MasterEventClosed: 16,

    CompanyEventActive: 7,
    CompanyEventInActive: 8,
    CompanyEventClosed: 17,

    RosterInitiated: 9,
    RosterApproved: 10,
    RosterReleased: 11,
    RosterReturnToHome : 18,
    RosterClosed : 22,

    TicketingUnassigned: 12,
    TicketingWACAssigned: 13,
    TicketingCrewAssigned: 19,
    TicketingHeld: 14,
    TicketingVerifyRestored: 15,
    TicketingUnableToWork: 20,
    TicketingClosed: 21,
  },
  userRoles: {
    SuperAdministrator: 1,
    Administrator: 2,
    ResourceUnitLead: 3,
    OnboardingResourceCoordinator: 4,
    WorkloadAssignmentCoordinator: 5,
    TicketAssignmentCoordinator: 6,
    SupportPersonnel: 7,
    FieldResource: 8,
    UnAssigned: 9
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
