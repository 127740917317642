import { Component, OnInit } from '@angular/core';
import {StatusDetailsModel} from '../../model/status-details-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {LocalDataService} from '../../providers/local-data-service';
import {Router} from '@angular/router';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {RosterService} from '../../providers/roster-service';

@Component({
  selector: 'app-modify-roster-details-page',
  templateUrl: './modify-roster-details-page.component.html',
  styleUrls: ['./modify-roster-details-page.component.css']
})
export class ModifyRosterDetailsPageComponent implements OnInit {
  status:number;
  rosterName:string;
  pageStatus:string;
  rosterId:number;

  rosterStatus: StatusDetailsModel[];

  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private modalService: BsModalService,
              private spinner: NgxSpinnerService, private rosterService: RosterService,
              private localDataService: LocalDataService, private router: Router,
              private handleReqErr: HandleRequestError, private commonService: CommonService) {
  }
  ngOnInit() {

    this.rosterName = this.modalService.config.initialState['rosterName'];
    this.pageStatus = this.modalService.config.initialState['pageStatus'];
    this.rosterId = this.modalService.config.initialState['rosterId'];
    this.status = this.modalService.config.initialState['status'];

    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchRosterStatus());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });

  }


  fetchRosterStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('Roster').subscribe((res) => {
        console.log(res);
        this.rosterStatus = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;
  validateFields(){
    if(!this.submitAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'status', this.status, 'Roster status');
    if (this.validationError && this.validationError['status']) {
      return;
    }
  }


  updateRoster() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'status', this.status, 'Roster status');
    if (this.validationError && this.validationError['status']) {
      return;
    }
    this.spinner.show();
    this.rosterService.updateRoster(this.rosterId, this.status, this.rosterName).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Updated Roster',
        message: 'Roster details updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }
}
