import { Component, OnInit } from '@angular/core';
import {ValidationError} from '../../validators/validation-error';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {RosterService} from '../../providers/roster-service';
import {CommonService} from '../../providers/common-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {RosterEquipmentDetailsModel} from '../../model/roster-equipment-details-model';

@Component({
  selector: 'app-modify-equipment-details-page',
  templateUrl: './modify-equipment-details-page.component.html',
  styleUrls: ['./modify-equipment-details-page.component.css']
})
export class ModifyEquipmentDetailsPageComponent implements OnInit {

  equipmentDetails: RosterEquipmentDetailsModel;

  genders: string[] = ['Male', 'Female', 'Others'];
  yesNo: string[] = ['Yes', 'No'];
  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  isEditEquipment: boolean = false;
  rosterEquipmentDetailsId: number;
  rosterId: number;

  fuelTypeValues: string[] = ["Unleaded","Diesel","Other","Not Applicable"];
  equipmentTypeValues: string[] = ["Backhoe","Bucket Truck - Service","Bucket Truck - Distribution","Bucket Truck - Transmission","Chipper","Digger Derrick","Digger Derrick-rear lot","Equipment Trailer","Knuckle Boom Truck","Pickup","Pole Hauler","Pole Trailer","Service Truck","Specialty Equipment"];

  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService,
              private authService: AuthService, private modalService: BsModalService, private rosterService: RosterService,
              private commonService: CommonService, private handleReqErr: HandleRequestError) {
  }

  ngOnInit() {
    this.equipmentDetails = new RosterEquipmentDetailsModel();
    this.isEditEquipment = this.modalService.config.initialState['isEditEquipment'];
    this.rosterEquipmentDetailsId = this.modalService.config.initialState['rosterEquipmentDetailsId'];
    this.rosterId = this.modalService.config.initialState['rosterId'];
    if (this.isEditEquipment) {
      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.fetchAllRosterEquipmentsDetails());
      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.iziToast.error({
          title: 'Error occurred',
          message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
          position: 'center',
          timeout: 3000,
          close: true,
          overlay: true
        });
      });
    }else{
      this.equipmentDetails.RosterId = this.rosterId;
    }
  }


  fetchAllRosterEquipmentsDetails() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchRosterEquipmentDetails(this.rosterEquipmentDetailsId).subscribe((res) => {
        console.log(res);
        if (res) {
          this.equipmentDetails.mapDetails(res);
          resolve(true);
        } else {
          reject(false);
        }
      }, (err) => {
        reject(err);
      });
    }));
  }


  submitAttempted = false;

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    if (this.isEditEquipment) {
      err = this.equipmentDetails.validateUpdate();
    } else {
      err = this.equipmentDetails.validateInsert();
    }
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }

  addEquipment(close: boolean) {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.equipmentDetails.validateInsert();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.rosterService.addEquipment(this.equipmentDetails).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'New Equipment Added',
        message: 'New Equipment added successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.equipmentDetails = new RosterEquipmentDetailsModel();
      this.equipmentDetails.RosterId = this.rosterId;
      this.submitAttempted = false;
      if(close){
        this.bsModalRef.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }



  updateEquipment() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.equipmentDetails.validateUpdate();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.rosterService.updateEquipment(this.equipmentDetails).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Equipment Details Updated',
        message: 'Equipment Details Updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.equipmentDetails = new RosterEquipmentDetailsModel();
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }
}
