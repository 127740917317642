import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth-service';
import {EnvironmentService} from './environment-service';
import {Observable, Subject} from 'rxjs';
import {CompanyEventModel, DynamicField} from '../model/company-event-model';
import {TicketCrewModel, TicketDocument, TicketModel, TicketSearchModel} from '../model/ticket-model';
import {ProfileModel} from '../model/profile-model';
import {RosterModel} from '../model/roster-model';
import {UserDetailsModel} from '../model/user-details-model';
import {LocalDataService} from './local-data-service';
import {FieldResourceAuthModel} from '../model/field-resource-auth-model';

@Injectable()
export class FieldResourceService {


  frDetails: FieldResourceAuthModel;

  constructor(private http: HttpClient, private environment: EnvironmentService, private localDataService: LocalDataService) {
  }

  setFRUserToken(frDetails: FieldResourceAuthModel){
    this.localDataService.storeFRUserToken(frDetails);
    this.frDetails = frDetails;
  }

  prepareHeaders(){
    if(!this.frDetails || !this.frDetails.token){
      this.frDetails = this.localDataService.readFRUserToken();
      if(this.frDetails && this.frDetails.token){
        let token = this.frDetails.token;
        let headers = new HttpHeaders();
        headers = headers.set('authorization', 'bearer '+token);
        return headers;
      }else{
        return new HttpHeaders();
      }
    }else{
      let token = this.frDetails.token;
      let headers = new HttpHeaders();
      headers = headers.set('authorization', 'bearer '+token);
      return headers;
    }

  }
  prepareOptions(){
    return {withCredentials: true, headers:this.prepareHeaders()};
  }

  login(authToken: string): Observable<FieldResourceAuthModel> {
    return this.http.post<FieldResourceAuthModel>(this.environment.serviceHostUrl + 'fieldResource/login', {authToken: authToken});
  }

  refreshToken(): Observable<FieldResourceAuthModel> {
    return this.http.post<FieldResourceAuthModel>(this.environment.serviceHostUrl + 'fieldResource/refreshToken', {}, this.prepareOptions());
  }

  updateTicketStatusByFR(ticketId: number, status:number, comment: string): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'fieldResource/updateTicketStatusByFR', {ticketId: ticketId, status:status, comment:comment}, this.prepareOptions());
  }

  fetchAllFRAllocatedTickets(): Observable<TicketModel[]> {
    return this.http.get<TicketModel[]>(this.environment.serviceHostUrl + 'fieldResource/fetchAllFRAllocatedTickets', this.prepareOptions());
  }

  fetchTicketDetailsByFR(ticketId: number): Observable<TicketModel> {
    return this.http.post<TicketModel>(this.environment.serviceHostUrl + 'fieldResource/fetchTicketDetailsByFR', {ticketId: ticketId}, this.prepareOptions());
  }


  fetchTicketCrewsByFR(ticketId: number): Observable<TicketCrewModel[]> {
    return this.http.post<TicketCrewModel[]>(this.environment.serviceHostUrl + 'fieldResource/fetchTicketCrewsByFR', {ticketId: ticketId}, this.prepareOptions());
  }

  fetchALLTicketDocumentsByFR(ticketId: number): Observable<TicketDocument[]> {
    return this.http.post<TicketDocument[]>(this.environment.serviceHostUrl + 'fieldResource/fetchALLTicketDocumentsByFR', {ticketId: ticketId}, this.prepareOptions());
  }

  fetchTicketDocumentByFR(ticketDocumentId: number): Observable<any> {
    let options = this.prepareOptions();
    options['responseType'] = 'blob';
    return this.http.post<any>(this.environment.serviceHostUrl + 'fieldResource/fetchTicketDocumentByFR', {ticketDocumentId: ticketDocumentId}, options);
  }


  fetchAllTicketsDynamicFieldsForCompanyWithFilterByFR( ): Observable<DynamicField[]> {
    return this.http.post<DynamicField[]>(this.environment.serviceHostUrl + 'fieldResource/fetchAllTicketsDynamicFieldsForCompanyWithFilterByFR', { },this.prepareOptions());
  }


}

