import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TicketCrewModel, TicketDocument, TicketModel} from '../../model/ticket-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {TicketService} from '../../providers/ticket-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {AuthService} from '../../providers/auth-service';
import {Router} from '@angular/router';
import {RosterService} from '../../providers/roster-service';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {FieldResourceService} from '../../providers/field-resource-service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {UpdateTicketPageComponent} from '../update-ticket-page/update-ticket-page.component';
import {TicketDocumentViewerPageComponent} from '../ticket-document-viewer-page/ticket-document-viewer-page.component';
import {UserDetailsModel} from '../../model/user-details-model';
import {LocalDataService} from '../../providers/local-data-service';
import {environment} from '../../../environments/environment';
import {DynamicField} from '../../model/company-event-model';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-field-resource-ticket-page',
  templateUrl: './field-resource-ticket-page.component.html',
  styleUrls: ['./field-resource-ticket-page.component.css']
})
export class FieldResourceTicketPageComponent implements OnInit {

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private localDataService: LocalDataService,
              private handleReqErr: HandleRequestError, private router: Router, private fieldResourceService: FieldResourceService,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil) { }

  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }
  ticketClosedStatusId = environment.moduleStatus.TicketingClosed;
  companyEventClosedStatusId = environment.moduleStatus.CompanyEventClosed;

  loadData(){
    this.disableEditBtn = true;
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllFRAllocatedTickets());
    baseDataCalls.push(this.fetchAllNonReleasedTicketsDynamicFields());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
      this.startTokenRefresh();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  fetchAllFRAllocatedTickets() {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchAllFRAllocatedTickets().subscribe((res) => {
        console.log(res);
        if(res){
          this.tickets = res;
          this.ticketsRows = [...this.tickets];
        }else{
          this.tickets = [];
          this.ticketsRows = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }
  bsModalRef: BsModalRef;
  tickets: TicketModel[];
  ticketsRows: TicketModel[];
  selected: TicketModel[] = [];

  disableEditBtn = true;
  showEditTicket = true;

  prepareRosterName(rosterId) {
    return this.commonUtil.prepareRosterName(rosterId);
  }

  getRowClass(row): any {
    return {
      'disabled-row': row['Status'] === environment.moduleStatus.TicketingClosed || row['Deleted'] === true || row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }
  getClosedTicketCheckboxClass({row, column, value}): any {
    return {
      'hide-checkbox':  row['Status'] === environment.moduleStatus.TicketingClosed || row['Deleted'] === true || row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;
  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.tickets.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.ticketsRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];

    this.disableEditBtn = true;
  }

  checkFilter(d:TicketModel, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    let primaryFieldFilter = this.matchContent(d.EventName, val) || this.matchContent(this.prepareTicketName(d.TicketId), val) ||
      this.matchContent(this.prepareCompanyEventName(d.CompanyEventId), val) || this.matchContent(d.RespondingCompanyName, val) ||
      this.matchContent(d.WACName, val) || this.matchContent(d.CompanyEventName, val) || this.matchContent(d.RequestingCompanyName, val) ||
      this.matchContent(d.StatusValue, val) || this.matchContent(d.WorkLocation, val) ||
      !val;

    let matchInDynamicField = false;
    let dynamicFields: DynamicField[] = this.ticketDynamicFieldMapping[d.TicketId];
    if (dynamicFields && dynamicFields.length > 0) {
      for (let dynamicField of dynamicFields) {
        if (this.matchContent(dynamicField.DynamicFieldValue, val)) {
          matchInDynamicField = true;
          break;
        }
      }
    }
    return primaryFieldFilter || matchInDynamicField;

  }

  checkFilterOld(d:TicketModel, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.EventName, val) || this.matchContent(this.prepareTicketName(d.TicketId), val) ||
      this.matchContent(this.prepareCompanyEventName(d.CompanyEventId), val) || this.matchContent(d.RespondingCompanyName, val) ||
      this.matchContent(d.WACName, val) || this.matchContent(d.CompanyEventName, val) || this.matchContent(d.RequestingCompanyName, val) ||
      this.matchContent(d.StatusValue, val) || this.matchContent(d.WorkLocation, val) ||
      !val;

  }


  prepareTicketName(ticketId){
    return this.commonUtil.prepareTicketName(ticketId);
  }

  prepareCompanyEventName(companyEventId){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }
  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }
  getTicketStatusClass({row, column, value}): any {
    return {
      'ticket-unassigned': row['Status'] === environment.moduleStatus.TicketingUnassigned,
      'ticket-WACAssigned': row['Status'] === environment.moduleStatus.TicketingWACAssigned,
      'ticket-CrewAssigned': row['Status'] === environment.moduleStatus.TicketingCrewAssigned,
      'ticket-Held': row['Status'] === environment.moduleStatus.TicketingHeld,
      'ticket-VerifyRestored': row['Status'] === environment.moduleStatus.TicketingVerifyRestored,
      'ticket-UnableToWork': row['Status'] === environment.moduleStatus.TicketingUnableToWork,
      'ticket-Closed': row['Status'] === environment.moduleStatus.TicketingClosed,
    };
  }
  getTicketIdStatusClass({row, column, value}): any {
    return {
      'ticket-br-unassigned': row['Status'] === environment.moduleStatus.TicketingUnassigned,
      'ticket-br-WACAssigned': row['Status'] === environment.moduleStatus.TicketingWACAssigned,
      'ticket-br-CrewAssigned': row['Status'] === environment.moduleStatus.TicketingCrewAssigned,
      'ticket-br-Held': row['Status'] === environment.moduleStatus.TicketingHeld,
      'ticket-br-VerifyRestored': row['Status'] === environment.moduleStatus.TicketingVerifyRestored,
      'ticket-br-UnableToWork': row['Status'] === environment.moduleStatus.TicketingUnableToWork,
      'ticket-br-Closed': row['Status'] === environment.moduleStatus.TicketingClosed,
    };
  }
  //TODO
  editTicket(){
    this.bsModalRef = this.modalService.show(UpdateTicketPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {
        ticketId: this.selected[0].TicketId,
        fromFieldResource: true
      },
      backdrop: 'static'
    });
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.tickets) {
      if (this.selected.length === this.tickets.length) {
        this.iziToast.warning({
          title: 'All Tickets selected',
          message: 'You have selected all the Tickets. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    this.disableEditBtn = true;
    if (this.selected.length === 1) {
      this.disableEditBtn = false;
    }
    if(this.selected[0].CompanyEventStatus === environment.moduleStatus.CompanyEventClosed ||
      this.selected[0].Status === environment.moduleStatus.TicketingClosed ||
      this.selected[0].Deleted
    ){
      this.disableEditBtn = true;
    }

  }
  documentSrc: any;
  bsModalRefViewDocument: BsModalRef;
  downloadTicketDocumentByFR(ticketDocumentId: number, filename: string) {
    this.spinner.show();
    this.fieldResourceService.fetchTicketDocumentByFR(ticketDocumentId).subscribe((res) => {
      console.log(res);
      this.createAndDownloadBlobFile(res, filename);
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      if(err.status === 404){
        this.iziToast.error({
          title: 'File Missing',
          message: 'Requested file is not available to download.',
          position: 'center', timeout: 2000
        });
      }else{
        this.iziToast.error({
          title: 'Error getting Documents',
          message: 'Error while getting the documents, please try again.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        console.log(err);
      }
    });
  }

  viewTicketDocument(ticketId: number){
    this.spinner.show();
    this.fieldResourceService.fetchALLTicketDocumentsByFR(ticketId).subscribe((res) => {
      let ticketDocuments = res;
      if( ticketDocuments && ticketDocuments.length > 0){
        this.bsModalRefViewDocument = this.modalService.show(TicketDocumentViewerPageComponent, {
          class: 'modal-dialog-centered modal-lg second',
          initialState: {
            ticketDocumentId: ticketDocuments[0]['TicketDocumentId'],
            fromFieldResource: true,
            ticketDocumentName: ticketDocuments[0]['OriginalFileName']
          },
          backdrop: 'static'
        });
      }else{
        this.iziToast.warning({
          title: 'No Documents found',
          message: 'No Documents found for the ticket.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.iziToast.error({
        title: 'Error getting Documents',
        message: 'Error while getting the documents, please try again.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
    });

  }




  downloadTicketDocument(ticketId: number) {
    this.spinner.show();
    this.fieldResourceService.fetchALLTicketDocumentsByFR(ticketId).subscribe((res) => {
      let ticketDocuments = res;
      if(ticketDocuments && ticketDocuments.length > 0){
        this.fieldResourceService.fetchTicketDocumentByFR(ticketDocuments[0]['TicketDocumentId']).subscribe((res) => {
          console.log(res);
          this.createAndDownloadBlobFile(res, ticketDocuments[0]['OriginalFileName']);
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          if(err.status === 404){
            this.iziToast.error({
              title: 'File Missing',
              message: 'Requested file is not available to download.',
              position: 'center', timeout: 2000
            });
          }else{
            this.iziToast.error({
              title: 'Error getting Documents',
              message: 'Error while getting the documents, please try again.',
              position: 'topRight',
              timeout: 5000,
              close: true,
              overlay: false
            });
            console.log(err);
          }
        });

      }else{
        this.iziToast.warning({
          title: 'No Documents found',
          message: 'No Documents found for the ticket.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.iziToast.error({
        title: 'Error getting Documents',
        message: 'Error while getting the documents, please try again.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
    });


  }

  createAndDownloadBlobFile(body, filename) {
   // var blob = new Blob([body], {type: 'application/octet-stream'});
    saveAs(body, filename);
    /*if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link['download'] !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }*/
  }

  tokenRefresher: any;
  startTokenRefresh(){
    this.tokenRefresher = setInterval(()=>{
      this.refreshTokenOnInterval()
    }, 10 * 60 * 1000);
  }

  refreshTokenOnInterval(){
    this.fieldResourceService.refreshToken().subscribe((res) => {
      if (res && res.token) {
        this.fieldResourceService.setFRUserToken(res);
      }else{
        clearInterval(this.tokenRefresher);
        this.logoutFR();
      }
    }, (err)=>{
      clearInterval(this.tokenRefresher);
      if(err.status === 403){
        this.logoutFR();
      }
      console.log('Error refreshing the token: ', err )
    });
  }

  ticket: TicketModel;
  ticketDocuments: TicketDocument[];

  fetchTicketDetailsByFR(ticketId) {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchTicketDetailsByFR(ticketId).subscribe((res) => {
        this.ticket = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchALLTicketDocumentsByFR(ticketId) {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchALLTicketDocumentsByFR(ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }
  ticketCrew: TicketCrewModel[];
  ticketCrewRows: TicketCrewModel[];
  modalRef: BsModalRef;
  showTicketCrew(ticketId: number, template: TemplateRef<any>){
    this.spinner.show();
    this.fetchTicketDetailsByFR(ticketId).then((r)=>{
      return this.fetchALLTicketDocumentsByFR(ticketId);
    }).then((r2)=>{
      this.fieldResourceService.fetchTicketCrewsByFR(ticketId).subscribe((res) => {
        this.ticketCrew = res;
        let ttc : TicketCrewModel[] = [];
        this.spinner.hide();
        if (this.ticketCrew && this.ticketCrew.length > 0) {
          for (let tc of this.ticketCrew) {
            if (tc.PrimaryCrew) {
              tc.PrimaryCrewSelectedValue = tc.RosterId + '-' + tc.RosterCrewDetailsId;
            }
            ttc.push(tc);
          }
          this.ticketCrewRows = [...ttc];
          this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static'
          });
        }else{
          this.ticketCrewRows = [];
          this.ticketCrew = [];
          this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static'
          });
        }


      }, (err) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(err);
      });
    }).catch((err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    })

  }

  logoutFR(){
    this.localDataService.clearFRUserToken();
    this.fieldResourceService.frDetails = null;
    window.location.href = window.location.origin;
  }


  dynamicFieldColumns = [];
  ticketDynamicFieldMapping: { [key: string]: DynamicField[] } = {};
  dynamicFields: DynamicField[];

  fetchAllNonReleasedTicketsDynamicFields() {
    return new Promise(((resolve, reject) => {
      //TODO
      this.fieldResourceService.fetchAllTicketsDynamicFieldsForCompanyWithFilterByFR().subscribe((res) => {
        console.log(res);
        if (res) {
          this.dynamicFields = res;
        } else {
          this.dynamicFields = [];
        }
        this.dynamicFieldColumns = [];
        this.ticketDynamicFieldMapping = {};
        let maxColumns = 0;
        if (this.dynamicFields.length > 0) {
          for (let dynamicField of this.dynamicFields) {
            if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId]) {
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
              if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length > maxColumns) {
                maxColumns = this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length;
              }
            } else {
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId] = [];
              this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
            }
          }
        }
        for (let i = 1; i <= maxColumns; i++) {
          this.dynamicFieldColumns.push('Dynamic Field ' + i);
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchDynamicFieldNameOfTicketInIndex(ticketId, index) {
    if (this.ticketDynamicFieldMapping['' + ticketId] && this.ticketDynamicFieldMapping['' + ticketId].length > index) {
      return this.ticketDynamicFieldMapping['' + ticketId][index].DynamicFieldName;
    } else {
      return '';
    }
  }

  fetchDynamicFieldValueOfTicketIdInIndex(ticketId, index) {
    if (this.ticketDynamicFieldMapping['' + ticketId] && this.ticketDynamicFieldMapping['' + ticketId].length > index) {
      return this.ticketDynamicFieldMapping['' + ticketId][index].DynamicFieldValue;
    } else {
      return '';
    }
  }
}
