import { Component, OnInit } from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TicketService} from '../../providers/ticket-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {FieldResourceService} from '../../providers/field-resource-service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-ticket-document-viewer-page',
  templateUrl: './ticket-document-viewer-page.component.html',
  styleUrls: ['./ticket-document-viewer-page.component.css']
})
export class TicketDocumentViewerPageComponent implements OnInit {
  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;
  ticketDocumentId: number;
  ticketDocumentName: string;

  documentSrc : any;

  fromFieldResource: boolean = false;
  showPDFViewer: boolean = false;
  showNonPDFInfo: boolean = false;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private companyEventService: CompanyEventsService, public bsModalRef: BsModalRef, private ticketService: TicketService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
              private fieldResourceService: FieldResourceService
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  logmsg(d){
    console.log(d);
  }

  ngOnInit() {

    this.fromFieldResource = this.modalService.config.initialState['fromFieldResource'];
    this.ticketDocumentId = this.modalService.config.initialState['ticketDocumentId'];
    this.ticketDocumentName = this.modalService.config.initialState['ticketDocumentName'];

    if(this.fromFieldResource){
      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.fetchTicketDocumentByFR());
      //Document Loading is pending...
      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(e);
      });
    }else{
      this.adminCompanyId = this.authService.userDetails.companyId;
      this.adminCompanyName = this.authService.userDetails.companyName;
      this.userRole = this.authService.userDetails.userRole;
      this.spinner.show();
      let baseDataCalls = [];

      baseDataCalls.push(this.fetchTicketDocument());
      //Document Loading is pending...
      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(e);
      });
    }

  }

  fetchTicketDocument() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDocument(this.ticketDocumentId).subscribe((res) => {
        if(this.ticketDocumentName.indexOf('pdf') != -1){
          this.documentSrc = res;
          this.showPDFViewer = true;
        }else{
          this.createAndDownloadBlobFile(res, this.ticketDocumentName);
          /*this.iziToast.info({
            title: 'Not a PDF File',
            message: 'Since it is not a PDF file, File is downloaded',
            position: 'topRight',
            timeout: 5000,
            close: true,
            overlay: false
          });*/
          this.showNonPDFInfo = true;
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchTicketDocumentByFR() {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchTicketDocumentByFR(this.ticketDocumentId).subscribe((res) => {
        if(this.ticketDocumentName.indexOf('pdf') != -1){
          this.documentSrc = res;
          this.showPDFViewer = true;
        }else{
          this.createAndDownloadBlobFile(res, this.ticketDocumentName);
          /*this.iziToast.info({
            title: 'Not a PDF File',
            message: 'Since it is not a PDF file, File is downloaded',
            position: 'topRight',
            timeout: 5000,
            close: true,
            overlay: false
          });*/
          this.showNonPDFInfo = true;
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  createAndDownloadBlobFile(body, filename) {
  //  var blob = new Blob([body], {type: 'application/octet-stream'});
    saveAs(body, filename);
    /*if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link['download'] !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }*/
  }

  close(){
    this.bsModalRef.hide();
  }
}
