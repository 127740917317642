import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MasterEventModel, MasterEventTypeModel} from '../../model/master-event-model';
import {StatusDetailsModel} from '../../model/status-details-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {LocalDataService} from '../../providers/local-data-service';
import {Router} from '@angular/router';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {CompanyEventModel, DynamicField, RespondingCompanyUsers} from '../../model/company-event-model';
import {CompanyEventsService} from '../../providers/company-events-service';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import isEmail from "validator/lib/isEmail";
import {AuthService} from '../../providers/auth-service';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-manage-company-events-page',
  templateUrl: './modify-company-events-page.component.html',
  styleUrls: ['./modify-company-events-page.component.css']
})
export class ModifyCompanyEventsPageComponent implements OnInit, OnDestroy {

  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }

  masterEvents: MasterEventModel[];
  eventStatus: StatusDetailsModel[];
  respondingCompanies: CompanyDetailsModel[];
  respCompanyUsers: RespondingCompanyUsers[];
  respCompanyUsersHaveEmailSubscription: RespondingCompanyUsers[];
  companyEvent: CompanyEventModel;

  isEditEvent: boolean;
  companyEventId: number;

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private modalService: BsModalService,
              private spinner: NgxSpinnerService, private masterEventService: MasterEventService,
              private localDataService: LocalDataService, private router: Router,private authService: AuthService,
              private companyEventService: CompanyEventsService,
              private handleReqErr: HandleRequestError, private commonService: CommonService) {
  }
  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe((r) => {
      console.log(r);
      this.loadMasterEvents();
    });
    this.loadData();
  }


  loadMasterEvents(){
    this.spinner.show();
    this.fetchAllMasterEvents().then((r)=>{
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }
  loadData(){
    this.companyEvent = new CompanyEventModel();

    this.isEditEvent = this.modalService.config.initialState['isEditEvent'];
    this.companyEventId = this.modalService.config.initialState['companyEventId'];


    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;


    this.spinner.show();
    let baseDataCalls = [];

    if(this.isEditEvent){
      baseDataCalls.push(this.fetchCompanyEvents());
      baseDataCalls.push(this.fetchEventStatus());
    }else{
      baseDataCalls.push(this.fetchAllActiveRespondingCompanies());
      baseDataCalls.push(this.fetchAllMasterEvents());
      this.companyEvent.additionalUsers = [];
      this.companyEvent.additionalUsers[0] = new RespondingCompanyUsers();

    }
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  fetchAllActiveRespondingCompanies() {
    return new Promise(((resolve, reject) => {
      this.commonService.getAllRespondingCompanies().subscribe((res) => {
        console.log(res);
        let tres = [];
        if(res && res.length >0 ){
          for(let r=0; r<res.length; r++){
            if(res[r].CompanyId !== this.adminCompanyId){
              tres.push(res[r]);
            }else{
              if(this.isEditEvent && this.authService.userDetails.userRole === environment.userRoles.SuperAdministrator){
                if(this.companyEvent && this.companyEvent.selectedRespondingCompanies.indexOf(res[r].CompanyId) > -1){
                  tres.push(res[r]);
                }
              }
            }
          }
        }
        this.respondingCompanies = tres;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  addNewDynamicField(){
    if(this.companyEvent.newDynamicFields){
      let t = [];
      for(let i =0; i < this.companyEvent.newDynamicFields.length; i++){
        t.push(this.companyEvent.newDynamicFields[i]);
      }
      t.push(new DynamicField());
      this.companyEvent.newDynamicFields = [...t];
     // this.cdr.detectChanges();

    }else{
      this.companyEvent.newDynamicFields = [];
      this.companyEvent.newDynamicFields.push(new DynamicField());
     // this.cdr.detectChanges();
    }
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  removeNewDynamicField(index: number){
    if(this.companyEvent.newDynamicFields){
      let t = [];
      for(let i =0; i < this.companyEvent.newDynamicFields.length; i++){
        if(i !== index){
          t.push(this.companyEvent.newDynamicFields[i]);
        }
      }
      this.companyEvent.newDynamicFields = [...t];
    //  this.cdr.detectChanges();
    }else{
      this.companyEvent.newDynamicFields = [];
    //  this.cdr.detectChanges();
    }
  }

  removeExistingDynamicField(index: number){
    if(!this.companyEvent.deletedDynamicFields){
      this.companyEvent.deletedDynamicFields = [];
    }
    this.companyEvent.deletedDynamicFields.push(this.companyEvent.existingDynamicFields[index]);
    if(this.companyEvent.existingDynamicFields){
      let t = [];
      for(let i =0; i < this.companyEvent.existingDynamicFields.length; i++){
        if(i !== index){
          t.push(this.companyEvent.existingDynamicFields[i]);
        }
      }
      this.companyEvent.existingDynamicFields = [...t];
    //  this.cdr.detectChanges();
    }else{
      this.companyEvent.existingDynamicFields = [];
    //  this.cdr.detectChanges();
    }
  }

  fetchRespCompanyUsers() {
    return new Promise(((resolve, reject) => {
      if(!this.companyEvent.selectedRespondingCompanies){
        resolve(true);
      }
      this.companyEventService.fetchAllActiveUsersOfRespondingCompany(this.companyEvent.selectedRespondingCompanies.join(',')).subscribe((res) => {
        console.log(res);
        this.respCompanyUsers = res;
        this.respCompanyUsersHaveEmailSubscription = [];
        for(let resComUser of this.respCompanyUsers){
          if(resComUser.EmailAlerts){
            this.respCompanyUsersHaveEmailSubscription.push(resComUser);
          }
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  @ViewChild('respondingUsersTemplatePopup')
  respondingUsersTemplatePopup: any;

  showUsersListLabel = 'click here to show/hide list of users who receives notification.';

  toggleUserListLabel(){
    console.log('de')
    this.respondingUsersTemplatePopup.hide();
  }

  fetchCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchCompanyEvent( this.companyEventId).subscribe((res) => {
        console.log(res);
        this.companyEvent.mapCompanyEvent(res);
        if(this.companyEvent.selectedRespondingCompanies && this.companyEvent.selectedRespondingCompanies.length > 0){
          this.fetchRespCompanyUsers();
        }
        this.fetchAllMasterEvents().then((r)=>{
          return this.fetchAllActiveRespondingCompanies()
        }).then((r)=>{
          resolve(true);
        }).catch((err)=>{
          reject(err);
        })

      }, (err) => {
        reject(err);
      });
    }));
  }


  fetchAllMasterEvents() {
    return new Promise(((resolve, reject) => {
      this.masterEventService.fetchAllMasterEvents().subscribe((res) => {
        console.log(res);
        let mes = [];
        //this.masterEvents = res;
        if(res && res.length > 0){
          for(let me of res){
            if( (this.companyEvent && this.companyEvent.CompanyEventId &&
               me.EventId === this.companyEvent.MasterEventId)
              || (me.Status !== environment.moduleStatus.MasterEventClosed
                && me.Status !== environment.moduleStatus.MasterEventInActive)){
              mes.push(me);
            }
          }
          this.masterEvents = mes;
        }else{
          this.masterEvents = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  fetchEventStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('Company Event').subscribe((res) => {
        console.log(res);
        this.eventStatus = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;
  validateFields(){
    console.log(this.companyEvent);
    if(!this.submitAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    if(this.isEditEvent){
      err =this.companyEvent.validateUpdateCompanyEvent();
    }else{
      err =this.companyEvent.validateAddCompanyEvent();
    }

    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }


  updateCompanyEvent() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.companyEvent.validateUpdateCompanyEvent();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    let tempAdditionalUsers: RespondingCompanyUsers[] = [];
    if(this.companyEvent.additionalUsers && this.companyEvent.additionalUsers.length > 0){
      for(let au of this.companyEvent.additionalUsers){
        if(au.eMail_ID || au.PhoneNumber){
          tempAdditionalUsers.push(au);
        }
      }
    }
    this.companyEvent.additionalUsers = tempAdditionalUsers;

    let err1 = this.validateAdditionalUsers();
    if (err1 && Object.keys(err1).length > 0) {
      this.validationError = err1;
      return;
    }
    let err2 = this.validateDynamicFields();
    if (err2 && Object.keys(err2).length > 0) {
      this.validationError = err2;
      return;
    }
    if(this.companyEvent.Status === environment.moduleStatus.CompanyEventClosed){
      this.checkAnyApprovedReleasedRosterAndOpenTickets().then((data)=>{
        if(data && data['ITEM_COUNT'] > 0){
          this.confirmCloseCompanyEvent();
        }else{
          this. onCloseConfirm();
        }
      }).catch((err)=>{
        this.handleReqErr.handlePageLoadError(err);
      })
    }else{
      this. onCloseConfirm();
    }

  }




  confirmCloseCompanyEvent() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'There are Initiated/Approved Rosters and Open Tickets associated with this Company Event, do you really want to close it? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onCloseConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onCloseConfirm() {
    this.spinner.show();
    this.companyEventService.updateCompanyEvent(this.companyEvent).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Updated Company Event',
        message: 'Company Event updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  addCompanyEvent(close: boolean) {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.companyEvent.validateAddCompanyEvent();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    //Validate Additional users

    let tempAdditionalUsers: RespondingCompanyUsers[] = [];
    if(this.companyEvent.additionalUsers && this.companyEvent.additionalUsers.length > 0){
      for(let au of this.companyEvent.additionalUsers){
        if(au.eMail_ID || au.PhoneNumber){
          tempAdditionalUsers.push(au);
        }
      }
    }
    this.companyEvent.additionalUsers = tempAdditionalUsers;

    let err1 = this.validateAdditionalUsers();
    if (err1 && Object.keys(err1).length > 0) {
      this.validationError = err1;
      return;
    }
    let err2 = this.validateDynamicFields();
    if (err2 && Object.keys(err2).length > 0) {
      this.validationError = err2;
      return;
    }
    this.spinner.show();
    this.companyEventService.addCompanyEvent(this.companyEvent).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Company Event Added',
        message: 'Company Event added successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.submitAttempted = false;
      if(close){
        this.bsModalRef.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }

  checkAnyApprovedReleasedRosterAndOpenTickets(){
    return new Promise(((resolve, reject) => {
      this.companyEventService.checkAnyApprovedReleasedRosterAndOpenTickets(this.companyEventId).subscribe((data)=>{
        console.log(data);
        resolve(data);
      },(err)=>{
        console.log(err);
        reject(err);
      })
    }))
  }

  onRespondingCompanyChanged(){
   // console.log(this.companyEvent);
    if(this.companyEvent.selectedRespondingCompanies && this.companyEvent.selectedRespondingCompanies.length > 0){
      this.fetchRespCompanyUsers();
    }else{
      this.respCompanyUsers = null;
      this.respCompanyUsersHaveEmailSubscription = null;
    }
  }

  addAdditionalUsers(){
    this.companyEvent.additionalUsers.push(new RespondingCompanyUsers());
  }

  removeAdditionalUsers(index:number){
    console.log('minus clicked');
    if(this.companyEvent.additionalUsers.length > index){
      if(this.companyEvent.additionalUsers.length === 1 && index === 0){
        this.companyEvent.additionalUsers = [];
        this.companyEvent.additionalUsers[0] = new RespondingCompanyUsers();
      }else{
        this.companyEvent.additionalUsers.splice(index, 1);
      }
    }
  }

  validateAdditionalUsers(){
    if(!this.companyEvent.additionalUsers || this.companyEvent.additionalUsers.length === 0){
      return;
    }
    let error = new ValidationError();
    let validator = new Validators();
    let index = 0;
    for(let user of this.companyEvent.additionalUsers){
      validator.validateEmail(error, 'addUser'+index+'eMail_ID', user.eMail_ID, 'Email');
      validator.validateMobileNumber(error, 'addUser'+index+'PhoneNumber', user.PhoneNumber, 'PhoneNumber');
      validator.requiredString(error, 'addUser'+index+'CompanyName', user.CompanyName, 'AEIC Company');
      validator.requiredString(error, 'addUser'+index+'ContactName', user.ContactName, 'Contact Name');
      index = index + 1;
    }
    return error;
  }

  validateDynamicFields(){
    let error = new ValidationError();
    let validator = new Validators();
    let index = 0;
    if(this.companyEvent.newDynamicFields && this.companyEvent.newDynamicFields.length > 0){
      for(let dynamicField of this.companyEvent.newDynamicFields){
        validator.requiredString(error, 'newDynamicField'+index, dynamicField.DynamicFieldName, 'This ');
        index = index + 1;
      }
    }
    index = 0;
    if(this.companyEvent.existingDynamicFields && this.companyEvent.existingDynamicFields.length > 0){
      for(let dynamicField of this.companyEvent.existingDynamicFields){
        validator.requiredString(error, 'existingDynamicField'+dynamicField.DynamicFieldId, dynamicField.DynamicFieldName, 'This ');
        index = index + 1;
      }
    }

    return error;
  }

  masterBSModalRef: BsModalRef;
  addMasterEvent() {
    this.masterBSModalRef = this.modalService.show(ModifyMasterEventPageComponent, {
      class: 'modal-dialog-centered second',
      initialState: {isEditEvent: false},
      backdrop: 'static'
    });
  }

  fetchUserMatchingEmail(index: number){
    if(this.companyEvent.additionalUsers[index].eMail_ID && isEmail(this.companyEvent.additionalUsers[index].eMail_ID)){
      this.companyEventService.fetchUserMatchingEmail(this.companyEvent.additionalUsers[index].eMail_ID).subscribe((res) => {
        console.log(res);
        if (res && res['CompanyName']) {
          console.log(res);
          if(this.companyEvent.additionalUsers && this.companyEvent.additionalUsers.length > index){
            if(!this.companyEvent.additionalUsers[index].ContactName){
              this.companyEvent.additionalUsers[index].ContactName = res.FirstName+ ' ' + res.LastName;
            }
            if(!this.companyEvent.additionalUsers[index].CompanyName){
              this.companyEvent.additionalUsers[index].CompanyName = res.CompanyName;
            }
            if(!this.companyEvent.additionalUsers[index].PhoneNumber){
              this.companyEvent.additionalUsers[index].PhoneNumber = res.PhoneNumber;
            }
          }
        }

      }, (err) => {

        //this.validationError = this.handleReqErr.handleReqError(err, this.errors);
      });
    }
  }

}
