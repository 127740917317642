import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {CompanyEventModel} from '../../model/company-event-model';
import {CompanyEventsService} from '../../providers/company-events-service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {ModifyCompanyEventsPageComponent} from '../modify-company-events-page/modify-company-events-page.component';
import {RosterService} from '../../providers/roster-service';
import {UploadRosterPageComponent} from '../upload-roster-page/upload-roster-page.component';
import {CommonUtil} from '../../util/common-util';
import {Subscription} from 'rxjs';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventRespondingCompanyPageComponent} from '../company-event-responding-company-page/company-event-responding-company-page.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-company-events-page',
  templateUrl: './company-events-page.component.html',
  styleUrls: ['./company-events-page.component.css']
})
export class CompanyEventsPageComponent implements OnInit, OnDestroy {

  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }

  companyEvents: CompanyEventModel[];
  companyEventsRows: CompanyEventModel[];
  selected: CompanyEventModel[] = [];
  bsModalRef: BsModalRef;

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  showModifyFunctionality: boolean = false;
  showEditButton: boolean = false;
  showUploadRosterButton: boolean = false;
  showAddButton: boolean = false;

  checkAndEnableModifyOption(){
    if(this.userRole === environment.userRoles.SuperAdministrator || this.userRole === environment.userRoles.Administrator){
      this.showModifyFunctionality = true;
      this.showEditButton = true;
      this.showUploadRosterButton = true;
      this.showAddButton = true;
    }else if(this.userRole === environment.userRoles.ResourceUnitLead){
      this.showModifyFunctionality = true;
      this.showEditButton = true;
    }else{
      this.showModifyFunctionality = false;
    }
  }

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService,
              private masterEventService: MasterEventService, private companyEventService: CompanyEventsService,
              private handleReqErr: HandleRequestError, private rosterService: RosterService, private authService: AuthService,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil
  ) {
   /* this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();


  }

  disableEditBtn = true;
  disableRosterBtn = true;
  disableDeleteBtn = true;
  loadData() {
    this.disableEditBtn = true;
    this.disableRosterBtn = true;
    this.disableDeleteBtn = true;

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkAndEnableModifyOption();

    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];

    baseDataCalls.push(this.fetchAllCompanyEvents());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }


  checkIsRosterLinkedWithCompanyEventAlready(){
    this.spinner.show();
    this.rosterService.checkIsRosterLinkedWithCompanyEventAlready(this.selected[0].CompanyEventId, this.authService.userDetails.companyId).subscribe((resp)=>{
      this.spinner.hide();
      if(resp['RostersCount'] > 0){
        this.iziToast.warning({
          title: 'Roster already uploaded',
          message: 'Already roster is uploaded for this company event by your company. Please go to Roster and update it, if needed.',
          position: 'center',
          timeout: 3000,
          close: true,
          overlay: true,
        });
      }else{
        this.bsModalRef = this.modalService.show(UploadRosterPageComponent, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {companyEvent: this.selected[0]},
          backdrop: 'static'
        });
      }
    },(e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  fetchAllCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchAllCompanyEvents().subscribe((res) => {
        console.log(res);
        if(res){
          this.companyEvents = res;
          this. prepareCompanyEvent();
          this.companyEventsRows = [...this.companyEvents];
        }else{
          this.companyEvents = [];
          this.companyEventsRows = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareCompanyEvent(){
    if(!this.companyEvents || this.companyEvents.length <=0){
      return;
    }
    for(let ce of this.companyEvents){
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }




  /*fetchAllCompanyEventInvites() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllCompanyEventInvites().subscribe((res) => {
        console.log(res);
        if(res){
          this.companyEvents = res;
          this.companyEventsRows = [...this.companyEvents];
        }else{
          this.companyEvents = [];
          this.companyEventsRows = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }*/

  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.companyEvents.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.companyEventsRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableEditBtn = true;
    this.disableDeleteBtn = true;
  }


  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    if(this.userRole === 1){
      return this.matchContent(d.MasterEventName, val) || this.matchContent(this.prepareCompanyEventName(d.CompanyEventId), val) ||
        this.matchContent(d.StartDateTxt, val) || this.matchContent(d.EndDateTxt, val) ||  this.matchContent(d.CompanyEventName, val) ||
        this.matchContent(d.CreatedByName, val) || this.matchContent(d.UpdatedByName, val) || this.matchContent(d.RequestingCompanyName, val) ||
        this.matchContent(d.StatusValue, val) ||
        !val;
    }else{
      return this.matchContent(d.MasterEventName, val) || this.matchContent(this.prepareCompanyEventName(d.CompanyEventId), val) ||
        this.matchContent(d.StartDateTxt, val) || this.matchContent(d.EndDateTxt, val) ||this.matchContent(d.RequestingCompanyName, val) ||
        this.matchContent(d.CreatedByName, val) || this.matchContent(d.UpdatedByName, val) ||  this.matchContent(d.CompanyEventName, val) ||
        this.matchContent(d.StatusValue, val) ||
        !val;
    }

  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.companyEvents) {
      if (this.selected.length === this.companyEvents.length) {
        this.iziToast.warning({
          title: 'All Company Events selected',
          message: 'You have selected all the Company Events. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    /*let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      if (sel.Status === 1 && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      if (sel.Status === 2 && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }*/

    if (this.selected.length === 1) {
      if((this.selected[0].RequestingCompanyId === this.authService.userDetails.companyId || this.userRole === 1)
          && this.selected[0].Status !== environment.moduleStatus.CompanyEventClosed){
        this.disableEditBtn = false;
      }else{
        this.disableEditBtn = true;
      }

      if(this.selected[0].Status === environment.moduleStatus.CompanyEventActive && (this.selected[0].RequestingCompanyId != this.adminCompanyId  || this.userRole === 1)){
        this.disableRosterBtn = false;
      }else{
        this.disableRosterBtn = true;
      }

    } else {
      this.disableEditBtn = true;
      this.disableRosterBtn = true;
    }

    /* if (this.selected.length > 0) {
       this.disableActivateBtn = !allInActiveUsers;
       this.disableInActivateBtn = !allActiveUsers;
     } else {
       this.disableActivateBtn = true;
       this.disableInActivateBtn = true;
     }*/

    let enableDeleteButton = true;
    for (let sel of this.selected) {
      if (sel.CompanyEventInviteAcceptedCount > 0) {
        enableDeleteButton = false;
        break;
      }else if (this.userRole !== 1  && sel.RequestingCompanyId != this.adminCompanyId){
        enableDeleteButton = false;
        break;
      }
    }
    if (this.selected.length > 0) {
      this.disableDeleteBtn = !enableDeleteButton;
    } else {
      this.disableDeleteBtn = true;
    }

  }

  getUserStatusClass({row, column, value}): any {
    return {
      'user-active': value === 'Active',
      'user-in-active': value === 'In-Active'
    };
  }

  getCompanyEventClass({row, column, value, adminCompanyName}): any {
    return {
      'our-company-event': row['RequestingCompanyName'] === row['adminCompanyName'],
      'other-company-event': row['RequestingCompanyName'] !== row['adminCompanyName']
    };
  }

  deleteCompanyEvent() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Delete the Company Event? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onDeleteConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onDeleteConfirm() {
    this.spinner.show();
    let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.CompanyEventId);
    }
    this.companyEventService.deleteCompanyEvent(eventIds.join(',')).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({title: 'Company Event Deleted', message: 'Company Event deleted Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false});
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  addCompanyEvent() {
    this.bsModalRef = this.modalService.show(ModifyCompanyEventsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditEvent: false},
      backdrop: 'static'
    });
  }

  addRoster() {
    //Can upload multiple rosters
    //this.checkIsRosterLinkedWithCompanyEventAlready();
    this.bsModalRef = this.modalService.show(UploadRosterPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {companyEvent: this.selected[0],
        showCompanyEventSelect: false
      },
      backdrop: 'static'
    });

  }


  editCompanyEvent() {
    this.bsModalRef = this.modalService.show(ModifyCompanyEventsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditEvent: true, companyEventId: this.selected[0].CompanyEventId},
      backdrop: 'static'
    });
  }

  showCompanyEventRoster(companyEventId) {
    this.bsModalRef = this.modalService.show(CompanyEventRespondingCompanyPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditEvent: true, companyEventId: companyEventId},
      backdrop: 'static'
    });
  }


  prepareCompanyEventName(companyEventId: number){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }
}
