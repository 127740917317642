import {Component, OnInit} from '@angular/core';
import {CompanyEventModel} from '../../model/company-event-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {RosterService} from '../../providers/roster-service';
import {LocalDataService} from '../../providers/local-data-service';
import {Router} from '@angular/router';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {AuthService} from '../../providers/auth-service';
import {CommonUtil} from '../../util/common-util';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {RosterModel} from '../../model/roster-model';

@Component({
  selector: 'app-assign-roster-to-company-event-page',
  templateUrl: './assign-roster-to-company-event-page.component.html',
  styleUrls: ['./assign-roster-to-company-event-page.component.css']
})
export class AssignRosterToCompanyEventPageComponent implements OnInit {

 // rosterId: number;
  masterEventId: number;
  companyEventId: number;
  companyEvents: CompanyEventModel[] = [];

  adminCompanyId: number;
  adminCompanyName: string;
  isAssignRoster: boolean;
  userRole: number;
  rostersToAssign: RosterModel[];


  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private modalService: BsModalService,
              private spinner: NgxSpinnerService, private rosterService: RosterService, private companyEventService: CompanyEventsService,
              private localDataService: LocalDataService, private router: Router, private authService: AuthService,
              private commonUtil: CommonUtil,
              private handleReqErr: HandleRequestError, private commonService: CommonService) {
  }

  ngOnInit() {
    this.rostersToAssign = this.modalService.config.initialState['rostersToAssign'];
    this.masterEventId = this.modalService.config.initialState['masterEventId'];
    this.isAssignRoster = this.modalService.config.initialState['isAssignRoster'];
    console.log('masterEventId', this.masterEventId);

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.fetchAllActiveCompanyEvents();
    /*if (this.isAssignRoster) {
      this.fetchAllCompanyEventInvites();
    }else{
      this.fetchAllActiveCompanyEvents();
    }*/
  }

  fetchAllActiveCompanyEvents() {
    this.spinner.show();
    this.companyEventService.fetchAllActiveCompanyEvents(this.masterEventId).subscribe((res) => {
      console.log(res);
      if (res) {
        this.companyEvents = res;
        this.prepareCompanyEvent();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.iziToast.warning({
          title: 'No Company Events Available for the Master Event.',
          message: 'No Company Events Available to pull Roster to it.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false,
          onOpened: ()=>{
            console.log('Error closed');
            this.close();
          }
        });
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  fetchAllCompanyEventInvites() {
    this.spinner.show();
    this.companyEventService.fetchAllCompanyEventInvites(this.masterEventId).subscribe((res) => {
      console.log(res);
      if (res) {
        this.companyEvents = res;
        this.prepareCompanyEvent();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.iziToast.warning({
          title: 'No Company Events Invites Available for the Master Event.',
          message: 'No Company Events Available Invites to assign Roster to it.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false,
          onOpened: ()=>{
            console.log('Error closed');
            this.close();
          }
        });
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  assignRoster() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'companyEventId', this.companyEventId, 'Company Event');
    if (this.validationError && this.validationError['companyEventId']) {
      return;
    }
    this.spinner.show();
    for(let tr of this.rostersToAssign){
      tr.CompanyEventId = this.companyEventId;
    }
    this.rosterService.assignOrPullRoster(this.rostersToAssign).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Roster assigned',
        message: 'Roster details updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }


  pullRoster() {
    /*this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'companyEventId', this.companyEventId, 'Company Event');
    if (this.validationError && this.validationError['companyEventId']) {
      return;
    }
    this.spinner.show();
    this.rosterService.assignOrPullRoster(this.rosterId, this.companyEventId).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Roster pulled',
        message: 'Roster details updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });*/
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'companyEventId', this.companyEventId, 'Company Event');
    if (this.validationError && this.validationError['companyEventId']) {
      return;
    }
  }

  prepareCompanyEvent() {
    if (!this.companyEvents || this.companyEvents.length <= 0) {
      return;
    }
    for (let ce of this.companyEvents) {
      ce.FormattedCompanyEventName = ce.MasterEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RequestingCompanyName;
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }

  close() {
    this.bsModalRef.hide();
  }
}

