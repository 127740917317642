import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth-service';
import {EnvironmentService} from './environment-service';
import {Observable} from 'rxjs';
import {CompanyEventModel, DynamicField} from '../model/company-event-model';
import {
  TicketCrewModel,
  TicketDeleteCloseRequestModel,
  TicketDocument,
  TicketModel,
  TicketSearchModel, TicketWACModel,
  TicketWorkLocationModel
} from '../model/ticket-model';
import {ProfileModel} from '../model/profile-model';
import {RosterModel} from '../model/roster-model';
import {CompanyDetailsModel} from '../model/company-details-model';

@Injectable()
export class TicketService {
  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  fetchAllTicketsForCompany(): Observable<TicketModel[]> {
    return this.http.get<TicketModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllTicketsForCompany', this.authService.prepareOptions());
  }

  fetchAllTicketsForCompanyWithFilter(ticketSearch: TicketSearchModel): Observable<TicketModel[]> {
    return this.http.post<TicketModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllTicketsForCompanyWithFilter', {ticketSearch:ticketSearch},this.authService.prepareOptions());
  }

  fetchAllTicketsDynamicFieldsForCompanyWithFilter(ticketSearch: TicketSearchModel): Observable<DynamicField[]> {
    return this.http.post<DynamicField[]>(this.environment.serviceHostUrl + 'ticket/fetchAllTicketsDynamicFieldsForCompanyWithFilter', {ticketSearch:ticketSearch},this.authService.prepareOptions());
  }

  fetchAllActiveWACUsersOfRespondingCompany(companyId: number): Observable<ProfileModel[]> {
    return this.http.post<ProfileModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllActiveWACUsersOfRespondingCompany', {companyId: companyId}, this.authService.prepareOptions());
  }

  fetchAllRostersForCreateTicket(): Observable<RosterModel[]> {
    return this.http.get<RosterModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllRostersForCreateTicket', this.authService.prepareOptions());
  }
  createNewTicket(file: File, ticket: TicketModel): Observable<any> {
    let input = new FormData();
    input.append('TicketDocument', file);
    input.append('ticketDetails', JSON.stringify(ticket));

    /*input.append('CompanyEventId', '' + ticket.CompanyEventId);
    input.append('RespondingCompanyId', '' + ticket.RespondingCompanyId);
    input.append('WorkLocation', '' + ticket.WorkLocation);
    if (ticket.WAC_Id) {
      input.append('WAC_Id', '' + ticket.WAC_Id);
    }*/

    let options = this.authService.prepareOptions();
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(this.environment.serviceHostUrl + 'ticket/createNewTicket', input, options);
  }


  createMultipleTicket(tickets: TicketModel[]): Observable<any> {
    let input = new FormData();
    input.append('multiTicketDetails', JSON.stringify(tickets));
    for(let t of tickets){
      input.append('TicketMultiDocument', t.file);
    }

    let options = this.authService.prepareOptions();
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(this.environment.serviceHostUrl + 'ticket/createMultipleTicket', input, options);
  }

  updateTicketWithFile(file: File, ticket: TicketModel): Observable<any> {
    let input = new FormData();
    input.append('TicketDocument', file);
    input.append('ticketDetails', JSON.stringify(ticket));
    if (ticket.WAC_Id) {
      input.append('WAC_Id', '' + ticket.WAC_Id);
    }

    let options = this.authService.prepareOptions();
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(this.environment.serviceHostUrl + 'ticket/updateTicketWithFile', input, options);
  }

  updateTicket(ticket: TicketModel): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'ticket/updateTicket', ticket, this.authService.prepareOptions());
  }

  fetchTicketDetails(ticketId: number): Observable<TicketModel> {
    return this.http.post<TicketModel>(this.environment.serviceHostUrl + 'ticket/fetchTicketDetails', {ticketId: ticketId}, this.authService.prepareOptions());
  }


  fetchTicketDocuments(ticketId: number): Observable<TicketDocument[]> {
    return this.http.post<TicketDocument[]>(this.environment.serviceHostUrl + 'ticket/fetchTicketDocuments', {ticketId: ticketId}, this.authService.prepareOptions());
  }

  fetchTicketDocument(ticketDocumentId: number): Observable<any> {
    let options = this.authService.prepareOptions();
    options['responseType'] = 'blob';
    return this.http.post<any>(this.environment.serviceHostUrl + 'ticket/fetchTicketDocument', {ticketDocumentId: ticketDocumentId}, options);
  }

  fetchTicketCrews(ticketId: number): Observable<TicketCrewModel[]> {
    return this.http.post<TicketCrewModel[]>(this.environment.serviceHostUrl + 'ticket/fetchTicketCrews', {ticketId: ticketId}, this.authService.prepareOptions());
  }

  assignTicketCrew(ticketId: number, ticketCrews: TicketCrewModel[]): Observable<TicketCrewModel[]> {
    let body={};
    body['ticketId'] = ticketId;
    body['ticketCrews'] = ticketCrews;
    return this.http.post<TicketCrewModel[]>(this.environment.serviceHostUrl + 'ticket/assignTicketCrew', body, this.authService.prepareOptions());
  }

  deleteTicket(ticketDeleteReq: TicketDeleteCloseRequestModel): Observable<TicketCrewModel[]> {
    return this.http.post<TicketCrewModel[]>(this.environment.serviceHostUrl + 'ticket/deleteTicket', ticketDeleteReq, this.authService.prepareOptions());
  }

  closeTicket(ticketCloseReq: TicketDeleteCloseRequestModel): Observable<TicketCrewModel[]> {
    return this.http.post<TicketCrewModel[]>(this.environment.serviceHostUrl + 'ticket/closeTicket', ticketCloseReq, this.authService.prepareOptions());
  }


  fetchAllApprovedRostersForCompanyEventAndRespondingCompanyDynamicFields(companyEventId: number): Observable<DynamicField[]> {
    return this.http.post<DynamicField[]>(this.environment.serviceHostUrl + 'ticket/fetchAllApprovedRostersForCompanyEventAndRespondingCompanyDynamicFields', {
      companyEventId: companyEventId
    }, this.authService.prepareOptions());
  }

  fetchAllDynamicFieldsOfTicketOfCompanyEvent(companyEventId: number): Observable<DynamicField[]> {
    return this.http.post<DynamicField[]>(this.environment.serviceHostUrl + 'ticket/fetchAllDynamicFieldsOfTicketOfCompanyEvent', {
      companyEventId: companyEventId
    }, this.authService.prepareOptions());
  }

  fetchAllWACForCompanyEvents(companyEventIds: number[]): Observable<TicketWACModel[]> {
    return this.http.post<TicketWACModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllWACForCompanyEvents',{companyEventIds:companyEventIds}, this.authService.prepareOptions());
  }

  fetchAllWorkLocationForCompanyEvents(companyEventIds: number[]): Observable<TicketWorkLocationModel[]> {
    return this.http.post<TicketWorkLocationModel[]>(this.environment.serviceHostUrl + 'ticket/fetchAllWorkLocationForCompanyEvents',{companyEventIds:companyEventIds}, this.authService.prepareOptions());
  }

}

