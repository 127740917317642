import {Component, OnInit, Optional} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {VerifyMfaPageComponent} from '../verify-mfa-page/verify-mfa-page.component';
import {AuthService} from '../../providers/auth-service';
import {LocalDataService} from '../../providers/local-data-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {LoginPageComponent} from '../login-page/login-page.component';
import {Validators} from '../../validators/validators';
import isEmail from "validator/lib/isEmail";
import {CommonService} from '../../providers/common-service';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.css']
})
export class SignUpPageComponent implements OnInit {

  signUp: ProfileModel;
  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  emailDomainError: string;
  //@Optional()
  public bsModalRef: BsModalRef;

  constructor(public iziToast: Ng2IzitoastService,  private spinner: NgxSpinnerService,private router: Router,
              private authService: AuthService, private modalService: BsModalService,
              private commonService: CommonService, private handleReqErr: HandleRequestError) { }

  ngOnInit() {
    this.signUp = new ProfileModel();
   // this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.getAllActiveCompanies());
    Promise.all(baseDataCalls).then((r) => {
   //   this.spinner.hide();
    }).catch((e) => {
      console.log(e);
  //    this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }
  activeCompanies: CompanyDetailsModel[];
  getAllActiveCompanies() {
    return new Promise(((resolve, reject) => {
      this.authService .getAllActiveCompanies().subscribe((res) => {
        console.log(res);
        this.activeCompanies = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  closeForm(){
    this.bsModalRef.hide();
  }

  getCompanyDetails(){
    this.emailDomainError = null;
    this.signUp.CompanyName = null;
    this.validateFields();
    if(this.signUp.eMail_ID && isEmail(this.signUp.eMail_ID)){
      this.authService.getCompanyName(this.signUp.eMail_ID).subscribe((res) => {
        console.log(res);
        if (res && res['CompanyName']) {
         console.log(res);
         //this.signUp.CompanyName = res['CompanyName'];
        // if(!this.signUp.CompanyId || this.signUp.CompanyId <=0){
           this.signUp.CompanyId = res['CompanyId'];

           this.toggleShowCompanyNameChangeInfo();
          this.signUp.BusinessName = res['CompanyName'];
           this.toggleShowBusinessNameChangeInfo();
         //}
        } else {
        //  this.emailDomainError = 'Your Email Domain does not have any Company linked to it, please give your official email id or check with your company for more details.';
        }
      }, (err) => {
        this.spinner.hide();
        this.validationError = this.handleReqErr.handleReqError(err, this.errors);
      });
    }
  }

  onCompanySelected(){
    if(this.signUp.CompanyId){
      for(let cm of this.activeCompanies){
        if(this.signUp.CompanyId === cm.CompanyId){
          this.signUp.BusinessName = cm.CompanyName;
          this.toggleShowBusinessNameChangeInfo();
          break;
        }
      }
    }
  }

  showCompanyNameChangeInfo = false;
  showBusinessNameChangeInfo = false;

  toggleShowCompanyNameChangeInfo(){
    this.showCompanyNameChangeInfo = true;
    /*setTimeout(()=>{
      this.showCompanyNameChangeInfo = false;
    }, 20000);*/
  }
  toggleShowBusinessNameChangeInfo(){
    this.showBusinessNameChangeInfo = true;
   /* setTimeout(()=>{
      this.showBusinessNameChangeInfo = false;
    }, 20000);*/
  }

  signupAttempted = false;
  validateFields(){
    if(!this.signupAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let err = this.signUp.validate();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }

  /*onPhoneNumberUpdated(){
    if(this.signUp.PhoneNumberMask){
      //this.signUp.PhoneNumber = '+1'+this.signUp.PhoneNumberMask;
      this.signUp.PhoneNumber = this.signUp.PhoneNumberMask;
    }
    this.validateFields();
  }*/
  signUpUser(){
    /*if(this.signUp.PhoneNumberMask){
      this.signUp.PhoneNumber = '+1'+this.signUp.PhoneNumberMask;
    }*/
    this.signupAttempted = true;
    /*if(!this.signUp.CompanyName){
      this.emailDomainError = 'Your Email Domain does not have any Company linked to it, please give your official email id or check with your company for more details.';
      return;
    }*/
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.signUp.validate();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
   /* if(this.signUp.sendEmailAlert){
      this.signUp.EmailAlerts = 1;
    }else{
      this.signUp.EmailAlerts = 0;
    }

    if(this.signUp.sendSMSAlert){
      this.signUp.SMSAlerts = 1;
    }else{
      this.signUp.SMSAlerts = 0;
    }*/

    this.spinner.show();
    this.authService.signup(this.signUp).subscribe((res) => {
      this.spinner.hide();
      if (res) {
        this.authService.token = res.token;
        this.spinner.hide();
       // this.authService.setToken(res.token);
       // this.bsModalRef.hide();
      //  this.bsModalRef = this.modalService.show(VerifyMfaPageComponent,{class: 'modal-dialog-centered ', initialState:{isSignUpSuccess: true}});
        //Navigate to verify mfa...
        this.authService.inSignUpProcess = true;
        this.router.navigate(['/new-user-2','verifyMFA']);
      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  smsAlertsStateChanged(){
    if(this.signUp.SMSAlerts){
      this.iziToast.info({
        close: true,
        closeOnEscape: true,
        overlay: true,
        timeout: 6000,
        title: 'Please make sure you have given you mobile number, to receive SMS Alert we need your mobile number. ',
        position: 'center',
      });
    }
  }
}
