import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {LoginResponseModel} from '../model/login-response-model';
import {environment} from '../../environments/environment';
import {LoginRequestModel} from '../model/login-request-model';
import {ProfileModel} from '../model/profile-model';
import {ServiceResponse} from '../model/service-response';
import {AuthService} from './auth-service';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  getUserDetails(): Observable<ProfileModel>{
    return this.http.post<ProfileModel>(this.environment.serviceHostUrl+'user/getUserDetails',{}, this.authService.prepareOptions());
  }

  updateProfile(profile: ProfileModel): Observable<ServiceResponse>{
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl+'user/updateProfile',profile, this.authService.prepareOptions());
  }

  updatePassword(oldPassword: string, newPassword:string): Observable<ServiceResponse>{
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl+'user/updatePassword',{newPassword:newPassword, oldPassword:oldPassword}, this.authService.prepareOptions());
  }

}
