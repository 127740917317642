import {Component, OnInit} from '@angular/core';
import {MasterEventModel, MasterEventTypeModel} from '../../model/master-event-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {LocalDataService} from '../../providers/local-data-service';
import {Router} from '@angular/router';
import {HandleRequestError} from '../../providers/handle-request-error';
import {MasterEventService} from '../../providers/master-event-service';
import {ValidationError} from '../../validators/validation-error';
import {ProfileModel} from '../../model/profile-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {StatusDetailsModel} from '../../model/status-details-model';
import {CommonService} from '../../providers/common-service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-manage-master-event-page',
  templateUrl: './modify-master-event-page.component.html',
  styleUrls: ['./modify-master-event-page.component.css']
})
export class ModifyMasterEventPageComponent implements OnInit {

  masterEvent: MasterEventModel;
  masterEventTypes: MasterEventTypeModel[];
  eventStatus: StatusDetailsModel[];

  isEditEvent: boolean;
  masterEventID: number;



  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private modalService: BsModalService,
              private spinner: NgxSpinnerService, private masterEventService: MasterEventService,
              private localDataService: LocalDataService, private router: Router,
              private handleReqErr: HandleRequestError, private commonService: CommonService) {
  }


  ngOnInit() {


    this.masterEvent = new MasterEventModel();
    this.isEditEvent = this.modalService.config.initialState['isEditEvent'];
    this.masterEventID = this.modalService.config.initialState['masterEventID'];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllMasterEvents());
    if(this.isEditEvent){
      baseDataCalls.push(this.fetchMasterEvents());
      baseDataCalls.push(this.fetchEventStatus());
    }
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }


  fetchAllMasterEvents() {
    return new Promise(((resolve, reject) => {
      this.masterEventService.fetchAllMasterEventsType().subscribe((res) => {
        console.log(res);
        this.masterEventTypes = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchEventStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('Master Event').subscribe((res) => {
        console.log(res);
        this.eventStatus = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchMasterEvents() {
    return new Promise(((resolve, reject) => {
      this.masterEventService.fetchMasterEvent(this.masterEventID).subscribe((res) => {
        console.log(res);
        this.masterEvent.mapMasterEvent( res);
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;
  validateFields(){
    if(!this.submitAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    if(this.isEditEvent){
      err =this.masterEvent.validateUpdateMasterEvent();
    }else{
      err =this.masterEvent.validateAddMasterEvent();
    }

    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }


  updateMasterEvent() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.masterEvent.validateUpdateMasterEvent();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.masterEventService.updateMasterEvent(this.masterEvent).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Updated Master Event',
        message: 'Master Event updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  addMasterEvent(close: boolean) {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.masterEvent.validateAddMasterEvent();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.masterEventService.addMasterEvent(this.masterEvent).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({
        title: 'Master Event Added',
        message: 'Master Event added successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.submitAttempted = false;
      if(close){
        this.bsModalRef.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }
}
