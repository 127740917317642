import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth-service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CompanyEventModel, DynamicField, RespondingCompanyUsers} from '../model/company-event-model';
import {environment} from '../../environments/environment';
import {ServiceResponse} from '../model/service-response';
import * as moment from 'moment';
import {ProfileModel} from '../model/profile-model';
import {
  RosterApproveRequestModel,
  RosterApproveResponseModel,
  RosterModel,
  RosterReleaseResponseModel, RosterReturnToHomeResponseModel,
  RosterWorkLocationModel
} from '../model/roster-model';
import {RosterCrewDetailsModel} from '../model/roster-crew-details-model';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';
import {RosterEquipmentDetailsModel} from '../model/roster-equipment-details-model';
import {ReportDataModel} from '../model/report-data-model';


@Injectable()
export class RosterService {
  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  fetchAllCompanyEventInvites(): Observable<CompanyEventModel[]> {
    return this.http.get<CompanyEventModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllCompanyEventInvites', this.authService.prepareOptions());
  }


  fetchAllNonReleasedRosters(): Observable<RosterModel[]> {
    return this.http.get<RosterModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllNonReleasedRosters', this.authService.prepareOptions());
  }
  fetchAllNonReleasedRostersDynamicFields(): Observable<DynamicField[]> {
    return this.http.get<DynamicField[]>(this.environment.serviceHostUrl + 'roster/fetchAllNonReleasedRostersDynamicFields', this.authService.prepareOptions());
  }


  fetchAllReleasedRosters(): Observable<RosterModel[]> {
    return this.http.get<RosterModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllReleasedRosters', this.authService.prepareOptions());
  }

  fetchAllClosedRosters(): Observable<RosterModel[]> {
    return this.http.get<RosterModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllClosedRosters', this.authService.prepareOptions());
  }
  fetchAllReturnedToHomeRosters(): Observable<RosterModel[]> {
    return this.http.get<RosterModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllReturnedToHomeRosters', this.authService.prepareOptions());
  }

  fetchAllRostersForCompanyEvent(companyEventId: number): Observable<any[]> {
    return this.http.post<any[]>(this.environment.serviceHostUrl + 'roster/fetchAllRostersForCompanyEvent', {companyEventId: companyEventId}, this.authService.prepareOptions());
  }

  fetchRosterDetails(rosterId: number): Observable<RosterModel> {
    return this.http.post<RosterModel>(this.environment.serviceHostUrl + 'roster/fetchRosterDetails', {rosterId: rosterId}, this.authService.prepareOptions());
  }

  checkIsRosterLinkedWithCompanyEventAlready(companyEventId: number, respondingCompanyID: number): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'roster/checkIsRosterLinkedWithCompanyEventAlready', {
      respondingCompanyID: respondingCompanyID,
      companyEventId: companyEventId
    }, this.authService.prepareOptions());
  }

  approveRoster(rosterApproveReq: RosterModel[]): Observable<RosterApproveResponseModel> {
    return this.http.post<RosterApproveResponseModel>(this.environment.serviceHostUrl + 'roster/approveRoster', rosterApproveReq, this.authService.prepareOptions());
  }


  updateRosterWorkLocation( roster:RosterModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/updateRosterWorkLocation', roster, this.authService.prepareOptions());
  }


  checkForRosterApproved(rosterApproveReq: RosterModel[]): Observable<RosterApproveResponseModel> {
    return this.http.post<RosterApproveResponseModel>(this.environment.serviceHostUrl + 'roster/checkForRosterApproved', rosterApproveReq, this.authService.prepareOptions());
  }
  checkForRosterReleased(rostersToRelease: RosterModel[]): Observable<RosterReleaseResponseModel> {
    return this.http.post<RosterReleaseResponseModel>(this.environment.serviceHostUrl + 'roster/checkForRosterReleased', rostersToRelease, this.authService.prepareOptions());
  }

  checkForRosterReturnToHome(rostersToReturnToHome: RosterModel[]): Observable<RosterReturnToHomeResponseModel> {
    return this.http.post<RosterReturnToHomeResponseModel>(this.environment.serviceHostUrl + 'roster/checkForRosterReturnToHome', rostersToReturnToHome, this.authService.prepareOptions());
  }

  updateRosterDynamicFields(rosterApproveReq: RosterApproveRequestModel): Observable<RosterCrewDetailsModel> {
    return this.http.post<RosterCrewDetailsModel>(this.environment.serviceHostUrl + 'roster/updateRosterDynamicFields', rosterApproveReq, this.authService.prepareOptions());
  }

  releaseRoster( rosterReleaseReq:RosterModel[]): Observable<RosterReleaseResponseModel> {
    return this.http.post<RosterReleaseResponseModel>(this.environment.serviceHostUrl + 'roster/releaseRoster', rosterReleaseReq, this.authService.prepareOptions());
  }


  markRosterAsReturnToHome( rostersToMarkReturnToHome:RosterModel[]): Observable<RosterReturnToHomeResponseModel> {
    return this.http.post<RosterReturnToHomeResponseModel>(this.environment.serviceHostUrl + 'roster/markRosterAsReturnToHome', rostersToMarkReturnToHome, this.authService.prepareOptions());
  }


  uploadRoster(file: File, CompanyEventId: number, MasterEventId: number, rosterName: string): Observable<any> {
    let input = new FormData();
    input.append('Rosters', file);
    input.append('CompanyEventId', '' + CompanyEventId);
    input.append('MasterEventId', '' + MasterEventId);
    if (rosterName) {
      input.append('RosterName', '' + rosterName);
    }

    let options = this.authService.prepareOptions();
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(this.environment.serviceHostUrl + 'roster/uploadRoster', input, options);
  }


  fetchAllRosterCrews(rosterId: number): Observable<RosterCrewDetailsModel[]> {
    return this.http.post<RosterCrewDetailsModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllRosterCrews', {rosterId: rosterId}, this.authService.prepareOptions());
  }

  fetchRosterCrewsDetails(rosterCrewDetailsId: number): Observable<RosterCrewDetailsModel> {
    return this.http.post<RosterCrewDetailsModel>(this.environment.serviceHostUrl + 'roster/fetchRosterCrewsDetails', {rosterCrewDetailsId: rosterCrewDetailsId}, this.authService.prepareOptions());
  }

  addCrew(rosterCrewDetails: RosterCrewDetailsModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/addCrew', rosterCrewDetails, this.authService.prepareOptions());
  }

  updateCrew(rosterCrewDetails: RosterCrewDetailsModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/updateCrew', rosterCrewDetails, this.authService.prepareOptions());
  }

  deleteRosterCrewsDetails(rosterCrewDetailsIds: string, rosterId: number): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/deleteRosterCrewsDetails', {rosterCrewDetailsIds: rosterCrewDetailsIds, rosterId: rosterId}, this.authService.prepareOptions());
  }

  fetchAllRosterEquipments(rosterId: number): Observable<RosterEquipmentDetailsModel[]> {
    return this.http.post<RosterEquipmentDetailsModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllRosterEquipments', {rosterId: rosterId}, this.authService.prepareOptions());
  }

  fetchRosterEquipmentDetails(rosterEquipmentDetailsId: number): Observable<RosterEquipmentDetailsModel> {
    return this.http.post<RosterEquipmentDetailsModel>(this.environment.serviceHostUrl + 'roster/fetchRosterEquipmentDetails', {rosterEquipmentDetailsId: rosterEquipmentDetailsId}, this.authService.prepareOptions());
  }

  addEquipment(rosterEquipmentDetails: RosterEquipmentDetailsModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/addEquipment', rosterEquipmentDetails, this.authService.prepareOptions());
  }

  updateEquipment(rosterEquipmentDetails: RosterEquipmentDetailsModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/updateEquipment', rosterEquipmentDetails, this.authService.prepareOptions());
  }

  deleteRosterEquipmentsDetails(rosterEquipmentDetailsIds: string, rosterId: number): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/deleteRosterEquipmentsDetails', {rosterEquipmentDetailsIds: rosterEquipmentDetailsIds, rosterId: rosterId}, this.authService.prepareOptions());
  }


  updateRoster(rosterId: number, status: number, rosterName: string) {
    let body = {
      rosterId: rosterId,
      status: status,
      rosterName: rosterName,
    };
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/updateRoster', body, this.authService.prepareOptions());
  }

  assignOrPullRoster(rostersToAssign: RosterModel[]) {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'roster/assignOrPullRoster', rostersToAssign, this.authService.prepareOptions());
  }

  fetchAllRostersForCompanyEventAndRespondingCompany(companyEventId: number, respondingCompanyId: number): Observable<any[]> {
    return this.http.post<any[]>(this.environment.serviceHostUrl + 'roster/fetchAllRostersForCompanyEventAndRespondingCompany', {
      companyEventId: companyEventId,
      respondingCompanyId: respondingCompanyId
    }, this.authService.prepareOptions());
  }

  fetchAllApprovedRostersForCompanyEventAndRespondingCompany(companyEventId: number, dynamicFields: DynamicField[]): Observable<any[]> {
    return this.http.post<any[]>(this.environment.serviceHostUrl + 'roster/fetchAllApprovedRostersForCompanyEventAndRespondingCompany', {
      companyEventId: companyEventId,
      dynamicFields: dynamicFields
    }, this.authService.prepareOptions());
  }

  fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations(companyEventId: number, workLocations: number[]): Observable<any[]> {
    return this.http.post<any[]>(this.environment.serviceHostUrl + 'roster/fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations', {
      companyEventId: companyEventId,
      workLocations: workLocations
    }, this.authService.prepareOptions());
  }

  fetchAllApprovedRostersForCompanyEventAndRespondingCompanyDynamicFields(companyEventId: number): Observable<DynamicField[]> {
    return this.http.post<DynamicField[]>(this.environment.serviceHostUrl + 'roster/fetchAllApprovedRostersForCompanyEventAndRespondingCompanyDynamicFields', {
      companyEventId: companyEventId
    }, this.authService.prepareOptions());
  }

  fetchAllWorkLocationsByApprovedRostersForCompanyEventAndRespondingCompany(companyEventId: number): Observable<RosterWorkLocationModel[]> {
    return this.http.post<RosterWorkLocationModel[]>(this.environment.serviceHostUrl + 'roster/fetchAllWorkLocationsByApprovedRostersForCompanyEventAndRespondingCompany', {
      companyEventId: companyEventId
    }, this.authService.prepareOptions());
  }

  fetchRosterDetailsForReport(companyEventIds: string, rosterIds:string): Observable<ReportDataModel> {

    return this.http.post<ReportDataModel>(this.environment.serviceHostUrl + 'roster/fetchRosterDetailsForReport', {
      companyEventIds: companyEventIds, rosterIds: rosterIds
    }, this.authService.prepareOptions());
  }


  fetchRosterDetailsForReportV2(companyEventIds: string, rosterIds:string): Observable<any> {
    let options = this.authService.prepareOptions();
    let params = {};
    let filter = btoa(JSON.stringify({companyEventIds: companyEventIds, rosterIds: rosterIds}));
    params['filter'] = filter;
    options['params'] = params;
    options['responseType'] = 'blob';
/*    var url = "http://localhost:3008/roster/fetchRosterDetailsForReportV2?filter=eyJjb21wYW55RXZlbnRJZHMiOiI0NiIsInJvc3RlcklkcyI6bnVsbH0=";
    var win = window.open(url, '_blank');
    win.opener = null;
    win.focus();
    win.addEventListener('load', function () {
      win.close();
    });*/
    return this.http.get<any>(this.environment.serviceHostUrl + 'roster/fetchRosterDetailsForReportV2', options);
  }

  activateRosterCrews( rosterCrewIds:number[]): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'roster/activateRosterCrews', rosterCrewIds, this.authService.prepareOptions());
  }

  deActivateRosterCrews( rosterCrewIds:number[]): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'roster/deActivateRosterCrews', rosterCrewIds, this.authService.prepareOptions());
  }

}
