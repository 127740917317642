import { AuthService } from './auth-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment-service';
import { Observable } from 'rxjs';
import { CompanyDetailsModel } from '../model/company-details-model';
import { ServiceResponse } from '../model/service-response';



@Injectable()
export class CompanyManagementService {
  constructor(private Http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  getCompanyDetailsById(CompanyId: number): Observable<CompanyDetailsModel> {
    return this.Http.post<CompanyDetailsModel>(this.environment.serviceHostUrl + 'companyMgmt/getCompanyDetailsById', {
      CompanyId: CompanyId
    }, this.authService.prepareOptions());
  }

  getAllCompany(): Observable<CompanyDetailsModel[]> {
    return this.Http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'companyMgmt/getAllCompany', {
    }, this.authService.prepareOptions());
  }

  addCompany(company: CompanyDetailsModel): Observable<ServiceResponse> {
    return this.Http.post<ServiceResponse>(this.environment.serviceHostUrl + 'companyMgmt/addCompany', company,
    this.authService.prepareOptions());
  }

  updateCompany(company: CompanyDetailsModel): Observable<ServiceResponse> {
    return this.Http.post<ServiceResponse>(this.environment.serviceHostUrl + 'companyMgmt/updateCompany', company,
    this.authService.prepareOptions());
  }
}
