import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserManagementService} from '../../providers/user-mgmt-service';
import {CommonService} from '../../providers/common-service';
import {UserRoleModel} from '../../model/user-role-model';
import {StatusDetailsModel} from '../../model/status-details-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {HandleRequestError} from '../../providers/handle-request-error';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ModifyUserPageComponent} from '../modify-user-page/modify-user-page.component';
import {UploadUserPageComponent} from '../upload-user-page/upload-user-page.component';
import {ProfileModel} from '../../model/profile-model';
import {Subscription} from 'rxjs';
import {AuthService} from '../../providers/auth-service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.css']
})
export class UserManagementPageComponent implements OnInit, OnDestroy {

  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }
  bsModalRef: BsModalRef;
  expanded: any = {};

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private userMgmtService: UserManagementService, private handleReqErr: HandleRequestError,
              private commonService: CommonService, private modalService: BsModalService,
  ) {
  }

  public superAdminTblColumns: Array<any> = [
    {title: 'FIRST NAME', name: 'JOB_NUMBER', filtering: {filterString: '', placeholder: ''}},
    {title: 'LAST NAME', name: 'WORK_ORDER', filtering: {filterString: '', placeholder: ''}},
    {title: 'COMPANY NAME', name: 'WORK_ORDER_DESCRIPTION', filtering: {filterString: '', placeholder: ''}},
    {title: 'LAST MODIFIED', name: 'PARENT_WORK_ORDER_DESCRIPTION', filtering: {filterString: '', placeholder: ''}},
    {title: 'ROLE', name: 'TASK_DESCRIPTION', filtering: {filterString: '', placeholder: ''}},
    {title: 'STATUS', name: 'COMBINED_SERVICE_ADDRESS', filtering: {filterString: '', placeholder: ''}},
  ];

  public adminTblColumns: Array<any> = [
    {title: 'FIRST NAME', name: 'JOB_NUMBER', filtering: {filterString: '', placeholder: ''}},
    {title: 'LAST NAME', name: 'WORK_ORDER', filtering: {filterString: '', placeholder: ''}},
    {title: 'LAST MODIFIED', name: 'PARENT_WORK_ORDER_DESCRIPTION', filtering: {filterString: '', placeholder: ''}},
    {title: 'ROLE', name: 'TASK_DESCRIPTION', filtering: {filterString: '', placeholder: ''}},
    {title: 'STATUS', name: 'COMBINED_SERVICE_ADDRESS', filtering: {filterString: '', placeholder: ''}},
  ];

  public userTblColumns: Array<any>;

  selected: ProfileModel[] = [];



  getRowClass(row): any {
    return {
      'disabled-row': row['UserRole'] === environment.userRoles.SuperAdministrator && this.userRole !== environment.userRoles.SuperAdministrator
    };
  }
  getCheckboxClass({row, column, value}): any {
    return {
      'hide-checkbox':  row['UserRole'] === environment.userRoles.SuperAdministrator && this.userRole !== environment.userRoles.SuperAdministrator
    };
  }
  selectCheck(row, column, value) {
    return !(row['UserRole'] === environment.userRoles.SuperAdministrator && this.userRole !== environment.userRoles.SuperAdministrator);
  }

  updateUserStatus(status: number) {
    if (this.selected.length > 0) {
      this.spinner.show();
      let userIds = [];
      for (let usr of this.selected) {
        userIds.push(usr.UserId);
      }
      console.log('userIds', userIds);
      this.userMgmtService.updateUserStatus(userIds.join(','), status).subscribe((r) => {
        let title = 'Users Activated';
        let description = 'Selected users are activated';
        //CH1
        if(status === environment.moduleStatus.UserManagementInActive){
          title = 'Users Deactivated';
          description = 'Selected users are deactivated';
        }
        this.iziToast.success({
          title: title,
          message: description,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.spinner.hide();
        this.loadData();
      }, (e) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(e);
      });
    }
  }

  activateUser() {
    this.updateUserStatus(1);
  }

  deactivateUser() {
    this.updateUserStatus(2);
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.users) {
      if (this.selected.length === this.users.length) {
        this.iziToast.warning({
          title: 'All Users selected',
          message: 'You have selected all the users. Total users selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      //CH1
      if (sel.Status === environment.moduleStatus.UserManagementActive && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      //CH1
      if (sel.Status === environment.moduleStatus.UserManagementInActive && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }

    if (this.selected.length === 1) {
      this.disableEditBtn = false;
    } else {
      this.disableEditBtn = true;
    }

    if (this.selected.length > 0) {
      this.disableActivateBtn = !allInActiveUsers;
      this.disableInActivateBtn = !allActiveUsers;
    } else {
      this.disableActivateBtn = true;
      this.disableInActivateBtn = true;
    }
    for (let sel of this.selected) {
      if(sel['UserRole'] === environment.userRoles.SuperAdministrator && this.userRole !== environment.userRoles.SuperAdministrator){
        this.disableActivateBtn = true;
        this.disableInActivateBtn = true;
        this.disableEditBtn = true;
        break;
      }
    }
  }

  disableApproveBtn = false;
  disableActivateBtn = true;
  disableInActivateBtn = true;
  disableEditBtn = true;

  ngOnInit() {

    this.loadData();
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });

  }

  loadData() {
    this.spinner.show();
    this.selected = [];
    this.disableActivateBtn = true;
    this.disableInActivateBtn = true;
    this.disableEditBtn = true;

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    let baseDataCalls = [];
    baseDataCalls.push(this.getAllUsers());
    baseDataCalls.push(this.getModuleStatus());
    baseDataCalls.push(this.getAllRoles());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      /*this.iziToast.error({
        title: 'Server Error',
        message: 'Internal server error, please try again later or refresh the page.',
        position: 'center',
        timeout: 0,
        close: false,
        overlay: true
      });*/
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;
  userRoles: UserRoleModel[];
  userModuleStatus: StatusDetailsModel[];
  users: ProfileModel[];
  usersRows: ProfileModel[];


getAllRoles() {
    return new Promise(((resolve, reject) => {
      this.commonService.getUserRoles().subscribe((res) => {
        console.log(res);
        this.userRoles = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  getModuleStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('User Management').subscribe((res) => {
        console.log(res);
        this.userModuleStatus = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  getAllUsers() {
    return new Promise(((resolve, reject) => {
      this.userMgmtService.getAllUsers().subscribe((res) => {
        console.log(res);
        this.users = res;
        this.usersRows = [...this.users];
        //this.temp = [...this.users];
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.users.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.usersRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableActivateBtn = true;
    this.disableInActivateBtn = true;
  }

  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.FirstName, val) || this.matchContent(d.LastName, val) ||
      this.matchContent(d.CompanyName, val) || this.matchContent(d.RoleName, val) ||  this.matchContent(d.BusinessName, val) ||
      this.matchContent(d.LastUpdated, val) || this.matchContent(d.StatusValue, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  getUserStatusClass({row, column, value}): any {
    return {
      'user-active': value === 'Active',
      'user-in-active': value === 'In-Active',
      'user-registered': value === 'Registered',
      'user-locked': value === 'Locked',
    };
  }

  addUser() {
    this.bsModalRef = this.modalService.show(ModifyUserPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditUser: false},
      backdrop: 'static'
    });
  }


  editUser() {
    this.bsModalRef = this.modalService.show(ModifyUserPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditUser: true, UserId: this.selected[0].UserId, CompanyId: this.selected[0].CompanyId},
      backdrop: 'static'
    });
  }


  uploadUser() {
    this.bsModalRef = this.modalService.show(UploadUserPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditUser: false},
      backdrop: 'static'
    });
  }


  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
}
