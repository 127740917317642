import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';

export class MasterEventModel {
  EventId: number;
  EventName: string;
  EventTypeId: number;
  EventType: string;
  CreatedBy: number;
  CreatedDate: string;
  UpdatedBy: number;
  UpdatedByName: string;
  UpdatedDate: string;
  Status: number;
  StatusValue: string;
  AuditTrailId: number;
  CompanyEventCounts: number;
  DF01: string;
  DF02: string;
  DF03: string;
  DF04: string;
  DF05: string;
  DF06: string;
  DF07: string;
  DF08: string;
  DF09: string;
  DF10: string;


  validateAddMasterEvent(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'EventName', this.EventName, 'Event Name');
    validator.requiredNumber(error, 'EventTypeId', this.EventTypeId, 'Event Type');
    //validator.requiredNumber(error, 'Status', this.Status, 'Event Status');
    return error;
  }
  validateUpdateMasterEvent(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'EventName', this.EventName, 'Event Name');
    validator.requiredNumber(error, 'EventTypeId', this.EventTypeId, 'Event Type');
    validator.requiredNumber(error, 'Status', this.Status, 'Event Status');
    return error;
  }

  mapMasterEvent(masterEvent){
    this.EventId = masterEvent['EventId'];
    this.EventName = masterEvent['EventName'];
    this.EventTypeId = masterEvent['EventTypeId'];
    this.Status = masterEvent['Status'];
    
    this.DF01 = masterEvent['DF01'];
    this.DF02 = masterEvent['DF02'];
    this.DF03 = masterEvent['DF03'];
    this.DF04 = masterEvent['DF04'];
    this.DF05 = masterEvent['DF05'];
    this.DF06 = masterEvent['DF06'];
    this.DF07 = masterEvent['DF07'];
    this.DF08 = masterEvent['DF08'];
    this.DF09 = masterEvent['DF09'];
    this.DF10 = masterEvent['DF10'];
  }
}

export class MasterEventTypeModel {
  EventTypeId: number;
  EventType: string;
}
