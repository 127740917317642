import {Component, OnInit, Optional} from '@angular/core';
import {ValidationError} from '../../validators/validation-error';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ForgotPasswordService} from '../../providers/forgot-password-service';
import {LocalDataService} from '../../providers/local-data-service';
import {Router} from '@angular/router';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {HandleRequestError} from '../../providers/handle-request-error';
import {LoginPageComponent} from '../login-page/login-page.component';
import {Validators} from '../../validators/validators';

@Component({
  selector: 'app-set-first-password-page',
  templateUrl: './set-first-password-page.component.html',
  styleUrls: ['./set-first-password-page.component.css']
})
export class SetFirstPasswordPageComponent implements OnInit {


  eMail_ID:string;
  password:string;
  confirmPassword:string;
  fpVerificationCode:number;
  fpStep:string;
  errors: string[] = [];
  info: string[] = [];
  validationError: ValidationError = new ValidationError();
  constructor(@Optional() public bsModalRef: BsModalRef, private spinner: NgxSpinnerService, private modalService: BsModalService,
              private forgotPasswordService: ForgotPasswordService, private localDataService: LocalDataService,
              private router: Router, public iziToast: Ng2IzitoastService, private handleReqErr: HandleRequestError) {

  }


  ngOnInit() {
    this.fpStep = '1';
  }

  backToLogin(){
   // this.showModel(LoginPageComponent, {class: 'modal-dialog-centered ',});
    this.router.navigate(['/auth','login']);
  }

  closeMFA(){
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  sendFPVerificationCode(){
    this.errors = [];
    this.info = [];
    let validator = new Validators();

    this.validationError = new ValidationError();
    validator.validateEmail(this.validationError, 'eMail_ID', this.eMail_ID , 'Email');
    if(this.validationError['eMail_ID']){
      return;
    }
    this.spinner.show();
    this.forgotPasswordService.fpSendVerificationCode(this.eMail_ID,'false').subscribe((res)=>{
      if (res && res.token) {
        this.forgotPasswordService.token = res.token;

        this.fpStep = '2';
        this.info.push('Verification Code is sent to your registered Email. Please check it.');
        this.spinner.hide();
      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  verifyFPCode(){
    this.errors = [];
    this.info = [];
    this.validationError = new ValidationError();
    if(this.fpVerificationCode === null || this.fpVerificationCode === 0){
      this.validationError['fpVerificationCode']  = ['Invalid Verification code, please enter a valid one'];
    }

    this.spinner.show();
    this.forgotPasswordService.verifyFPCode(this.fpVerificationCode).subscribe((res)=>{
      if (res && res.token) {
        this.forgotPasswordService.token = res.token;
        this.fpStep = '3';
        this.spinner.hide();
      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  changePassword(){
    this.errors = [];
    this.info = [];
    let validator = new Validators();

    this.validationError = new ValidationError();
    validator.strongPasswordValidator(this.validationError, 'password', this.password , 'Password');
    validator.validatePasswordMatch(this.validationError, 'confirmPassword', this.confirmPassword , this.password , 'Confirm Password');
    if(this.validationError['password'] || this.validationError['confirmPassword']){
      return;
    }
    this.spinner.show();
    this.forgotPasswordService.fpChangePassword(this.password,'false').subscribe((res)=>{
      this.spinner.hide();
      this.backToLogin();
      this.iziToast.success({
        title: 'Password Changed',
        message: 'You have changed the password successfully!',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      })
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }
  resendCode(){
    this.errors = [];
    this.info = [];
    this.spinner.show();
    this.forgotPasswordService.fpResendVerificationCode('false').subscribe((res)=>{
      this.info.push('New Verification Code is sent to your registered Email. Please check it.');
      this.spinner.hide();
    }, (err)=>{
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  backToStep1(){
    this.fpStep = '1';
  }

  showModel(page, config) {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = this.modalService.show(page, config);
  }
}
