import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CompanyEventModel} from '../../model/company-event-model';
import {ValidationError} from '../../validators/validation-error';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {HandleRequestError} from '../../providers/handle-request-error';
import {RosterService} from '../../providers/roster-service';
import {Validators} from '../../validators/validators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FileUploader} from 'ng2-file-upload';
import {AuthService} from '../../providers/auth-service';
import {EnvironmentService} from '../../providers/environment-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {CommonUtil} from '../../util/common-util';

@Component({
  selector: 'app-upload-roster-page',
  templateUrl: './upload-roster-page.component.html',
  styleUrls: ['./upload-roster-page.component.css']
})
export class UploadRosterPageComponent implements OnInit {

  companyEvent :CompanyEventModel;
  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  rosterName:string;
  templateURL : string;
  showCompanyEventSelect : boolean;
  companyEventId: number;
  companyEvents: CompanyEventModel[] = [];
  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;
  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService, private companyEventService: CompanyEventsService,
              private rosterService: RosterService, private modalService: BsModalService, private authService: AuthService, private commonUtil: CommonUtil,
              private handleReqErr: HandleRequestError, private environment: EnvironmentService) { }

  ngOnInit() {
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.templateURL = this.environment.serviceHostUrl + 'MARC-RosterTemplate.xlsx';
    this.companyEvent = this.modalService.config.initialState['companyEvent'];
    this.showCompanyEventSelect = this.modalService.config.initialState['showCompanyEventSelect'];
    if(!this.showCompanyEventSelect){
      this.companyEventId = this.companyEvent.CompanyEventId;
    }else{
      this.fetchAllCompanyEventInvites();
    }


  }

  fetchAllCompanyEventInvites() {
    this.spinner.show();
    this.companyEventService.fetchAllCompanyEventInvites().subscribe((res) => {
      console.log(res);
      if (res) {
        this.companyEvents = res;
        this.prepareCompanyEvent();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.iziToast.warning({
          title: 'No Company Events Invites Available for the Master Event.',
          message: 'No Company Events Available Invites to assign Roster to it.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false,
          onOpened: ()=>{
            console.log('Error closed');
            this.close();
          }
        });
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }


  prepareCompanyEventName(companyEventId){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }

  public fileOverAnother(e: any): void {
    // this.hasAnotherDropZoneOver = e;
    console.log('File updated');
    if (this.uploader.queue.length === 1) {
      this.selectedFile = this.uploader.queue[0]._file;
    }else if(this.uploader.queue.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }else{
      this.iziToast.error({
        title: 'Invalid file type',
        message: 'Please drop only Excel or CSV file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }
    this.validateFields();
  }
  onCompanyEventSelect(){
    for(let ce of this.companyEvents){
      if(ce.CompanyEventId === this.companyEventId){
        this.companyEvent = ce;
        break;
      }
    }
  }

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    if(this.showCompanyEventSelect){
      validator.requiredNumber(this.validationError, 'companyEventId', this.companyEventId, 'Company Event');
      if (this.validationError && this.validationError['companyEventId']) {
        return;
      }
    }

  }

  prepareCompanyEvent() {
    if (!this.companyEvents || this.companyEvents.length <= 0) {
      return;
    }
    for (let ce of this.companyEvents) {
      if(ce.CompanyEventName && ce.CompanyEventName !== 'null'){
        ce.FormattedCompanyEventName = ce.MasterEventName +' - '+ ce.CompanyEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RequestingCompanyName;
      }else{
        ce.FormattedCompanyEventName = ce.MasterEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RequestingCompanyName;
      }

      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }

  checkIsRosterLinkedWithCompanyEventAlready(){
    this.spinner.show();
    this.rosterService.checkIsRosterLinkedWithCompanyEventAlready(this.companyEvent.CompanyEventId, this.authService.userDetails.companyId).subscribe((resp)=>{
      this.spinner.hide();
      if(resp['RostersCount'] > 0){
        this.iziToast.warning({
          title: 'Roster already uploaded',
          message: 'Already roster is uploaded for this company event by your company. Please go to Roster and update it, if needed.',
          position: 'center',
          timeout: 3000,
          close: true,
          overlay: true,
          onOpened: ()=>{
            console.log('Error closed');
            this.close();
          }
        });
      }else{
        // Nothing to do!
      }
    },(e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }
  close(){
    this.bsModalRef.hide();
  }


  submitAttempted= false;


  public hasAnotherDropZoneOver: boolean = false;
  @ViewChild('uploadFileSelect')
  uploadFileSelect: ElementRef;

  public uploader: FileUploader = new FileUploader({url: "",allowedFileType:['xlsx','xls']});
  selectedFile: File;

  uploadedPercentage: any;
  uploadProgress: number;
  showUploadProgress: boolean = false;


  duplicateCrewIds: string[] = [];
  duplicateEqipIds: string[] = [];


  fileSelected(e){
    // this.hasAnotherDropZoneOver = e;
    console.log(this.uploadFileSelect.nativeElement);
    if(this.uploadFileSelect.nativeElement.files.length === 1){
      this.selectedFile = this.uploadFileSelect.nativeElement.files[0];
    }else if(this.uploadFileSelect.nativeElement.files.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }else{
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }
    //this.selectedFile = this.uploadFileSelect.files;
    //this.fileName = this.selectedFile.name;
  }
  isFieldError = false;
  uploadFile(){
    this.isFieldError = false;
    this.submitAttempted = true;
    this.errors = [];
    this.duplicateCrewIds = [];
    this.duplicateEqipIds = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    if(this.selectedFile){
      validator.requiredString(this.validationError, 'selectedFile', this.selectedFile.name, 'This');
    }else{
      validator.requiredString(this.validationError, 'selectedFile', null, 'This');
    }
    if(this.showCompanyEventSelect){
      validator.requiredNumber(this.validationError, 'companyEventId', this.companyEventId, 'Company Event');
      if (this.validationError && (this.validationError['companyEventId'] || this.validationError['selectedFile'])) {
        return;
      }
    }else{
      if (this.validationError && this.validationError['selectedFile']) {
        return;
      }
    }

    window.scroll(0, 0);
    this.rosterService.uploadRoster(this.selectedFile, this.companyEventId, this.companyEvent.MasterEventId, this.rosterName).subscribe((event: HttpEvent<any>) => {
      this.uploadFileSelect.nativeElement.value = '';
      this.selectedFile = null;
      this.uploader.clearQueue();
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.Response:
          this.showUploadProgress = false;
          this.uploadedPercentage = 0;
          this.uploadProgress = 0;
          this.bsModalRef.hide();
          this.iziToast.success({title: 'Roster added', message: 'Roster added successfully!.', position: 'center'});
          break;
        case 1: {
          this.showUploadProgress = true;
          if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
            this.uploadedPercentage = event['loaded'] / event['total'] * 100;
            this.uploadProgress = Math.round(this.uploadedPercentage);
          }
          break;
        }

      }
    }, (err) => {
      this.showUploadProgress = false;
      this.uploadedPercentage = 0;
      this.uploadProgress = 0;
      console.log(err);
      if(err.status === 422 || err.status === 400){
        if(err.error.message){
          this.errors.push(err.error.message);
          this.isFieldError = false;
        }else{
          this.isFieldError = true;
          this.errors.push(...err.error);
        }
        if(err.error.duplicateCrewIds){
          this.duplicateCrewIds.push(...err.error.duplicateCrewIds);
        }
        if(err.error.duplicateEqipIds){
          this.duplicateEqipIds.push(...err.error.duplicateEqipIds);
        }

      }else{
        this.handleReqErr.handlePageLoadError(err);
      }

    });
  }

}
