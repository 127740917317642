import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {PublicWelcomePageComponent} from './pages/public-welcome-page/public-welcome-page.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {SignUpPageComponent} from './pages/sign-up-page/sign-up-page.component';
import {VerifyMfaPageComponent} from './pages/verify-mfa-page/verify-mfa-page.component';
  import {NgxSpinnerModule} from 'ngx-spinner';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ValidationErrorDisplayPage} from './components/validation-error-display/validation-error-display';
import {AuthService} from './providers/auth-service';
import {LocalDataService} from './providers/local-data-service';
import {HandleRequestError} from './providers/handle-request-error';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {UserProfilePageComponent} from './pages/user-profile-page/user-profile-page.component';
import {Ng2IziToastModule, Ng2IzitoastService} from 'ng2-izitoast';
import {UserService} from './providers/user-service';
import {UserManagementPageComponent} from './pages/user-management-page/user-management-page.component';
import {UserManagementService} from './providers/user-mgmt-service';
import {CommonService} from './providers/common-service';
import {Ng2TableModule} from 'ng2-table';
import {PaginationModule, PopoverModule} from 'ngx-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ModifyUserPageComponent} from './pages/modify-user-page/modify-user-page.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {UploadUserPageComponent} from './pages/upload-user-page/upload-user-page.component';
import {FileUploadModule} from 'ng2-file-upload';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ForgotPasswordPageComponent} from './pages/forgot-password-page/forgot-password-page.component';
import {ForgotPasswordService} from './providers/forgot-password-service';
import {MasterEventsPageComponent} from './pages/master-events-page/master-events-page.component';
import {ModifyMasterEventPageComponent} from './pages/modify-master-event-page/modify-master-event-page.component';
import {CompanyEventsPageComponent} from './pages/company-events-page/company-events-page.component';
import {ModifyCompanyEventsPageComponent} from './pages/modify-company-events-page/modify-company-events-page.component';
import {MasterEventService} from './providers/master-event-service';
import {InputFocusDirective} from './directives/input-focus.directive';
import {CompanyEventsService} from './providers/company-events-service';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {UploadRosterPageComponent} from './pages/upload-roster-page/upload-roster-page.component';
import {RosterService} from './providers/roster-service';
import {RostersPageComponent} from './pages/rosters-page/rosters-page.component';
import {RosterDetailsPageComponent} from './pages/roster-details-page/roster-details-page.component';
import {ModifyCrewDetailsPageComponent} from './pages/modify-crew-details-page/modify-crew-details-page.component';
import {CommonUtil} from './util/common-util';
import {ModifyRosterPageComponent} from './pages/modify-roster-page/modify-roster-page.component';
import {CompanyEventRespondingCompanyPageComponent} from './pages/company-event-responding-company-page/company-event-responding-company-page.component';
import {UnderConstructionComponent} from './pages/under-construction/under-construction.component';
import {SetFirstPasswordPageComponent} from './pages/set-first-password-page/set-first-password-page.component';
import {EnvServiceProvider} from './providers/environment-service';
import {RosterCrewDetailsPageComponent} from './pages/roster-crew-details-page/roster-crew-details-page.component';
import {RosterEquipmentDetailsPageComponent} from './pages/roster-equipment-details-page/roster-equipment-details-page.component';
import {ModifyEquipmentDetailsPageComponent} from './pages/modify-equipment-details-page/modify-equipment-details-page.component';
import {CompanyEventQuickViewPageComponent} from './pages/company-event-quick-view-page/company-event-quick-view-page.component';
import {ModifyRosterDetailsPageComponent} from './pages/modify-roster-details-page/modify-roster-details-page.component';
import {AssignRosterToCompanyEventPageComponent} from './pages/assign-roster-to-company-event-page/assign-roster-to-company-event-page.component';
import {TicketsPageComponent} from './pages/tickets-page/tickets-page.component';
import {TicketService} from './providers/ticket-service';
import {CreateTicketPageComponent} from './pages/create-ticket-page/create-ticket-page.component';
import {UpdateTicketPageComponent} from './pages/update-ticket-page/update-ticket-page.component';
import {AssignCrewToTicketPageComponent} from './pages/assign-crew-to-ticket-page/assign-crew-to-ticket-page.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {TicketDocumentViewerPageComponent} from './pages/ticket-document-viewer-page/ticket-document-viewer-page.component';
import {CreateMultipleTicketsPageComponent} from './pages/create-multiple-tickets-page/create-multiple-tickets-page.component';
import {FieldResourceTicketPageComponent} from './pages/field-resource-ticket-page/field-resource-ticket-page.component';
import {FieldResourceLoginPageComponent} from './pages/field-resource-login-page/field-resource-login-page.component';
import {FieldResourceService} from './providers/field-resource-service';
import {Location} from '@angular/common';
import {ReportsPageComponent} from './pages/reports-page/reports-page.component';
import {CompanyManagementPageComponent} from './pages/company-management-page/company-management-page.component';
import {ManageCompanyPageComponent} from './pages/manage-company-page/manage-company-page.component';
import {CompanyManagementService} from './providers/company-management-service';
import {HelpPageComponent} from './pages/help-page/help-page.component';
import {TrainingPageComponent} from './pages/training-page/training-page.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import { PublicTrainingPageComponent } from './pages/public-training-page/public-training-page.component';
//export const options: Partial<IConfig> | (() => Partial<IConfig>) = new Partial<IConfig>(); , canActivate: [AuthGuard]
const appRoutes: Routes = [
  {
    path: 'new-user-1', component: PublicWelcomePageComponent, children: [
      {path: 'register', component: SignUpPageComponent},
    ]
  },
  {
    path: 'new-user-2', component: PublicWelcomePageComponent, children: [
      {path: 'verifyMFA', component: VerifyMfaPageComponent},
    ]
  },
  {
    path: 'new-user', component: PublicWelcomePageComponent, children: [
      {path: 'setPassword', component: SetFirstPasswordPageComponent},
    ]
  },{
    path: 'user', component: PublicWelcomePageComponent, children: [
      {path: 'forgotPassword', component: ForgotPasswordPageComponent},
    ]
  },
  {
    path: 'auth', component: PublicWelcomePageComponent, children: [
      {path: 'login', component: LoginPageComponent},
    ]
  },
  {path: 'forgotPassword', component: PublicWelcomePageComponent},
  {path: 'training', component: PublicTrainingPageComponent},
  {path: 'fieldResource/Auth/:authToken', component: FieldResourceLoginPageComponent},
  {path: 'fieldResource/tickets', component: FieldResourceTicketPageComponent},
  {
    path: 'home', component: HomePageComponent, canActivate: [AuthGuard],
    children: [
      {path: 'updateProfile', component: UserProfilePageComponent},
      {path: 'manageMasterEvent', component: MasterEventsPageComponent},
      {path: 'manageCompanyEvent', component: CompanyEventsPageComponent},
      {path: 'manageUsers', component: UserManagementPageComponent},
      {path: 'manageCompany', component: CompanyManagementPageComponent},
      {path: 'manageRosters/:pageStatus', component: RostersPageComponent},
      {path: 'manageRosters/:pageStatus', component: RostersPageComponent},
      {path: 'tickets', component: TicketsPageComponent},
      {path: 'tickets/multiTickets', component: CreateMultipleTicketsPageComponent},
      {path: 'tickets/assignCrew/:ticketId', component: AssignCrewToTicketPageComponent},
      {path: 'rosterDetails/:rosterId', component: RosterDetailsPageComponent},
      {path: 'rosterDetails/:rosterId/:fromCE', component: RosterDetailsPageComponent},
      {path: 'reports', component: ReportsPageComponent},
      {path: 'training', component: TrainingPageComponent},
    ]
  },
  {path: '**', pathMatch: 'full', redirectTo: '/auth/login'},
  {path: '', pathMatch: 'full', redirectTo: '/auth/login'},
];

// canActivate: [AuthGuard],
@NgModule({
  declarations: [
    AppComponent,
    PublicWelcomePageComponent,
    LoginPageComponent,
    SignUpPageComponent,
    VerifyMfaPageComponent,
    ValidationErrorDisplayPage,
    HomePageComponent,
    UserProfilePageComponent,
    UserManagementPageComponent,
    ModifyUserPageComponent,
    UploadUserPageComponent,
    ForgotPasswordPageComponent,
    MasterEventsPageComponent,
    ModifyMasterEventPageComponent,
    CompanyEventsPageComponent,
    ModifyCompanyEventsPageComponent,
    InputFocusDirective,
    UploadRosterPageComponent,
    RostersPageComponent,
    RosterDetailsPageComponent,
    ModifyCrewDetailsPageComponent,
    ModifyRosterPageComponent,
    CompanyEventRespondingCompanyPageComponent,
    UnderConstructionComponent,
    SetFirstPasswordPageComponent,
    RosterCrewDetailsPageComponent,
    RosterEquipmentDetailsPageComponent,
    ModifyEquipmentDetailsPageComponent,
    CompanyEventQuickViewPageComponent,
    ModifyRosterDetailsPageComponent,
    AssignRosterToCompanyEventPageComponent,
    TicketsPageComponent,
    CreateTicketPageComponent,
    UpdateTicketPageComponent,
    AssignCrewToTicketPageComponent,
    TicketDocumentViewerPageComponent,
    CreateMultipleTicketsPageComponent,
    FieldResourceLoginPageComponent,
    FieldResourceTicketPageComponent,
    ReportsPageComponent,
    CompanyManagementPageComponent,
    ManageCompanyPageComponent,
    HelpPageComponent,
    TrainingPageComponent,
    PublicTrainingPageComponent,
  ],
  imports: [
    BrowserModule, ModalModule.forRoot(), NgxSpinnerModule, FormsModule, TabsModule.forRoot(),
    BrowserAnimationsModule, FileUploadModule, BsDatepickerModule.forRoot(),
    HttpClientModule, Ng2TableModule, PaginationModule.forRoot(), NgxDatatableModule,
    MatSidenavModule, Ng2IziToastModule, NgCircleProgressModule.forRoot(),
    TooltipModule.forRoot(), NgSelectModule,
    NgxMaskModule.forRoot(), NgxExtendedPdfViewerModule, PopoverModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'} // <-- debugging purposes only
    ),

  ], entryComponents: [
    LoginPageComponent, SignUpPageComponent, VerifyMfaPageComponent, ForgotPasswordPageComponent,
    ValidationErrorDisplayPage, UserManagementPageComponent, ModifyUserPageComponent, UploadUserPageComponent,
    MasterEventsPageComponent,
    ModifyMasterEventPageComponent, TicketDocumentViewerPageComponent,
    CompanyEventsPageComponent, UpdateTicketPageComponent, AssignCrewToTicketPageComponent,
    ModifyCompanyEventsPageComponent, AssignRosterToCompanyEventPageComponent,
    UploadRosterPageComponent, CreateTicketPageComponent,
    RostersPageComponent, ModifyRosterDetailsPageComponent,
    ModifyCrewDetailsPageComponent, UnderConstructionComponent, ModifyEquipmentDetailsPageComponent,
    RosterDetailsPageComponent, SetFirstPasswordPageComponent, CompanyEventRespondingCompanyPageComponent, CreateMultipleTicketsPageComponent, TicketsPageComponent,
    FieldResourceLoginPageComponent,
    FieldResourceTicketPageComponent, ReportsPageComponent,
    CompanyManagementPageComponent,
    ManageCompanyPageComponent, HelpPageComponent
  ],
  providers: [AuthService, LocalDataService, HandleRequestError, ForgotPasswordService, CompanyEventsService, RosterService, CommonUtil, EnvServiceProvider,
    AuthGuard, UserService, UserManagementService, CommonService, MasterEventService, TicketService, FieldResourceService, Location, CompanyManagementService],
  bootstrap: [AppComponent]
})
export class AppModule {
}

