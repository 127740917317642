import { ValidationError } from './../validators/validation-error';
import {Validators} from '../validators/validators';


export class CompanyDetailsModel {
  CompanyId: number;
  CompanyName: string;
  TypeId: number;
  CompanyAddress: string;
  CompanyCity: string;
  CompanyDomain: string;
  CompanyPhoneNumber: string;
  CompanyState: string;
  CompanyZipCode: number;
  CompanyEmailID: string;
  Status: boolean;
  TypeName: string;

  validate(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();

    validator.requiredString(error, 'CompanyName', this.CompanyName, 'Company Name');
    validator.requiredString(error, 'CompanyAddress', this.CompanyAddress, 'Company Address');
    validator.requiredString(error, 'CompanyCity', this.CompanyCity, 'Company City');
    validator.requiredString(error, 'CompanyState', this.CompanyState, 'Company State');
    validator.requiredString(error, 'CompanyDomain', this.CompanyDomain, 'Company Domain');
    validator.requiredString(error, 'TypeName', this.TypeName, 'Type Name');
    validator.requiredStringLength(error, 'CompanyName', this.CompanyName, 4, 'Company Name');
    validator.requiredStringLength(error, 'CompanyCity', this.CompanyCity, 3, 'Company City');
    validator.requiredStringLength(error, 'CompanyState', this.CompanyState, 2, 'Company State');
    validator.requiredStringLength(error, 'CompanyDomain', this.CompanyDomain, 4, 'Company Domain');
    validator.requiredStringLength(error, 'TypeName', this.TypeName, 3, 'Type Name');

    validator.validateEmail(error, 'CompanyEmailID', this.CompanyEmailID, 'CompanyEmailID');

    validator.validateMobileNumber(error, 'CompanyPhoneNumber', this.CompanyPhoneNumber, 'Company PhoneNumber');

    return error;
  }

  validateCompany(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();

    validator.requiredString(error, 'CompanyName', this.CompanyName, 'Company Name');
    validator.requiredString(error, 'TypeName', this.TypeName, 'Type Name');
    validator.requiredStringLength(error, 'CompanyName', this.CompanyName, 4, 'Company Name');
    validator.requiredStringLength(error, 'TypeName', this.TypeName, 3, 'Type Name');
    validator.validateMobileNumber(error, 'CompanyPhoneNumber', this.CompanyPhoneNumber, 'Company PhoneNumber')
    return error;
  }

  validateAddCompany(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();

    validator.requiredString(error, 'CompanyName', this.CompanyName, 'Company Name');
    validator.requiredString(error, 'CompanyAddress', this.CompanyAddress, 'Company Address');
    validator.requiredString(error, 'CompanyCity', this.CompanyCity, 'Company City');
    validator.requiredString(error, 'CompanyState', this.CompanyState, 'Company State');
    validator.requiredString(error, 'CompanyDomain', this.CompanyDomain, 'Company Domain');
    validator.requiredNumber(error, 'CompanyZipCode', this.CompanyZipCode, 'Company ZipCode');

    validator.requiredStringLength(error, 'CompanyName', this.CompanyName, 4, 'Company Name');

    validator.validateEmail(error, 'CompanyEmailID', this.CompanyEmailID, 'Company Email');
    validator.validateMobileNumber(error, 'CompanyPhoneNumber', this.CompanyPhoneNumber, 'Company PhoneNumber');

    validator.requiredNumber(error, 'TypeId', this.TypeId, 'TypeId');
    validator.requiredBoolean(error, 'Status', this.Status, 'Status');
    return error;
  }

  validateOnUpdateCompany(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();

    validator.requiredString(error, 'CompanyName', this.CompanyName, 'Company Name');
    validator.requiredString(error, 'CompanyAddress', this.CompanyAddress, 'Company Address');
    validator.requiredString(error, 'CompanyCity', this.CompanyCity, 'Company City');
    validator.requiredString(error, 'CompanyState', this.CompanyState, 'Company State');
    validator.requiredString(error, 'CompanyDomain', this.CompanyDomain, 'Company Domain');
    validator.requiredNumber(error, 'CompanyZipCode', this.CompanyZipCode, 'Company ZipCode');

    validator.requiredStringLength(error, 'CompanyName', this.CompanyName, 4, 'Company Name');

    validator.validateEmail(error, 'CompanyEmailID', this.CompanyEmailID, 'Company Email');
    validator.validateMobileNumber(error, 'CompanyPhoneNumber', this.CompanyPhoneNumber, 'Company PhoneNumber');

    validator.requiredNumber(error, 'TypeId', this.TypeId, 'TypeId');
    validator.requiredBoolean(error, 'Status', this.Status, 'Status');
    return error;
  }

  mapCompanyFields(company) {
    this.CompanyName = company['CompanyName'];
    this.CompanyAddress = company['CompanyAddress'];
    this.CompanyCity = company['CompanyCity'];
    this.CompanyDomain = company['CompanyDomain'];
    this.CompanyEmailID = company['CompanyEmailID'];
    this.CompanyState = company['CompanyState'];
    this.CompanyZipCode  = company['CompanyZipCode'];
    this.CompanyPhoneNumber = company['CompanyPhoneNumber'];
    this.TypeName = company['TypeName'];
  }
}
