import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';
import * as moment from 'moment';

export class CompanyEventModel {
  CompanyEventId: number;
  CompanyEventName: string;
  MasterEventId: number;
  RequestingCompanyId: number;
  MasterEventName: string;
  RequestingCompanyName: string;
  MasterEventStatus: string;
  StartDate: Date;
  EndDate: Date;
  selectedRespondingCompanies: number[];
  StartDateTxt: string;
  EndDateTxt: string;
  MasterEventType: string;
  CreatedBy: number;
  CreatedDate: string;
  UpdatedBy: number;
  UpdatedByName: string;
  CreatedByName: string;
  UpdatedDate: string;
  Status: number;
  StatusValue: string;
  AuditTrailId: number;
  CompanyEventInviteCount: number;
  CompanyEventInviteAcceptedCount: number;
  FormattedCompanyEventName: string;
  DF01: string;
  DF02: string;
  DF03: string;
  DF04: string;
  DF05: string;
  DF06: string;
  DF07: string;
  DF08: string;
  DF09: string;
  DF10: string;
  FormattedCompanyEventIDAndName: string;

  additionalUsers: RespondingCompanyUsers[];
  newDynamicFields: DynamicField[];
  existingDynamicFields: DynamicField[];
  deletedDynamicFields: DynamicField[];

  validateAddCompanyEvent(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'MasterEventId', this.MasterEventId, 'Master Event Name');
    validator.validateDate(error, 'StartDate', this.StartDate, 'Start Date');
    // validator.validateDate(error, 'EndDate', this.EndDate, 'End Date');
    console.log('Endate', this.EndDate);
    if (this.StartDate && this.StartDate.toDateString() === 'Invalid Date' && !error['StartDate']) {
      error['StartDate'] = [];
      error['StartDate'].push('Start Date is invalid, it is a mandatory field.');
    }

    if (this.EndDate && this.EndDate.toDateString() === 'Invalid Date' && !error['EndDate']) {
      error['EndDate'] = [];
      error['EndDate'].push('End Date is invalid, end date is not mandatory but current value is invalid.');
    } else if (this.EndDate) {
      validator.validateDate(error, 'EndDate', this.EndDate, 'End Date');
      if (this.StartDate && !error['EndDate']) {
        if (this.EndDate.getTime() < this.StartDate.getTime()) {
          error['EndDate'] = [];
          error['EndDate'].push('End Date should be greater than Start Date.');
        }
      }
    }
    if (!this.selectedRespondingCompanies || this.selectedRespondingCompanies.length <= 0) {
      error['selectedRespondingCompanies'] = ['Responding Company is a required field, please select atleast one.'];
    }

    return error;
  }

  validateUpdateCompanyEvent(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'MasterEventId', this.MasterEventId, 'Master Event Name');
    validator.validateDate(error, 'StartDate', this.StartDate, 'Start Date');
    console.log('Endate', this.EndDate);
    if (this.StartDate && this.StartDate.toDateString() === 'Invalid Date' && !error['StartDate']) {
      error['StartDate'] = [];
      error['StartDate'].push('Start Date is invalid, it is a mandatory field.');
    }
    if (this.EndDate && this.EndDate.toDateString() === 'Invalid Date') {
      error['EndDate'] = [];
      error['EndDate'].push('End Date is invalid, end date is not mandatory but current value is invalid.');
    } else if (this.EndDate) {
      validator.validateDate(error, 'EndDate', this.EndDate, 'End Date');
      if (this.StartDate && !error['EndDate']) {
        if (this.EndDate.getTime() < this.StartDate.getTime()) {
          error['EndDate'] = [];
          error['EndDate'].push('End Date should be greater than Start Date.');
        }
      }
    }
    // validator.validateDate(error, 'EndDate', this.EndDate, 'End Date');
    validator.requiredNumber(error, 'Status', this.Status, 'Event Status');
    if (!this.selectedRespondingCompanies || this.selectedRespondingCompanies.length <= 0) {
      error['selectedRespondingCompanies'] = ['Responding Company is a required field, please select atleast one.'];
    }
    return error;
  }


  mapCompanyEvent(companyEvent) {
    this.CompanyEventId = companyEvent.CompanyEventId;
    this.CompanyEventName = companyEvent.CompanyEventName;
    this.MasterEventId = companyEvent.MasterEventId;
    this.RequestingCompanyId = companyEvent.RequestingCompanyId;
    /*this.StartDate = moment.utc(companyEvent.StartDate).toDate();
    if (companyEvent.EndDate) {
      this.EndDate = moment.utc(companyEvent.EndDate).toDate();
    }*/

    this.StartDate = new Date(companyEvent['StartDateTxt']);
    if (companyEvent['EndDateTxt']) {
      this.EndDate = new Date(companyEvent['EndDateTxt']);
    }

    this.MasterEventName = companyEvent['MasterEventName'];
    this.MasterEventStatus = companyEvent['MasterEventStatus'];
    this.StartDateTxt = companyEvent['StartDateTxt'];
    this.EndDateTxt = companyEvent['EndDateTxt'];
    this.MasterEventType = companyEvent['MasterEventType'];
    this.CreatedBy = companyEvent['CreatedBy'];
    this.CreatedDate = companyEvent['CreatedDate'];
    this.UpdatedBy = companyEvent['UpdatedBy'];
    this.UpdatedByName = companyEvent['UpdatedByName'];
    this.CreatedByName = companyEvent['CreatedByName'];
    this.UpdatedDate = companyEvent['UpdatedDate'];
    this.StatusValue = companyEvent['StatusValue'];
    this.CompanyEventInviteCount = companyEvent['CompanyEventInviteCount'];
    this.CompanyEventInviteAcceptedCount = companyEvent['CompanyEventInviteAcceptedCount'];
    this.Status = companyEvent['Status'];

    this.additionalUsers = companyEvent['additionalUsers'];
    if (!this.additionalUsers || this.additionalUsers.length === 0) {
      this.additionalUsers = [];
      this.additionalUsers[0] = new RespondingCompanyUsers();
    }
    this.existingDynamicFields = companyEvent['existingDynamicFields'];
    /*if(!this.dynamicFields || this.dynamicFields.length ===0){
      this.dynamicFields = [];
      this.dynamicFields[0] = new DynamicField();
    }*/
    this.selectedRespondingCompanies = companyEvent['selectedRespondingCompanies'];

    this.DF01 = companyEvent['DF01'];
    this.DF02 = companyEvent['DF02'];
    this.DF03 = companyEvent['DF03'];
    this.DF04 = companyEvent['DF04'];
    this.DF05 = companyEvent['DF05'];
    this.DF06 = companyEvent['DF06'];
    this.DF07 = companyEvent['DF07'];
    this.DF08 = companyEvent['DF08'];
    this.DF09 = companyEvent['DF09'];
    this.DF10 = companyEvent['DF10'];
  }
}

export class DynamicField {
  RosterDynamicFieldValueId: number;
  RosterId: number;
  TicketId: number;
  DynamicFieldId: number;
  CompanyEventId: number;
  DynamicFieldName: string;
  DynamicFieldValue: string;
  DynamicFieldValues: string[];
}

export class DynamicFieldMapper {
  dynamicFieldId: number;
  DynamicFieldName: string;
  dynamicFieldValues: string[];
}

export class RespondingCompanyUsers {
  FirstName: string;
  LastName: string;
  ContactName: string;
  PhoneNumber: string;
  eMail_ID: string;
  CompanyName: string;
  EmailAlerts: boolean;
  SMSAlerts: boolean;

  mapRespondingCompanyUsers(user) {
    this.FirstName = user['FirstName'];
    this.LastName = user['LastName'];
    this.ContactName = user['ContactName'];
    this.PhoneNumber = user['PhoneNumber'];
    this.eMail_ID = user['eMail_ID'];
    this.CompanyName = user['CompanyName'];
  }

}


export class CompanyEventBaseDetails {
  CompanyEventId: number;
  EndDate: string;
  EventName: string;
  CompanyEventName: string;
  RequestingCompanyId: number;
  RequestingCompanyName: string;
  RespondingCompanyId: number;
  RespondingCompanyName: string;
  StartDate: string;
  rosters: CompanyEventRosterModel[];
}

export class CompanyEventRosterModel {
  CreatedDate: string;
  CrewCount: number;
  CompanyEventId: number;
  CompanyEventName: string;
  RespondedCompanyUserName: string;
  RespondingCompanyName: string;
  RespondingCompanyID: number;
  RosterId: number;
  RosterName: string;
  Status: number;
  StatusValue: string;
  UpdatedDate: string;
}

export class CompanyEventAndRosterDetails {
  companyEventBaseDetails: CompanyEventBaseDetails[];
  rosters: CompanyEventRosterModel[];
}
