import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalDataService} from './local-data-service';
import {LoginRequestModel} from '../model/login-request-model';
import {LoginResponseModel} from '../model/login-response-model';
import {environment} from '../../environments/environment';
import {EnvironmentService} from './environment-service';

@Injectable()
export class ForgotPasswordService {
  token: string;


  constructor(private http: HttpClient, private localDataService: LocalDataService, private environment: EnvironmentService) {
  }

  prepareHeaders(){
    let token = this.token;
    let headers = new HttpHeaders();
    headers = headers.set('authorization', 'bearer '+token);
    return headers;
  }
  prepareOptions(){
    return {withCredentials: true, headers:this.prepareHeaders()};
  }

  fpSendVerificationCode(eMail_ID: string, isForgotPwdFlow: string): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'forgotPassword/fpSendVerificationCode',{eMail_ID:eMail_ID, isForgotPwdFlow:isForgotPwdFlow});
  }

  verifyFPCode(fpVerificationCode: number): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'forgotPassword/verifyFPCode',{fpVerificationCode: fpVerificationCode}, this.prepareOptions());
  }

  fpChangePassword(password: string, isForgotPwdFlow: string): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'forgotPassword/fpChangePassword',{password:password, isForgotPwdFlow:isForgotPwdFlow}, this.prepareOptions());
  }

  fpResendVerificationCode(isForgotPwdFlow: string): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'forgotPassword/fpResendVerificationCode',{isForgotPwdFlow:isForgotPwdFlow}, this.prepareOptions());
  }
}
