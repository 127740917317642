import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FieldResourceAuthModel} from '../model/field-resource-auth-model';

@Injectable()
export class LocalDataService {

  constructor() {

  }

  USER_TOKEN_KEY = 'USER_TOKEN';
  FR_USER_TOKEN_KEY = 'FR_USER_TOKEN';

  storeUserToken(token: string) : Observable<any>{
    return new Observable((observer) => {
      sessionStorage.setItem(this.USER_TOKEN_KEY, token);
      observer.next(token);
      observer.complete();
    });

  }

  readUserToken() : Observable<any>{
    return new Observable((observer) => {
      observer.next(sessionStorage.getItem(this.USER_TOKEN_KEY));
      observer.complete();
    });
  }


  clearUserToken() : Observable<any>{
    return new Observable((observer) => {
      sessionStorage.removeItem(this.USER_TOKEN_KEY);

      observer.next(true);
      observer.complete();
    });
  }

  storeFRUserToken(frDetails: FieldResourceAuthModel) {
    sessionStorage.setItem(this.FR_USER_TOKEN_KEY, JSON.stringify(frDetails));
  }

  readFRUserToken() : FieldResourceAuthModel{
    return JSON.parse(sessionStorage.getItem(this.FR_USER_TOKEN_KEY));
  }


  clearFRUserToken(){
    sessionStorage.removeItem(this.FR_USER_TOKEN_KEY);

  }

}
