import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {RosterModel} from '../../model/roster-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {UploadRosterPageComponent} from '../upload-roster-page/upload-roster-page.component';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {environment} from '../../../environments/environment';
import {ModifyRosterDetailsPageComponent} from '../modify-roster-details-page/modify-roster-details-page.component';
import {AssignRosterToCompanyEventPageComponent} from '../assign-roster-to-company-event-page/assign-roster-to-company-event-page.component';
import {RosterCrewDetailsModel} from '../../model/roster-crew-details-model';
import {RosterEquipmentDetailsModel} from '../../model/roster-equipment-details-model';
import {CompanyEventModel, DynamicField} from '../../model/company-event-model';
import {ReportDataModel, ReportTicketDataModel} from '../../model/report-data-model';
import {TicketCrewModel, TicketModel} from '../../model/ticket-model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.css']
})
export class ReportsPageComponent implements OnInit {

  adminCompanyId: number;
  adminCompanyName: string;
  pageStatus: string;
  userRole: number;
  selectedCompanyEventIds: number[] = [];

  rosters: RosterModel[];
  rostersRows: RosterModel[];

  releasedRosters: RosterModel[];

  closedRosters: RosterModel[];

  returnToHomeRosters: RosterModel[];

  nonReleasedRosters: RosterModel[];

  selected: RosterModel[] = [];

  bsModalRef: BsModalRef;

  companyEvents: CompanyEventModel[] = [];

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService, private companyEventService: CompanyEventsService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  loadData() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.disableGetReportsBtn = true;

    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllnonReleasedRosters());
    baseDataCalls.push(this.fetchAllReleasedRosters());
    baseDataCalls.push(this.fetchAllClosedRosters());
    baseDataCalls.push(this.fetchAllReturnedToHomeRosters());
    baseDataCalls.push(this.fetchAllCompanyEvents());
    Promise.all(baseDataCalls).then((r) => {
      this.rosters = [];
      this.rostersRows = [];
      this.rosters.push(...this.releasedRosters);
      this.rosters.push(...this.nonReleasedRosters);
      this.rosters.push(...this.closedRosters);
      this.rosters.push(...this.returnToHomeRosters);
      this.rostersRows.push(...this.rosters);
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });


  }


  ngOnInit() {
    this.loadData();
  }

  fetchAllCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchAllCompanyEvents().subscribe((res) => {
        console.log(res);
        if(res){
          this.companyEvents = res;
          this.prepareCompanyEvent();
        }else{
          this.companyEvents = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  prepareCompanyEvent() {
    if (!this.companyEvents || this.companyEvents.length <= 0) {
      return;
    }
    for (let ce of this.companyEvents) {
      ce.FormattedCompanyEventName = ce.MasterEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RequestingCompanyName;
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }

  fetchAllnonReleasedRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllNonReleasedRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.nonReleasedRosters = res;
          this.prepareRosters(this.nonReleasedRosters);
        } else {
          this.nonReleasedRosters = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  fetchAllReleasedRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllReleasedRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.releasedRosters = res;
          this.prepareRosters(this.releasedRosters);

        } else {
          this.releasedRosters = [];

        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchAllReturnedToHomeRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllReturnedToHomeRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.returnToHomeRosters = res;
          this.prepareRosters(this.returnToHomeRosters);

        } else {
          this.returnToHomeRosters = [];

        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchAllClosedRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllClosedRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.closedRosters = res;
          this.prepareRosters(this.closedRosters);

        } else {
          this.closedRosters = [];

        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  getCompanyEventClass({row, column, value, adminCompanyName}): any {
    return {
      'our-company-event': row['RequestingCompanyName'] === row['adminCompanyName'],
      'other-company-event': row['RequestingCompanyName'] !== row['adminCompanyName']
    };
  }

  prepareRosters(rosters) {
    if (!rosters || rosters.length <= 0) {
      return;
    }
    for (let ce of rosters) {
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }

  disableGetReportsBtn = true;

  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.rosters.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.rostersRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableGetReportsBtn = true;
  }


  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(this.commonUtil.prepareRosterName(d.RosterId), val) || this.matchContent(d.RosterName, val) ||
      this.matchContent(d.EventName, val) || this.matchContent(this.commonUtil.prepareCompanyEventName(d.CompanyEventId), val) ||
      this.matchContent(d.RequestingCompanyName, val) ||
      this.matchContent(d.StartDate, val) || this.matchContent(d.EndDate, val) ||
      this.matchContent(d.RespondingCompanyName, val) || this.matchContent(d.RespondedCompanyUserName, val) ||
      this.matchContent(d.StatusValue, val) ||  this.matchContent(d.CompanyEventName, val) ||this.matchContent(d.WorkLocation, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }



  getRowClass(row): any {
    return {
      'disabled-row': false
    };
    //row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
  }

  getClosedCECheckboxClass({row, column, value}): any {
    return {
      'hide-checkbox': false
    };
    //row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.rosters) {
      if (this.selected.length === this.rosters.length) {
        this.iziToast.warning({
          title: 'All Rosters selected',
          message: 'You have selected all the Rosters. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }

    this.disableGetReportsBtn = true;

    if (this.selected.length >=1) {

      this.disableGetReportsBtn = false;

    }


  }
  getReportByCompanyEvent(){
    if(!this.selectedCompanyEventIds || this.selectedCompanyEventIds.length === 0){
      this.iziToast.warning({
        title: 'No Company Events selected',
        message: 'Please select a Company Events to prepare report.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      return;
    }
    let companyEventIds = '';
    for(let ce of this.selectedCompanyEventIds){
      if(companyEventIds === ''){
        companyEventIds = companyEventIds + ce;
      }else{
        companyEventIds = companyEventIds + ','+ce;
      }
    }
    this.spinner.show();
    this.rosterService.fetchRosterDetailsForReport(companyEventIds, null).subscribe((res)=>{
      this.spinner.hide();
      console.log(res);
      this.reportDataModel = res;

      this.prepareTicketDynamicFieldMapping();
      this.generateRosterExcelReport(this.reportDataModel);
      //Excel generator
    },(err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }
  reportDataModel: ReportDataModel;
  getReport(){
    if(!this.selected || this.selected.length === 0){
      this.iziToast.warning({
        title: 'No Rosters selected',
        message: 'Please select a Roster to prepare report.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      return;
    }
    let rosterIds = '';
    for(let rs of this.selected){
      if(rosterIds === ''){
        rosterIds = rosterIds + rs.RosterId;
      }else{
        rosterIds = rosterIds + ','+rs.RosterId;
      }
    }
    this.spinner.show();
    this.rosterService.fetchRosterDetailsForReport(null, rosterIds).subscribe((res)=>{
      this.spinner.hide();
      console.log(res);
      this.reportDataModel = res;
      this.prepareTicketDynamicFieldMapping();
      this.generateRosterExcelReport(this.reportDataModel);
      //Excel generator
    },(err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  ticketDynamicFieldMapping: { [key: string]: DynamicField[] } = {};
  prepareTicketDynamicFieldMapping(){
    this.ticketDynamicFieldMapping = {};
    let maxColumns = 0;
    if (this.reportDataModel.ticketData && this.reportDataModel.ticketData.ticketDynamicFields &&   this.reportDataModel.ticketData.ticketDynamicFields.length > 0) {
      for (let dynamicField of this.reportDataModel.ticketData.ticketDynamicFields) {
        if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId]) {
          this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
          if (this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length > maxColumns) {
            maxColumns = this.ticketDynamicFieldMapping['' + dynamicField.TicketId].length;
          }
        } else {
          this.ticketDynamicFieldMapping['' + dynamicField.TicketId] = [];
          this.ticketDynamicFieldMapping['' + dynamicField.TicketId].push(dynamicField);
        }
      }

      if(this.reportDataModel.ticketData && this.reportDataModel.ticketData.tickets){
        for(let ticket of this.reportDataModel.ticketData.tickets){
          ticket.dynamicFields = this.ticketDynamicFieldMapping['' + ticket.TicketId];
        }
      }
    }
  }

  generateRosterExcelReport(reportData: ReportDataModel){

    if(!reportData){
      this.iziToast.warning({
        title: 'No Data available to export.',
        message: 'No Data.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      return;
    }
    /* generate worksheet */
    const crewSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareCrewExcelSheetRows(reportData.rosterCrews));
    const equipmentSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareEquipmentExcelSheetRows(reportData.rosterEquipments));
    if(!reportData.ticketData){
      reportData.ticketData = new ReportTicketDataModel();
    }
    const ticketSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareTicketsExcelSheetRows(reportData.ticketData.tickets));
    const ticketCrewSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.prepareTicketsCrewsExcelSheetRows(reportData.ticketData.ticketCrews));

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, crewSheet, 'CREW');
    XLSX.utils.book_append_sheet(wb, equipmentSheet, 'EQUIPMENT');
    XLSX.utils.book_append_sheet(wb, ticketSheet, 'TICKET');
    XLSX.utils.book_append_sheet(wb, ticketCrewSheet, 'TICKET CREW');

    /* save to file */
    //XLSX.writeFile(wb, 'Rosters-Report-'+moment(new Date()).format('MMM-DD-YYYY-hh-mm-A')+'.xlsx');
    var wbout = XLSX.write(wb, {type:"array", bookType:'xlsx'});
    saveAs(new Blob([wbout], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), 'Rosters-Report-'+moment(new Date()).format('MMM-DD-YYYY-hh-mm-A')+'.xlsx');


  }

  prepareCrewExcelSheetRows(crewDetails : RosterCrewDetailsModel[]){
    let excelData = [];
    let col = 0;
    excelData[0] =[];
    excelData[0][col] = 'Roster ID';
    excelData[0][++col] = 'Roster Name';
    excelData[0][++col] = 'Work Location';
    excelData[0][++col] = 'Company Event ID';
    excelData[0][++col] = 'Company Event Name';
    excelData[0][++col] = 'Master Event Name';
    excelData[0][++col] = 'Crew ID';
    excelData[0][++col] = 'Personnel ID';
    excelData[0][++col] = 'Last Name';
    excelData[0][++col] = 'First Name';
    excelData[0][++col] = 'Email';
    excelData[0][++col] = 'Cell Phone';
    excelData[0][++col] = 'Gender';
    excelData[0][++col] = 'Team Lead/General Foreman(Y/N)';
    excelData[0][++col] = 'Crew Lead/Foreman(Y/N)';
    excelData[0][++col] = 'Team Type';
    excelData[0][++col] = 'Resource Type';
    excelData[0][++col] = 'Departure City';
    excelData[0][++col] = 'Departure State';
    excelData[0][++col] = 'Roster Uploaded Date';
    excelData[0][++col] = 'Roster Last Updated Date';
    if(!crewDetails || crewDetails.length === 0){
      return excelData;
    }
    let i = 1;
    for(let crew of crewDetails) {
      let j = 0;
      excelData[i] = [];
      excelData[i][j] = this.prepareRosterName(crew.RosterId);
      excelData[i][++j] = crew['RosterName'];
      excelData[i][++j] = crew['WorkLocation'];
      excelData[i][++j] = this.prepareCompanyEventName(crew['CompanyEventId']);
      excelData[i][++j] = crew['CompanyEventName'];
      excelData[i][++j] = crew['MasterEventName'];
      excelData[i][++j] = crew.CrewId;
      excelData[i][++j] = crew.PersonnelId;
      excelData[i][++j] = crew.LastName;
      excelData[i][++j] = crew.FirstName;
      excelData[i][++j] = crew.Email;
      excelData[i][++j] = crew.CellPhone;
      excelData[i][++j] = crew.Gender;
      excelData[i][++j] = crew.TeamLead === true ? 'Yes': 'No';
      excelData[i][++j] = crew.CrewLead === true ? 'Yes': 'No';
      excelData[i][++j] = crew.TeamType;
      excelData[i][++j] = crew.ResourceType;
      excelData[i][++j] = crew.DepartureCity;
      excelData[i][++j] = crew.DepartureState;
      excelData[i][++j] = crew['RosterImportDate'];
      excelData[i][++j] = crew['RosterLastUpdateDate'];
      i++;
    }
    return excelData;
  }


  prepareEquipmentExcelSheetRows(equipmentDetails : RosterEquipmentDetailsModel[]){
    let excelData = [];
    let col = 0;
    excelData[0] =[];
    excelData[0][col] = 'Roster ID';
    excelData[0][++col] = 'Roster Name';
    excelData[0][++col] = 'Work Location';
    excelData[0][++col] = 'Company Event ID';
    excelData[0][++col] = 'Company Event Name';
    excelData[0][++col] = 'Master Event Name';
    excelData[0][++col] = 'Equipment ID';
    excelData[0][++col] = 'Equipment Type';
    excelData[0][++col] = 'Equipment Description';
    excelData[0][++col] = 'Equipment Fuel Type';
    excelData[0][++col] = 'Assigned Equipment Crew ID';
    excelData[0][++col] = 'Roster Uploaded Date';
    excelData[0][++col] = 'Roster Last Updated Date';
    if(!equipmentDetails || equipmentDetails.length === 0){
      return excelData;
    }
    let i = 1;
    for(let equip of equipmentDetails) {
      let j = 0;
      excelData[i] = [];
      excelData[i][j] = this.prepareRosterName(equip.RosterId);
      excelData[i][++j] = equip['RosterName'];
      excelData[i][++j] = equip['WorkLocation'];
      excelData[i][++j] = this.prepareCompanyEventName(equip['CompanyEventId']);
      excelData[i][++j] = equip['CompanyEventName'];
      excelData[i][++j] = equip['MasterEventName'];
      excelData[i][++j] = equip.EquipmentId;
      excelData[i][++j] = equip.EquipmentType;
      excelData[i][++j] = equip.EquipmentDesc;
      excelData[i][++j] = equip.EquipmentFuelType;
      excelData[i][++j] = equip.AssignedEquipmentCrewID;
      excelData[i][++j] = equip['RosterImportDate'];
      excelData[i][++j] = equip['RosterLastUpdateDate'];
      i++;
    }
    return excelData;
  }

  prepareTicketsExcelSheetRows(tickets : TicketModel[]){
    let excelData = [];
    let col = 0;
    excelData[0] =[];
    excelData[0][col] = 'Ticket ID';
    excelData[0][++col] = 'Status';
    excelData[0][++col] = 'Work Location';
    excelData[0][++col] = 'Comment';
    excelData[0][++col] = 'WAC Name';
    excelData[0][++col] = 'Responding Company Name';
    excelData[0][++col] = 'Requesting Company Name';
    excelData[0][++col] = 'Company Event Id';
    excelData[0][++col] = 'Company Event Name';
    excelData[0][++col] = 'Dynamic Field 1 - Name';
    excelData[0][++col] = 'Dynamic Field 1 - Value';
    excelData[0][++col] = 'Dynamic Field 2 - Name';
    excelData[0][++col] = 'Dynamic Field 2 - Value';
    excelData[0][++col] = 'Dynamic Field 3 - Name';
    excelData[0][++col] = 'Dynamic Field 3 - Value';
    excelData[0][++col] = 'Deleted';
    excelData[0][++col] = 'Delete Reason';
    excelData[0][++col] = 'Created Date';
    excelData[0][++col] = 'Updated Date';
    if(!tickets || tickets.length === 0){
      return excelData;
    }
    let i = 1;
    for(let ticket of tickets) {
      let j = 0;
      excelData[i] = [];
      excelData[i][j] = this.prepareTicketName(ticket.TicketId);
      excelData[i][++j] = ticket.StatusValue;
      excelData[i][++j] = ticket.WorkLocation;
      excelData[i][++j] = ticket.Comment;
      excelData[i][++j] = ticket.WACName;
      excelData[i][++j] = ticket.RespondingCompanyName;
      excelData[i][++j] = ticket.RequestingCompanyName;
      excelData[i][++j] = this.prepareCompanyEventName(ticket.CompanyEventId);
      excelData[i][++j] = ticket.CompanyEventName;
      if(ticket.dynamicFields){
        if(ticket.dynamicFields.length > 0 && ticket.dynamicFields[0]){
          excelData[i][++j] = ticket.dynamicFields[0].DynamicFieldName;
          excelData[i][++j] = ticket.dynamicFields[0].DynamicFieldValue;
        }else{
          excelData[i][++j] = '';
          excelData[i][++j] = '';
        }
        if(ticket.dynamicFields.length > 1 && ticket.dynamicFields[1]){
          excelData[i][++j] = ticket.dynamicFields[1].DynamicFieldName;
          excelData[i][++j] = ticket.dynamicFields[1].DynamicFieldValue;
        }else{
          excelData[i][++j] = '';
          excelData[i][++j] = '';
        }
        if(ticket.dynamicFields.length > 2 && ticket.dynamicFields[2]){
          excelData[i][++j] = ticket.dynamicFields[2].DynamicFieldName;
          excelData[i][++j] = ticket.dynamicFields[2].DynamicFieldValue;
        }else{
          excelData[i][++j] = '';
          excelData[i][++j] = '';
        }
      }else{
        excelData[i][++j] = '';
        excelData[i][++j] = '';
        excelData[i][++j] = '';
        excelData[i][++j] = '';
        excelData[i][++j] = '';
        excelData[i][++j] = '';
      }

      excelData[i][++j] = ticket.Deleted? 'Yes': 'No';
      excelData[i][++j] = ticket.DeleteReason;
      excelData[i][++j] = ticket.CreatedDateTxt;
      excelData[i][++j] = ticket.UpdatedDateTxt;
      i++;
    }
    return excelData;
  }

  prepareTicketsCrewsExcelSheetRows(ticketCrews : TicketCrewModel[]){
    let excelData = [];
    let col = 0;
    excelData[0] =[];
    excelData[0][col] = 'Ticket ID';
    excelData[0][++col] = 'Roster Id';
    excelData[0][++col] = 'Roster Name';
    excelData[0][++col] = 'Work Location';
    excelData[0][++col] = 'Company Event Id';
    excelData[0][++col] = 'Company Event Name';
    excelData[0][++col] = 'Crew Name';
    excelData[0][++col] = 'Primary Crew';
    excelData[0][++col] = 'Crew Lead';
    excelData[0][++col] = 'Team Lead';
    excelData[0][++col] = 'Crew Id';
    excelData[0][++col] = 'Personnel Id';
    excelData[0][++col] = 'Team Type';
    excelData[0][++col] = 'Resource Type';
    excelData[0][++col] = 'Cell Phone';
    excelData[0][++col] = 'Email';
    excelData[0][++col] = 'Gender';
    excelData[0][++col] = 'Departure City';
    excelData[0][++col] = 'Departure State';
    excelData[0][++col] = 'Current Status';
    excelData[0][++col] = 'Crew Assigned Ticket Count';
    if(!ticketCrews || ticketCrews.length === 0){
      return excelData;
    }
    let i = 1;
    for(let ticketCrew of ticketCrews) {
      let j = 0;
      excelData[i] = [];
      excelData[i][j] = this.prepareTicketName(ticketCrew.TicketId);
      excelData[i][++j] = this.prepareRosterName(ticketCrew.RosterId)
      excelData[i][++j] = ticketCrew.RosterName;
      excelData[i][++j] = ticketCrew.WorkLocation;
      excelData[i][++j] = this.prepareCompanyEventName(ticketCrew.CompanyEventId);
      excelData[i][++j] = ticketCrew.CompanyEventName;
      excelData[i][++j] = ticketCrew.CrewName;
      excelData[i][++j] = ticketCrew.PrimaryCrew ? 'Yes': 'No';
      excelData[i][++j] = ticketCrew.CrewLead ? 'Yes': 'No';
      excelData[i][++j] = ticketCrew.TeamLead ? 'Yes': 'No';
      excelData[i][++j] = ticketCrew.CrewId;
      excelData[i][++j] = ticketCrew.PersonnelId;
      excelData[i][++j] = ticketCrew.TeamType;
      excelData[i][++j] = ticketCrew.ResourceType;
      excelData[i][++j] = ticketCrew.CellPhone;
      excelData[i][++j] = ticketCrew.Email;
      excelData[i][++j] = ticketCrew.Gender;
      excelData[i][++j] = ticketCrew.DepartureCity;
      excelData[i][++j] = ticketCrew.DepartureState;
      excelData[i][++j] = ticketCrew.Status ? 'Active' : 'In-Active';
      excelData[i][++j] = ticketCrew.CrewAssignedTicketCount;
      i++;
    }
    return excelData;
  }


  prepareTicketName(ticketId){
    return this.commonUtil.prepareTicketName(ticketId);
  }

  getUserStatusClass({row, column, value}): any {
    return {
      'user-active': value === 'Approved',
      'user-in-active': value === 'Initiated'
    };
  }


  prepareCompanyEventName(companyEventId: number) {
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }

  prepareRosterName(rosterId: number) {
    return this.commonUtil.prepareRosterName(rosterId);
  }

}
