import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {UserManagementService} from '../../providers/user-mgmt-service';
import {CommonService} from '../../providers/common-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {ValidationError} from '../../validators/validation-error';
import {environment} from '../../../environments/environment';
import {FileUploader} from 'ng2-file-upload';
import {Validators} from '../../validators/validators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {LocalDataService} from '../../providers/local-data-service';
import {EnvironmentService} from '../../providers/environment-service';

@Component({
  selector: 'app-upload-user-page',
  templateUrl: './upload-user-page.component.html',
  styleUrls: ['./upload-user-page.component.css']
})
export class UploadUserPageComponent implements OnInit {

  CompanyId: number;
  activeCompanies: CompanyDetailsModel[];
  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  templateURL : string;
  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService,
              private authService: AuthService, private modalService: BsModalService, private userMgmtServicce: UserManagementService,
              private commonService: CommonService, private handleReqErr: HandleRequestError, private environment: EnvironmentService) { }



  ngOnInit() {

    this.templateURL = this.environment.serviceHostUrl + 'MARC-UploadUserTemplate.xlsx';
    this.spinner.show();
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    if(this.userRole === 2){
      this.CompanyId = this.adminCompanyId;
    }
    let baseDataCalls = [];
    baseDataCalls.push(this.getAllActiveCompanies());

    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.iziToast.error({
        title: 'Error occurred',
        message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
        position: 'center',
        timeout: 3000,
        close: true,
        overlay: true
      });
    });
  }

  public hasAnotherDropZoneOver: boolean = false;
  @ViewChild('uploadFileSelect')
  uploadFileSelect: ElementRef;

  public uploader: FileUploader = new FileUploader({url: "",allowedFileType:['xlsx','xls','csv']});
  selectedFile: File;

  public fileOverAnother(e: any): void {
   // this.hasAnotherDropZoneOver = e;
    console.log('File updated');
    if (this.uploader.queue.length === 1) {
            this.selectedFile = this.uploader.queue[0]._file;
    }else if(this.uploader.queue.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }else{
      this.iziToast.error({
        title: 'Invalid file type',
        message: 'Please drop only Excel or CSV file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }
    this.validateFields();
  }

  fileSelected(e){
   // this.hasAnotherDropZoneOver = e;
    console.log(this.uploadFileSelect.nativeElement);
    if(this.uploadFileSelect.nativeElement.files.length === 1){
      this.selectedFile = this.uploadFileSelect.nativeElement.files[0];
    }else if(this.uploadFileSelect.nativeElement.files.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }else{
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }
    this.validateFields();
    //this.selectedFile = this.uploadFileSelect.files;
    //this.fileName = this.selectedFile.name;
  }

  getAllActiveCompanies() {
    return new Promise(((resolve, reject) => {
      this.commonService.getAllActiveCompanies().subscribe((res) => {
        console.log(res);
        this.activeCompanies = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  close(){
    this.bsModalRef.hide();
  }

  submitAttempted= false;
  validateFields(){
    if(!this.submitAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'CompanyId', this.CompanyId, 'Company');
    if(this.selectedFile){
      validator.requiredString(this.validationError, 'selectedFile', this.selectedFile.name, 'This');
    }else{
      validator.requiredString(this.validationError, 'selectedFile', null, 'This');
    }
    if (validator && Object.keys(validator).length > 0) {
      return;
    }
  }

  uploadedPercentage: any;
  uploadProgress: number;
  showUploadProgress: boolean = false;

  duplicateEmails : string[] = [];
  duplicatePhoneNumbers : string[] = [];
  isFieldError = false;
  uploadFile(){
    this.isFieldError = false;
    this.submitAttempted = true;
    this.errors = [];
    this.duplicateEmails = [];
    this.duplicatePhoneNumbers = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'CompanyId', this.CompanyId, 'Company');
    if(this.selectedFile){
      validator.requiredString(this.validationError, 'selectedFile', this.selectedFile.name, 'This');
    }else{
      validator.requiredString(this.validationError, 'selectedFile', null, 'This');
    }
    if (this.validationError && (this.validationError['CompanyId'] || this.validationError['selectedFile'])) {
      return;
    }
    window.scroll(0, 0);
    this.userMgmtServicce.uploadUsers(this.selectedFile, this.CompanyId).subscribe((event: HttpEvent<any>) => {
      this.uploadFileSelect.nativeElement.value = '';
      this.selectedFile = null;
      this.uploader.clearQueue();
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.Response:
          this.showUploadProgress = false;
          this.submitAttempted = false;
          this.uploadedPercentage = 0;
          this.uploadProgress = 0;
          this.iziToast.success({title: 'Users added', message: 'Users added successfully!.', position: 'center'});
          this.bsModalRef.hide();
          break;
        case 1: {
          this.showUploadProgress = true;
          if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
            this.uploadedPercentage = event['loaded'] / event['total'] * 100;
            this.uploadProgress = Math.round(this.uploadedPercentage);
          }
          break;
        }

      }
    }, (err) => {
      this.showUploadProgress = false;
      this.uploadedPercentage = 0;
      this.uploadProgress = 0;
      console.log(err);
      if(err.status === 422){
        if(err.error.error){
          this.isFieldError = true;
          this.errors.push(...err.error.error);
        }else{
          this.errors.push(...err.error.message);
        }

        if(err.error.duplicateEmails){
          this.duplicateEmails.push(...err.error.duplicateEmails);
        }
        if(err.error.duplicatePhoneNumbers){
          this.duplicatePhoneNumbers.push(...err.error.duplicatePhoneNumbers);
        }
        if(this.duplicateEmails.length > 0 || this.duplicatePhoneNumbers.length > 0){
          this.errors = [];
        }
      }else if(err.status === 400){
        this.validationError = err.error;
        if(err.error.duplicateEmails){
          this.duplicateEmails.push(...err.error.duplicateEmails);
        }
        if(err.error.duplicatePhoneNumbers){
          this.duplicatePhoneNumbers.push(...err.error.duplicatePhoneNumbers);
        }
      }else{
        this.handleReqErr.handlePageLoadError(err);
      }

    });
  }
}
