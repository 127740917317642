import {Component, OnInit} from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TicketService} from '../../providers/ticket-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {TicketCrewModel, TicketDocument, TicketModel, TicketWorkLocationModel} from '../../model/ticket-model';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {RosterModel, RosterWorkLocationModel} from '../../model/roster-model';
import {RosterCrewDetailsModel} from '../../model/roster-crew-details-model';
import {Validators} from '../../validators/validators';
import {Location} from '@angular/common';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DynamicField, DynamicFieldMapper} from '../../model/company-event-model';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-assign-crew-to-ticket-page',
  templateUrl: './assign-crew-to-ticket-page.component.html',
  styleUrls: ['./assign-crew-to-ticket-page.component.css']
})
export class AssignCrewToTicketPageComponent implements OnInit {
  adminCompanyId: number;
  adminCompanyName: string;
  primaryCrewSelectedValue: string;
  userRole: number;
  ticketId: number;
  selectedRosterId: number;
  selectedRosterId2: number;
  ticket: TicketModel;

  rosters: RosterModel[];
  rosterCrews: RosterCrewDetailsModel[];
  ticketCrew: TicketCrewModel[];
  ticketCrewRows: TicketCrewModel[];
  selectedCrews: TicketCrewModel[];
  ticketDocuments: TicketDocument[];
  showNonPDFInfo: boolean = false;

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;

  rosterWorkLocations: RosterWorkLocationModel[];

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private companyEventService: CompanyEventsService, private ticketService: TicketService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil, private location: Location
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.ticketId = params['ticketId'];
      this.loadData();
    });
  }

  loadData() {
    this.showNeedPrimaryError = false;
    this.selectedCrews = [];
    this.dynamicFieldsFilter = [];
    this.dynamicFields = [];
    this.ticketCrewRows = [];
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchTicketDetails());
    baseDataCalls.push(this.fetchTicketDocuments());
    //Document Loading is pending...
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  documentSrc: any;
  ticketDocumentName: string;

  fetchTicketDocuments() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDocuments(this.ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        if (this.ticketDocuments && this.ticketDocuments.length > 0) {
          this.ticketDocumentName = this.ticketDocuments[0].OriginalFileName;
          this.ticketService.fetchTicketDocument(this.ticketDocuments[0].TicketDocumentId).subscribe((data) => {
            if (this.ticketDocumentName.indexOf('pdf') != -1) {
              this.documentSrc = data;
              //this.showPDFViewer = true;
            } else {
              this.createAndDownloadBlobFile(data, this.ticketDocumentName);
              /*this.iziToast.info({
                title: 'Not a PDF File',
                message: 'Since it is not a PDF file, File is downloaded',
                position: 'topRight',
                timeout: 5000,
                close: true,
                overlay: false
              });*/
              this.showNonPDFInfo = true;
            }
            resolve(true);
          }, (err) => {
            reject(err);
          });
        }
      }, (err) => {
        reject(err);
      });
    }));
  }

  createAndDownloadBlobFile(body, filename) {
   // var blob = new Blob([body], {type: 'application/octet-stream'});
    saveAs(body, filename);
    /*if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link['download'] !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }*/
  }

  /*fetchTicketDocument() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDocuments(this.ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }*/


  fetchCompanyEventLinkedDynamicFields() {
    return new Promise((resolve, reject) => {
      this.rosterService.fetchAllApprovedRostersForCompanyEventAndRespondingCompanyDynamicFields(this.ticket.CompanyEventId).subscribe((res) => {
        console.log(res);
        if (res && res.length > 0) {
          this.dynamicFieldMapper = {};
          for (let df of res) {
            if (df.DynamicFieldValue && '' + df.DynamicFieldValue.trim() !== '') {
              if (this.dynamicFieldMapper['' + df.DynamicFieldId]) {
                this.dynamicFieldMapper['' + df.DynamicFieldId].dynamicFieldValues.push(df.DynamicFieldValue);
              } else {
                this.dynamicFieldMapper['' + df.DynamicFieldId] = new DynamicFieldMapper();
                this.dynamicFieldMapper['' + df.DynamicFieldId].DynamicFieldName = df.DynamicFieldName;
                this.dynamicFieldMapper['' + df.DynamicFieldId].dynamicFieldValues = [];
                this.dynamicFieldMapper['' + df.DynamicFieldId].dynamicFieldValues.push(df.DynamicFieldValue);
              }
            }

          }
          this.dynamicFields = [];
          for (let dfId of Object.keys(this.dynamicFieldMapper)) {
            let df = new DynamicField();
            df.DynamicFieldId = parseInt(dfId);
            df.DynamicFieldName = this.dynamicFieldMapper[dfId].DynamicFieldName;
            df.DynamicFieldValues = this.dynamicFieldMapper[dfId].dynamicFieldValues;
            this.dynamicFields.push(df);
          }
        }
        resolve(true);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    });

  }

  filterRosters() {
    this.spinner.show();
    this.fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations().then((r) => {
      this.spinner.hide();
    }).catch((err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  restDynamicFieldAndRosters() {
    this.dynamicFieldsFilter = [];
    for (let df of this.dynamicFields) {
      df.DynamicFieldValue = null;
    }
    this.filterRosters();
  }

  restWorkLocationFieldAndRosters() {
    this.workLocations = [];
    this.filterRosters();
  }

  fetchAllApprovedRostersForCompanyEventAndRespondingCompany() {
    this.selectedCrews = [];
    return new Promise((resolve, reject) => {
      this.rosterService.fetchAllApprovedRostersForCompanyEventAndRespondingCompany(this.ticket.CompanyEventId, this.dynamicFieldsFilter).subscribe((res) => {
        //this.ticketService.fetchAllRostersForCreateTicket().subscribe((res) => {
        this.rosters = res;
        if (this.rosters && this.rosters.length > 0) {
          this.prepareFormattedCompanyEventRosterName();
          this.selectedRosterId = this.rosters[0].RosterId;
          this.rosterService.fetchAllRosterCrews(this.rosters[0].RosterId).subscribe((res) => {
            this.rosterCrews = res;
            this.ticketService.fetchTicketCrews(this.ticketId).subscribe((res) => {
              this.ticketCrew = res;
              if (this.ticketCrew && this.ticketCrew.length > 0) {
                for (let tc of this.ticketCrew) {
                  if (tc.PrimaryCrew) {
                    tc.PrimaryCrewSelectedValue = tc.RosterId + '-' + tc.RosterCrewDetailsId;
                  }
                  for (let rc of this.rosterCrews) {
                    if (rc.RosterCrewDetailsId === tc.RosterCrewDetailsId) {
                      tc.ResourceType = rc.ResourceType;
                      tc.CrewId = rc.CrewId;
                    }
                  }
                }
                this.selectedCrews.push(...this.ticketCrew);
              }

              this.prepareRosterCrewToAssign();
              resolve(true);
            }, (err) => {
              reject(err);
            });
          }, (err) => {
            reject(err);
          });
        } else {
          this.iziToast.warning({
            title: 'No Roster Matched',
            message: 'Please check the Dynamic field filters!.',
            timeout: 5000,
            position: 'topRight'
          });
          this.ticketCrewRows = [];
          resolve(true);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  workLocations: number[] = [];

  fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations() {
    this.selectedCrews = [];
    return new Promise((resolve, reject) => {
      this.rosterService.fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations(this.ticket.CompanyEventId, this.workLocations).subscribe((res) => {
        //this.ticketService.fetchAllRostersForCreateTicket().subscribe((res) => {
        this.rosters = res;
        if (this.rosters && this.rosters.length > 0) {
          this.prepareFormattedCompanyEventRosterName();
          this.selectedRosterId = this.rosters[0].RosterId;
          this.rosterService.fetchAllRosterCrews(this.rosters[0].RosterId).subscribe((res) => {
            this.rosterCrews = res;
            this.ticketService.fetchTicketCrews(this.ticketId).subscribe((res) => {
              this.ticketCrew = res;
              if (this.ticketCrew && this.ticketCrew.length > 0) {
                for (let tc of this.ticketCrew) {
                  if (tc.PrimaryCrew) {
                    tc.PrimaryCrewSelectedValue = tc.RosterId + '-' + tc.RosterCrewDetailsId;
                  }
                  for (let rc of this.rosterCrews) {
                    if (rc.RosterCrewDetailsId === tc.RosterCrewDetailsId) {
                      tc.ResourceType = rc.ResourceType;
                      tc.CrewId = rc.CrewId;
                    }
                  }
                }
                this.selectedCrews.push(...this.ticketCrew);
              }

              this.prepareRosterCrewToAssign();
              resolve(true);
            }, (err) => {
              reject(err);
            });
          }, (err) => {
            reject(err);
          });
        } else {
          this.iziToast.warning({
            title: 'No Roster Matched',
            message: 'Please check the Dynamic field filters!.',
            timeout: 5000,
            position: 'topRight'
          });
          this.ticketCrewRows = [];
          resolve(true);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  dynamicFields: DynamicField[];
  dynamicFieldsFilter: DynamicField[];
  dynamicFieldMapper: { [key: string]: DynamicFieldMapper } = {};

  fetchTicketDetails() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDetails(this.ticketId).subscribe((res) => {
        this.ticket = res;
        //TODO
        this.fetchAllWorkLocationsByApprovedRostersForCompanyEventAndRespondingCompany().then((r) => {
          return this.fetchAllApprovedRostersForCompanyEventAndRespondingCompanyByWorkLocations();
        }).then((r) => {
          resolve(true);
        }).catch((err) => {
          reject(err);
        });
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchAllWorkLocationsByApprovedRostersForCompanyEventAndRespondingCompany() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllWorkLocationsByApprovedRostersForCompanyEventAndRespondingCompany(this.ticket.CompanyEventId).subscribe((res) => {
        this.rosterWorkLocations = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  prepareFormattedCompanyEventRosterName() {
    for (let r of this.rosters) {
      r.FormattedCompanyEventRosterName = this.commonUtil.prepareRosterName(r.RosterId) + ' - ' + r.RosterName;
    }
  }

  prepareRosterCrewToAssign() {
    this.ticketCrewRows = [];
    if (this.selectedCrews && this.selectedCrews.length > 0) {
      this.ticketCrewRows.push(...this.selectedCrews);
      if (this.rosterCrews && this.rosterCrews.length > 0) {
        for (let rc of this.rosterCrews) {
          let assignedAlready = false;
          for (let tc of this.selectedCrews) {
            if (tc.RosterId === rc.RosterId && tc.RosterCrewDetailsId === rc.RosterCrewDetailsId) {
              assignedAlready = true;
              break;
            }
          }
          if (!assignedAlready && rc.Status) {
            let tc = new TicketCrewModel();
            tc.RosterCrewDetailsId = rc.RosterCrewDetailsId;
            tc.RosterId = rc.RosterId;
            tc.CrewName = rc.FirstName + ' ' + rc.LastName;
            tc.CrewLead = rc.CrewLead;
            tc.ResourceType = rc.ResourceType;
            tc.CrewId = rc.CrewId;
            tc.CrewAssignedTicketCount = rc.CrewAssignedTicketCount;
            //  tc.PrimaryCrewSelectedValue = rc.RosterId+'+'+rc.CrewId;
            tc.PrimaryCrew = false;
            this.ticketCrewRows.push(tc);
          }
        }
      }
    } else {
      if (this.rosterCrews && this.rosterCrews.length > 0) {
        for (let rc of this.rosterCrews) {
          if (rc.Status) {
            let tc = new TicketCrewModel();
            tc.RosterCrewDetailsId = rc.RosterCrewDetailsId;
            tc.RosterId = rc.RosterId;
            tc.CrewName = rc.FirstName + ' ' + rc.LastName;
            tc.CrewLead = rc.CrewLead;
            tc.ResourceType = rc.ResourceType;
            tc.CrewAssignedTicketCount = rc.CrewAssignedTicketCount;
            tc.CrewId = rc.CrewId;
            //  tc.PrimaryCrewSelectedValue = rc.RosterId+'+'+rc.CrewId;
            tc.PrimaryCrew = false;
            this.ticketCrewRows.push(tc);
          }

        }

      }
    }
  }

  checkForSelectedCrew(rosterId, RosterCrewDetailsId) {
    let disable = true;
    for (let sc of this.selectedCrews) {
      if (sc.RosterId === rosterId && sc.RosterCrewDetailsId === RosterCrewDetailsId) {
        disable = false;
        break;
      }
    }
    return disable;
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selectedCrews);

    this.selectedCrews.splice(0, this.selectedCrews.length);
    this.selectedCrews.push(...selected);
    for (let tc of this.ticketCrewRows) {
      let matchInSelected = false;
      for (let sc of this.selectedCrews) {
        if (sc.RosterId === tc.RosterId && sc.RosterCrewDetailsId === tc.RosterCrewDetailsId) {
          matchInSelected = true;
        }
      }
      if (!matchInSelected) {
        tc.PrimaryCrewSelectedValue = null;
        tc.PrimaryCrew = false;
      }
    }

    console.log(this.selectedCrews);
  }

  managePrimary(rosterId, RosterCrewDetailsId) {
    for (let sc of this.selectedCrews) {
      if (!(sc.RosterId === rosterId && sc.RosterCrewDetailsId === RosterCrewDetailsId)) {
        sc.PrimaryCrewSelectedValue = null;
        sc.PrimaryCrew = false;
      } else {
        sc.PrimaryCrew = true;
        this.showNeedPrimaryError = false;
      }
    }
    console.log(this.ticketCrewRows);
  }

  prepareRosterName(rosterId) {
    return this.commonUtil.prepareRosterName(rosterId);
  }


  prepareCrewLeadTxt(crewLead) {
    // console.log('crewLead',crewLead)
    return crewLead === true ? 'Yes' : 'No';
  }

  onRosterChanged() {
    if (!this.selectedRosterId) {
      return;
    }
    this.showNeedPrimaryError = false;
    this.spinner.show();
    this.rosterService.fetchAllRosterCrews(this.selectedRosterId).subscribe((res) => {
      this.rosterCrews = res;
      this.prepareRosterCrewToAssign();
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    //validator.requiredNumber(this.validationError, 'selectedRosterId', this.selectedRosterId, 'Roster');
    if (this.validationError && this.validationError['selectedRosterId']) {
      return;
    }
  }

  backToTickets() {
    this.location.back();
  }

  showNeedPrimaryError = false;

  assignCrew() {
    this.showNeedPrimaryError = false;
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    if (this.selectedCrews && this.selectedCrews.length > 0) {
      let hasPrimaryCrew = false;
      for (let screw of this.selectedCrews) {
        if (screw.PrimaryCrew) {
          hasPrimaryCrew = true;
          break;
        }
      }
      if (!hasPrimaryCrew) {
        this.showNeedPrimaryError = true;
        return;
      }
    }
    this.spinner.show();
    this.ticketService.assignTicketCrew(this.ticketId, this.selectedCrews).subscribe((res) => {
      this.spinner.hide();
      this.iziToast.success({title: 'Ticket Crew Assigned', message: 'Ticket Crew Assigned successfully!.', position: 'topRight'});
      this.backToTickets();
    }, (err) => {
      this.spinner.hide();
      console.log(err);
      if (err.status === 422 || err.status === 400) {
        if (err.error.message) {
          this.errors.push(err.error.message);
        } else {
          //this.errors.push(err.error);
          this.handleReqErr.handleReqError(err, this.validationError);
        }
      } else {
        this.handleReqErr.handlePageLoadError(err);
      }

    });

  }
}
