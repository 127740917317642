import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth-service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {UserRoleModel} from '../model/user-role-model';
import {StatusDetailsModel} from '../model/status-details-model';
import {CompanyDetailsModel} from '../model/company-details-model';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';
import {CompanyTypeModel} from '../model/company-types-model';

@Injectable()
export class CommonService {
  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {
    this.toggleEvent = new Subject<string>();
  }

  toggleEvent: Subject<string>;

  getUserRoles(): Observable<UserRoleModel[]> {
    return this.http.post<UserRoleModel[]>(this.environment.serviceHostUrl + 'common/getUserRoles', {}, this.authService.prepareOptions());
  }

  getModuleStatus(moduleName: string): Observable<StatusDetailsModel[]> {
    return this.http.post<StatusDetailsModel[]>(this.environment.serviceHostUrl + 'common/getModuleStatus', {moduleName: moduleName}, this.authService.prepareOptions());
  }

  getAllActiveCompanies(): Observable<CompanyDetailsModel[]> {
    return this.http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'common/getAllActiveCompanies', {}, this.authService.prepareOptions());
  }


  getAllCompanyTypes(): Observable<CompanyTypeModel[]> {
    return this.http.post<CompanyTypeModel[]>(this.environment.serviceHostUrl + 'common/getAllCompanyTypes', {}, this.authService.prepareOptions());
  }
  getAllRespondingCompanies(): Observable<CompanyDetailsModel[]> {
    return this.http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'common/getAllRespondingCompanies', {}, this.authService.prepareOptions());
  }

  getAllRequestingCompanies(): Observable<CompanyDetailsModel[]> {
    return this.http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'common/getAllRequestingCompanies', {}, this.authService.prepareOptions());
  }



}
