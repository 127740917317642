import {Component, OnInit, Optional} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {SignUpPageComponent} from '../sign-up-page/sign-up-page.component';
import {VerifyMfaPageComponent} from '../verify-mfa-page/verify-mfa-page.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoginRequestModel} from '../../model/login-request-model';
import {ValidationError} from '../../validators/validation-error';
import {AuthService} from '../../providers/auth-service';
import {LocalDataService} from '../../providers/local-data-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {Router} from '@angular/router';
import {ForgotPasswordPageComponent} from '../forgot-password-page/forgot-password-page.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  loginReq: LoginRequestModel;
  errors: string[] = [];
  isSignUpSuccess: boolean = false;
  validationError: ValidationError = new ValidationError();
  rememberMe: boolean = false;

  //@Optional()
  public bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService,
              private spinner: NgxSpinnerService, private authService: AuthService,
              private localDataService: LocalDataService, private router: Router,
              private handleReqErr: HandleRequestError) {
  }

  ngOnInit() {
    this.loginReq = new LoginRequestModel();
    if(localStorage.getItem('lastLoggedInUserEmail')){
      this.loginReq.email = localStorage.getItem('lastLoggedInUserEmail');
      this.rememberMe = true;
    }
    this.isSignUpSuccess = this.modalService.config.initialState['isSignUpSuccess'];
    console.log('this.isSignUpSuccess', this.isSignUpSuccess);
  }

  alertdd() {
    alert('sss');
  }

  openSignupModel() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(SignUpPageComponent, {class: 'modal-dialog-centered modal-lg ', backdrop: 'static'});
  }

  openForgotPasswordModel() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(ForgotPasswordPageComponent, {class: 'modal-dialog-centered ', backdrop: 'static'});
  }

  login() {
    if(this.rememberMe){
      localStorage.setItem('lastLoggedInUserEmail', this.loginReq.email);
    }else{
      localStorage.removeItem('lastLoggedInUserEmail');
    }
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.loginReq.validate();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.authService.login(this.loginReq).subscribe((res) => {
      if (res && res.token) {

        this.spinner.hide();

        /*this.bsModalRef = this.modalService.show(VerifyMfaPageComponent, {
          class: 'modal-dialog-centered ',
          backdrop: "static"
        });*/
        if (res.status === environment.moduleStatus.UserManagementRegistered && res.mfaVerified) {
          this.isSignUpSuccess = true;
        }else{
          this.authService.token = res.token;
          this.authService.setToken(res.token);
          //this.bsModalRef.hide();
          if (res.mfaVerified && res.status === environment.moduleStatus.UserManagementActive) {
            this.router.navigate(['/home']);
            localStorage.setItem('sessionStorage', res.token);
            localStorage.removeItem('sessionStorage');
          } else {
           // this.bsModalRef = this.modalService.show(VerifyMfaPageComponent, {class: 'modal-dialog-centered ', backdrop: 'static'});
            this.authService.inSignUpProcess = true;
            this.router.navigate(['/new-user-2','verifyMFA']);
          }
        }

      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err) => {
      this.spinner.hide();
      /*if(err.status === 400){
        this.validationError = err.error;
      }else if(err.status === 401 || err.status === 500){
        for(let e of Object.keys(err.error)){
          this.errors.push(err.error[e]);
        }
      }else{
        this.errors.push('Internal Server Error, please try again later.');
      }*/
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });

  }

  close(){
    this.bsModalRef.hide();
  }
}
