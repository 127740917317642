import {Component, Input} from '@angular/core';


/**
 * Generated class for the ValidationErrorDisplayPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-validation-error-display',
  templateUrl: 'validation-error-display.html',
})
export class ValidationErrorDisplayPage {

  constructor() {
  }

  @Input('errors')
  errors: string[];


}
