import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../providers/auth-service';
import {LocalDataService} from '../providers/local-data-service';
import {UserDetailsModel} from '../model/user-details-model';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService, private localDataService: LocalDataService ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {

      this.localDataService.readUserToken().subscribe((data) => {
        if (data) {
          this.authService.token = data;
          this.authService.refreshToken().subscribe((res) => {
            if(res && res.mfaVerified){
              let userDetails = new UserDetailsModel();
              userDetails.mapDetails(res);
              this.authService.userDetails = userDetails;
              console.log('All good');
              resolve(true);
            }else{
              console.log('Not good - No Token in response!');
              this.router.navigate(['']);
              resolve(false);
            }
          },(err)=>{
            console.log('Not good - Expired Token');
            this.router.navigate(['']);
            resolve(false);
          });
        }else{
          setTimeout(()=>{
            this.localDataService.readUserToken().subscribe((data) => {
              if (data) {
                this.authService.token = data;
                this.authService.refreshToken().subscribe((res) => {
                  if (res && res.mfaVerified) {
                    let userDetails = new UserDetailsModel();
                    userDetails.mapDetails(res);
                    this.authService.userDetails = userDetails;
                    console.log('All good');
                    resolve(true);
                  } else {
                    console.log('Not good - No Token in response!');
                    this.router.navigate(['']);
                    resolve(false);
                  }
                }, (err) => {
                  console.log('Not good - Expired Token');
                  this.router.navigate(['']);
                  resolve(false);
                });
              }else{
                console.log('Not good - No Token in session');
                this.router.navigate(['']);
                resolve(false);
              }
            });
          }, 2000);

        }
      },(err)=>{
        console.log('Not good - Error reading token from session');
        this.router.navigate(['']);
        resolve(false);
      });


    }) ;


  }

}
