import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';

export class LoginRequestModel {
  email: string;
  password: string;
  verificationCode: string;

  validate(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    //EMAIL
    validator.validateEmail(error, 'email', this.email, 'Email');
    //Password
    validator.requiredString(error, 'password', this.password, 'Password');
    return error;
  }

  validateVerificationCode(){
    let error = new ValidationError();
    let validator = new Validators();
    //Password
    validator.requiredString(error, 'verificationCode', this.verificationCode, 'Verification Code');
    return error;
  }
}
