import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth-service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CompanyEventModel, RespondingCompanyUsers} from '../model/company-event-model';
import {environment} from '../../environments/environment';
import {ServiceResponse} from '../model/service-response';
import * as moment from "moment";
import {ProfileModel} from '../model/profile-model';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';
import {CompanyDetailsModel} from '../model/company-details-model';


@Injectable()
export class CompanyEventsService {
  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  fetchCompanyEvent(companyEventId: number): Observable<CompanyEventModel> {
    return this.http.post<CompanyEventModel>(this.environment.serviceHostUrl + 'companyEvent/fetchCompanyEvent', {companyEventId:companyEventId},this.authService.prepareOptions());
  }

  checkAnyApprovedReleasedRosterAndOpenTickets(companyEventId: number): Observable<any> {
    return this.http.post<any>(this.environment.serviceHostUrl + 'companyEvent/checkAnyApprovedReleasedRosterAndOpenTickets', {companyEventId:companyEventId},this.authService.prepareOptions());
  }

  fetchUserMatchingEmail(eMail_ID: string): Observable<ProfileModel> {
    return this.http.post<ProfileModel>(this.environment.serviceHostUrl + 'companyEvent/fetchUserMatchingEmail', {eMail_ID:eMail_ID},this.authService.prepareOptions());
  }

  fetchAllCompanyEvents(): Observable<CompanyEventModel[]> {
    return this.http.get<CompanyEventModel[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllCompanyEvents', this.authService.prepareOptions());
  }

  fetchAllCompanyEventsForMasterEvent(masterEventIds: number[]): Observable<CompanyEventModel[]> {
    return this.http.post<CompanyEventModel[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllCompanyEventsForMasterEvent',{masterEventIds:masterEventIds}, this.authService.prepareOptions());
  }

  fetchAllRespondingCompaniesForCompanyEvents(companyEventIds: number[]): Observable<CompanyDetailsModel[]> {
    return this.http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllRespondingCompaniesForCompanyEvents',{companyEventIds:companyEventIds}, this.authService.prepareOptions());
  }

  fetchAllActiveCompanyEvents(masterEventId: number): Observable<CompanyEventModel[]> {
    return this.http.post<CompanyEventModel[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllActiveCompanyEvents',{masterEventId:masterEventId}, this.authService.prepareOptions());
  }

  fetchAllCompanyEventInvites(masterEventId?: number): Observable<CompanyEventModel[]> {
    return this.http.post<CompanyEventModel[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllCompanyEventInvites',{masterEventId:masterEventId}, this.authService.prepareOptions());
  }

  fetchAllActiveUsersOfRespondingCompany(companyIds : string): Observable<RespondingCompanyUsers[]> {

    return this.http.post<RespondingCompanyUsers[]>(this.environment.serviceHostUrl + 'companyEvent/fetchAllActiveUsersOfRespondingCompany', {companyIds:companyIds}, this.authService.prepareOptions());
  }

  addCompanyEvent(companyEvent: CompanyEventModel): Observable<ServiceResponse> {
    companyEvent.StartDateTxt =  moment(companyEvent.StartDate).format('MM-DD-YYYY 00:00:00');
    if(companyEvent.EndDate){
      companyEvent.EndDateTxt =  moment(companyEvent.EndDate).format('MM-DD-YYYY 00:00:00');
    }else{
      companyEvent.EndDateTxt = null;
    }

    console.log(companyEvent);
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'companyEvent/addCompanyEvent', companyEvent, this.authService.prepareOptions());
  }

  updateCompanyEvent(companyEvent: CompanyEventModel): Observable<ServiceResponse> {
    companyEvent.StartDateTxt =  moment(companyEvent.StartDate).format('MM-DD-YYYY 00:00:00');
    if(companyEvent.EndDate){
      companyEvent.EndDateTxt =  moment(companyEvent.EndDate).format('MM-DD-YYYY 00:00:00');
    }else{
      companyEvent.EndDateTxt = null;
    }

    console.log(companyEvent);
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'companyEvent/updateCompanyEvent', companyEvent, this.authService.prepareOptions());
  }

  deleteCompanyEvent(companyEventIds: string): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'companyEvent/deleteCompanyEvent', {companyEventIds:companyEventIds}, this.authService.prepareOptions());
  }

}
