import { Component, OnInit } from '@angular/core';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {CommonService} from '../../providers/common-service';
import {UserRoleModel} from '../../model/user-role-model';
import {StatusDetailsModel} from '../../model/status-details-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {UserManagementService} from '../../providers/user-mgmt-service';
import {LoginPageComponent} from '../login-page/login-page.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-manage-user-page',
  templateUrl: './modify-user-page.component.html',
  styleUrls: ['./modify-user-page.component.css']
})
export class ModifyUserPageComponent implements OnInit {
  moduleStatus = environment.moduleStatus;
  constructor(public iziToast: Ng2IzitoastService, public bsModalRef: BsModalRef, private spinner: NgxSpinnerService,
              private authService: AuthService, private modalService: BsModalService, private userMgmtServicce: UserManagementService,
              private commonService: CommonService, private handleReqErr: HandleRequestError) { }

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;


  ngOnInit() {
    this.isEditUser = this.modalService.config.initialState['isEditUser'];
    this.UserId = this.modalService.config.initialState['UserId'];
    this.CompanyId = this.modalService.config.initialState['CompanyId'];

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.user = new ProfileModel();

    this.user.Status = environment.moduleStatus.UserManagementActive;
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.getAllActiveCompanies());
    baseDataCalls.push(this.getModuleStatus());
    baseDataCalls.push(this.getAllRoles());
    if(this.isEditUser){
      baseDataCalls.push(this.getUserDetailsById());
    }

    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.iziToast.error({
        title: 'Error occurred',
        message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
        position: 'center',
        timeout: 3000,
        close: true,
        overlay: true
      });
      //  this.handleReqErr.handlePageLoadError(e);
    });
  }
  user: ProfileModel;
  errors: string[] = [];
  UserId: number;
  CompanyId: number;
  validationError: ValidationError = new ValidationError();
  isEditUser: boolean = true;
  userRoles: UserRoleModel[];
  userModuleStatus: StatusDetailsModel[];
  activeCompanies: CompanyDetailsModel[];

  getAllRoles() {
    return new Promise(((resolve, reject) => {
      this.commonService.getUserRoles().subscribe((res) => {
        console.log(res);
        if(this.userRole !== environment.userRoles.SuperAdministrator){
          let tRoles: UserRoleModel[] = [];
          for(let tr of res){
            if(tr.RoleId !== environment.userRoles.SuperAdministrator && tr.RoleId !== environment.userRoles.FieldResource){
              tRoles.push(tr);
            }
          }
          this.userRoles = tRoles;
        }else{
          let tRoles: UserRoleModel[] = [];
          for(let tr of res){
            if(tr.RoleId !== environment.userRoles.FieldResource){
              tRoles.push(tr);
            }
          }
          this.userRoles = tRoles;
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  getUserDetailsById() {
    return new Promise(((resolve, reject) => {
      this.userMgmtServicce.getUserDetailsById(this.UserId, this.CompanyId).subscribe((res) => {
        console.log(res);
        this.user = new ProfileModel();
        this.user['UserId'] = res['UserId'];
        this.user['FirstName'] = res['FirstName'];
        this.user['BusinessName'] = res['BusinessName'];
        this.user['LastName'] = res['LastName'];
        this.user['PhoneNumber'] = res['PhoneNumber'];
        this.user['EmailAlerts'] = res['EmailAlerts'];
        this.user['SMSAlerts'] = res['SMSAlerts'];
        this.user['UserRole'] = res['UserRole'];
        this.user['CompanyId'] = res['CompanyId'];
        this.user['Status'] = res['Status'];
        this.user['eMail_ID'] = res['eMail_ID'];
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  getModuleStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('User Management').subscribe((res) => {
        console.log(res);
        this.userModuleStatus = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  getAllActiveCompanies() {
    return new Promise(((resolve, reject) => {
      this.commonService.getAllActiveCompanies().subscribe((res) => {
        console.log(res);
        this.activeCompanies = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  submitAttempted = false;
  validateFields(){
    if(!this.submitAttempted){
      return;
    }
    this.validationError = new ValidationError();
    let err = this.user.validateAddUser();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }

  addUserToCompany(close: boolean){
    if(this.userRole === 2){
      this.user.CompanyId = this.adminCompanyId;
    }
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.user.validateAddUser();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }

    this.spinner.show();
    this.userMgmtServicce.addUser(this.user).subscribe((res) => {
      this.spinner.hide();


      this.iziToast.success({
        title: 'New User Added',
        message: 'New User added successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.user = new ProfileModel();
      this.submitAttempted = false;
      if(close){
        this.bsModalRef.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  updateUserToCompany(){
    if(this.userRole === 2){
      this.user.CompanyId = this.adminCompanyId;
    }
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let err = this.user.validateOnUpdateUser();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
 
    this.spinner.show();
    this.userMgmtServicce.updateUser(this.user).subscribe((res) => {
      this.spinner.hide();


      this.iziToast.success({
        title: 'User Details updated',
        message: 'User Details updated successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.bsModalRef.hide();
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }
}
