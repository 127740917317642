import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-training-page',
  templateUrl: './training-page.component.html',
  styleUrls: ['./training-page.component.css']
})
export class TrainingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  verticalMode = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (document.documentElement.clientWidth < 768) {
      this.verticalMode = true;
    }else{
      this.verticalMode = false;
    }
  }
}
