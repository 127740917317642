import {DynamicField} from './company-event-model';

export class RosterModel {

  RespondingCompanyID:number;
  CompanyEventId:number;
  CompanyEventStatus:number;
  CompanyEventName: string;
  MasterEventId:number;
  RosterId:number;
  Status:number;

  EventName:string;
  WorkLocation:string;
  RosterName:string;
  FormattedCompanyEventRosterName:string;
  StartDate:string;
  EndDate:string;
  RequestingCompanyId:number;
  RequestingCompanyName:string;
  RespondingCompanyName:string;
  RespondedCompanyUserName:string;
  CreatedDate:string;
  UpdatedDate:string;
  StatusValue:string;
  CrewCount:string;

  dynamicFields: DynamicField[];
}

export class RosterTicketModel{
  [key:string] : RosterModel;
}

export class RosterWorkLocationModel {
  WorkLocation: string;
}

export class RosterApproveRequestModel {
  rosters: RosterModel[];
  CompanyEventId:number;
  RosterId:number;
  dynamicFields: DynamicField[];
}

export class RosterApproveResponseModel {
  alreadyApprovedRosters: number[];
  rostersApproved:boolean;
  rostersAlreadyApproved:boolean;
}


export class RosterReleaseResponseModel {
  alreadyReleasedRosters: number[];
  rostersReleased:boolean;
  rostersAlreadyReleased:boolean;
}

export class RosterReturnToHomeResponseModel {
  alreadyReturnToHomeRosters: number[];
  rostersReturnToHome:boolean;
  rostersAlreadyReturnToHome:boolean;
}
