import {Injectable} from '@angular/core';
import {ValidationError} from '../validators/validation-error';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {AuthService} from './auth-service';
import {LocalDataService} from './local-data-service';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import {FieldResourceService} from './field-resource-service';

@Injectable()
export class HandleRequestError {

  constructor(public iziToast: Ng2IzitoastService, private router: Router, private authService: AuthService,
              private localDataService: LocalDataService, private location: Location, private fieldResourceService: FieldResourceService) {

  }

  handleReqError(err, errors) {
    console.log(err);
    let validationError = new ValidationError();
    if (err.status === 400) {
      validationError = err.error;
    } else if (err.status === 401) {
      for (let e of Object.keys(err.error)) {
        errors.push(err.error[e]);
      }
    } else if (err.status === 403) {
      this.iziToast.error({
        title: 'Access Denied',
        message: 'Access denied to the resource you are looking for. Are your account is locked/In-Active. Please check with your admin.',
        position: 'center',
        timeout: 0,
        close: false,
        overlay: true
      });
    } else {
      this.iziToast.error({
        title: 'Error occurred',
        message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
        position: 'center',
        timeout: 3000,
        close: true,
        overlay: true
      });
    }
    return validationError;
  }

  handlePageLoadError(err) {
    console.log(err);
    if (err.status === 422) {
      this.iziToast.error({
        title: 'Following Error occurred',
        message: err.error.message,
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
    } else if (err.status === 403) {
      this.iziToast.error({
        title: 'Access Denied',
        message: 'Sorry, you don\'t have access to the requested resource.',
        position: 'center',
        timeout: 0,
        close: false,
        overlay: true
      });

      if(this.location.path().indexOf('/fieldResource/') != -1){
        this.logoutFR();
      }else{
        this.logout(true);
      }


    } else {
      this.iziToast.error({
        title: 'Error occurred',
        message: 'Please try again later or refresh the page. If error persists, take screen shot of the error and contact support.',
        position: 'center',
        timeout: 3000,
        close: true,
        overlay: true
      });
    }
  }



  logout(clear){
    if(clear){
      localStorage.setItem('clearSessionStorage', 'true');
      localStorage.removeItem('clearSessionStorage');
    }
    this.localDataService.clearUserToken().subscribe((r1)=>{
      this.authService.logout().subscribe((r)=>{
        this.authService.token = null;
        this.router.navigate(['']);
      },(e)=>{
        console.log(e);

      })
    });

  }


  logoutFR(){
    this.localDataService.clearFRUserToken();
    this.fieldResourceService.frDetails = null;
    this.router.navigate(['']);
  }

}
