import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import {ForgotPasswordPageComponent} from '../forgot-password-page/forgot-password-page.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {SetFirstPasswordPageComponent} from '../set-first-password-page/set-first-password-page.component';

@Component({
  selector: 'app-public-welcome-page',
  templateUrl: './public-welcome-page.component.html',
  styleUrls: ['./public-welcome-page.component.css']
})
export class PublicWelcomePageComponent implements OnInit {

  constructor(private modalService: BsModalService, private route: ActivatedRoute ) {
    this.route.params.subscribe( (params) => {
      if(params['setPassword'] === 'setPassword'){
        this.openSetPasswordModel();
      }
    });
  }
  bsModalRef: BsModalRef;
  ngOnInit() {

  }

  openSetPasswordModel() {
    this.bsModalRef = this.modalService.show(SetFirstPasswordPageComponent, {class: 'modal-dialog-centered ', backdrop: 'static'});
  }

}
