import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MasterEventModel} from '../../model/master-event-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {
  RosterApproveRequestModel,
  RosterApproveResponseModel,
  RosterModel,
  RosterReleaseResponseModel,
  RosterReturnToHomeResponseModel
} from '../../model/roster-model';
import {RosterService} from '../../providers/roster-service';
import {CommonUtil} from '../../util/common-util';
import {Subscription} from 'rxjs';
import {AuthService} from '../../providers/auth-service';
import {ModifyRosterDetailsPageComponent} from '../modify-roster-details-page/modify-roster-details-page.component';
import {ActivatedRoute} from '@angular/router';
import {AssignRosterToCompanyEventPageComponent} from '../assign-roster-to-company-event-page/assign-roster-to-company-event-page.component';
import {CompanyEventsService} from '../../providers/company-events-service';
import {UploadRosterPageComponent} from '../upload-roster-page/upload-roster-page.component';
import {environment} from '../../../environments/environment';
import {CompanyEventModel, DynamicField} from '../../model/company-event-model';
import {ValidationError} from '../../validators/validation-error';
import {EnvironmentService} from '../../providers/environment-service';

@Component({
  selector: 'app-rosters-page',
  templateUrl: './rosters-page.component.html',
  styleUrls: ['./rosters-page.component.css']
})
export class RostersPageComponent implements OnInit, OnDestroy {

  pageLoadSubscription: Subscription;

  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }


  adminCompanyId: number;
  adminCompanyName: string;
  pageStatus: string;
  userRole: number;

  rosters: RosterModel[];
  dynamicFields: DynamicField[];
  rostersRows: RosterModel[];
  selected: RosterModel[] = [];

  bsModalRef: BsModalRef;
  templateURL = null;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService, private companyEventService: CompanyEventsService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil, private environment: EnvironmentService,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
    this.templateURL = this.environment.serviceHostUrl + 'MARC-RosterTemplate.xlsx';
  }

  loadData() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableReleaseBtn = true;
    this.disableDeleteBtn = true;
    this.disableAssignBtn = true;
    this.disablePullBtn = true;
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    if (this.pageStatus === 'current') {
      baseDataCalls.push(this.fetchAllNonReleasedRosters());
      //baseDataCalls.push(this.fetchAllNonReleasedRostersDynamicFields());
    } else {
      baseDataCalls.push(this.fetchAllReleasedRosters());
    }

    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });


  }

  addRoster() {
    this.bsModalRef = this.modalService.show(UploadRosterPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {
        companyEvent: null,
        showCompanyEventSelect: true
      },
      backdrop: 'static'
    });
  }

  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.route.params.subscribe((params) => {
      this.pageStatus = params['pageStatus'];
      this.loadData();

    });
  }


  fetchAllNonReleasedRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllNonReleasedRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.rosters = res;
          this.prepareRosters();
          this.rostersRows = [...this.rosters];
        } else {
          this.rosters = [];
          this.rostersRows = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  dynamicFieldColumns = [];
  rosterDynamicFieldMapping: { [key: string]: DynamicField[] } = {};

  fetchAllNonReleasedRostersDynamicFields() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllNonReleasedRostersDynamicFields().subscribe((res) => {
        console.log(res);
        if (res) {
          this.dynamicFields = res;
        } else {
          this.dynamicFields = [];
        }
        this.dynamicFieldColumns = [];
        this.rosterDynamicFieldMapping = {};
        let maxColumns = 0;
        if (this.dynamicFields.length > 0) {
          for (let dynamicField of this.dynamicFields) {
            if (this.rosterDynamicFieldMapping['' + dynamicField.RosterId]) {
              this.rosterDynamicFieldMapping['' + dynamicField.RosterId].push(dynamicField);
              if (this.rosterDynamicFieldMapping['' + dynamicField.RosterId].length > maxColumns) {
                maxColumns = this.rosterDynamicFieldMapping['' + dynamicField.RosterId].length;
              }
            } else {
              this.rosterDynamicFieldMapping['' + dynamicField.RosterId] = [];
              this.rosterDynamicFieldMapping['' + dynamicField.RosterId].push(dynamicField);
            }
          }
        }
        for (let i = 1; i <= maxColumns; i++) {
          this.dynamicFieldColumns.push('Dynamic Field ' + i);
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchDynamicFieldNameOfRosterInIndex(rosterId, status, index) {
    if(status !== environment.moduleStatus.RosterApproved){
      return '';
    }
    if (this.rosterDynamicFieldMapping['' + rosterId] && this.rosterDynamicFieldMapping['' + rosterId].length > index) {
      return this.rosterDynamicFieldMapping['' + rosterId][index].DynamicFieldName;
    } else {
      return '';
    }
  }

  fetchDynamicFieldValueOfRosterInIndex(rosterId, status, index) {
    if(status !== environment.moduleStatus.RosterApproved){
      return '';
    }
    if (this.rosterDynamicFieldMapping['' + rosterId] && this.rosterDynamicFieldMapping['' + rosterId].length > index) {
      return this.rosterDynamicFieldMapping['' + rosterId][index].DynamicFieldValue;
    } else {
      return '';
    }
  }

  fetchAllReleasedRosters() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllReleasedRosters().subscribe((res) => {
        console.log(res);
        if (res) {
          this.rosters = res;
          this.prepareRosters();
          this.rostersRows = [...this.rosters];
        } else {
          this.rosters = [];
          this.rostersRows = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  getCompanyEventClass({row, column, value, adminCompanyName}): any {
    return {
      'our-company-event': row['RequestingCompanyName'] === row['adminCompanyName'],
      'other-company-event': row['RequestingCompanyName'] !== row['adminCompanyName']
    };
  }

  prepareRosters() {
    if (!this.rosters || this.rosters.length <= 0) {
      return;
    }
    for (let ce of this.rosters) {
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
    }
  }

  disableDeleteBtn = true;
  disableEditBtn = true;
  disableApproveBtn = true;
  disableReleaseBtn = true;
  disableAssignBtn = true;
  disableMarkReturnToHomeBtn = true;
  disablePullBtn = true;
  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.rosters.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.rostersRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableEditBtn = true;
    this.disableDeleteBtn = true;
    this.disableApproveBtn = true;
    this.disableReleaseBtn = true;

    this.disableAssignBtn = true;
    this.disableMarkReturnToHomeBtn = true;
    this.disablePullBtn = true;
  }


  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }

    if(this.pageStatus === 'current'){
      let primaryFieldFilter = this.matchContent(this.commonUtil.prepareRosterName(d.RosterId), val) || this.matchContent(d.RosterName, val) ||
        this.matchContent(this.commonUtil.prepareCompanyEventName(d.CompanyEventId), val) ||
        this.matchContent(d.RespondingCompanyName, val) || this.matchContent(d.RespondedCompanyUserName, val) ||
        this.matchContent(d.StatusValue, val) || this.matchContent(d.CompanyEventName, val) || this.matchContent(d.WorkLocation, val) ||
        !val;

      return primaryFieldFilter;
    }else{
      let primaryFieldFilter = this.matchContent(this.commonUtil.prepareRosterName(d.RosterId), val) || this.matchContent(d.RosterName, val) ||
        this.matchContent(this.commonUtil.prepareCompanyEventName(d.CompanyEventId), val) ||
        this.matchContent(d.RespondingCompanyName, val) || this.matchContent(d.RespondedCompanyUserName, val) ||
        this.matchContent(d.StatusValue, val) || this.matchContent(d.CompanyEventName, val) ||
        !val;

      return primaryFieldFilter;
    }

    //this.matchContent(d.EventName, val) ||  this.matchContent(d.RequestingCompanyName, val) ||   this.matchContent(d.StartDate, val) || this.matchContent(d.EndDate, val) ||
  }

  checkFilterWithDynamicField(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    let primaryFieldFilter = this.matchContent(this.commonUtil.prepareRosterName(d.RosterId), val) || this.matchContent(d.RosterName, val) ||
      this.matchContent(this.commonUtil.prepareCompanyEventName(d.CompanyEventId), val) ||
      this.matchContent(d.RespondingCompanyName, val) || this.matchContent(d.RespondedCompanyUserName, val) ||
      this.matchContent(d.StatusValue, val) || this.matchContent(d.CompanyEventName, val) || this.matchContent(d.WorkLocation, val) ||
      !val;

    let matchInDynamicField = false;
    let dynamicFields: DynamicField[] = this.rosterDynamicFieldMapping[d.RosterId];
    if (dynamicFields && dynamicFields.length > 0) {
      for (let dynamicField of dynamicFields) {
        if (this.matchContent(dynamicField.DynamicFieldValue, val)) {
          matchInDynamicField = true;
          break;
        }
      }
    }
    return primaryFieldFilter || matchInDynamicField;
    //this.matchContent(d.EventName, val) ||  this.matchContent(d.RequestingCompanyName, val) ||   this.matchContent(d.StartDate, val) || this.matchContent(d.EndDate, val) ||
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }


  getRowClass(row): any {
    return {
      'disabled-row': row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }

  getClosedCECheckboxClass({row, column, value}): any {
    return {
      'hide-checkbox': row['CompanyEventStatus'] === environment.moduleStatus.CompanyEventClosed
    };
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.rosters) {
      if (this.selected.length === this.rosters.length) {
        this.iziToast.warning({
          title: 'All Rosters selected',
          message: 'You have selected all the Rosters. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    /*let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      if (sel.Status === 1 && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      if (sel.Status === 2 && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }*/
    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableReleaseBtn = true;
    this.disablePullBtn = true;
    this.disableAssignBtn = true;
    this.disableMarkReturnToHomeBtn = true;

    if (this.selected.length === 1) {

      this.disableEditBtn = false;
      this.disableAssignBtn = false;

      if (this.selected[0].RequestingCompanyId === this.adminCompanyId) {
        this.disableReleaseBtn = false;
        if (this.selected[0].Status !== environment.moduleStatus.RosterApproved) {
          this.disableApproveBtn = false;
        }
      }
      this.disablePullBtn = false;


    } else if (this.selected.length > 1) {
      {
        let tAppRos = true;
        let tRelRos = true;
        let tAsiRos = true;
        let rosterMasterEventId = 0;
        for (let tr of this.selected) {
          if (tr.RequestingCompanyId === this.adminCompanyId) {
            if (tr.Status === environment.moduleStatus.RosterApproved) {
              tAppRos = false;
            }
          } else {
            tRelRos = false;
            tAppRos = false;
          }
          if(rosterMasterEventId === 0){
            rosterMasterEventId = tr.MasterEventId;
          }else{
            if(rosterMasterEventId !== tr.MasterEventId){
              tAsiRos = false;
            }
          }
        }
        this.disableApproveBtn = !tAppRos;
        this.disableReleaseBtn = !tRelRos;
        this.disableAssignBtn = !tAsiRos;
      }
    }

    //disableMarkReturnToHomeBtn

    if (this.selected.length > 0) {
      let tMarkRtHome= true;
      for(let tr of this.selected){
        if (tr.RespondingCompanyID !== this.adminCompanyId) {
          tMarkRtHome = false;
        }
      }
      this.disableMarkReturnToHomeBtn = !tMarkRtHome;
    }
    /* if (this.selected.length > 0) {
       this.disableActivateBtn = !allInActiveUsers;
       this.disableInActivateBtn = !allActiveUsers;
     } else {
       this.disableActivateBtn = true;
       this.disableInActivateBtn = true;
     }*/
    let rosterIds = '';
    for (let rs of this.selected) {
      if (rosterIds === '') {
        rosterIds = rosterIds + rs.RosterId;
      } else {
        rosterIds = rosterIds + ',' + rs.RosterId;
      }
    }
    /*this.rosterService.fetchRosterDetailsForReport(null, rosterIds).subscribe((res)=>{
      console.log(res);
    },(err)=>{
      console.log(err);
    });*/
    this.disableReleaseBtn = false;
  }

  getUserStatusClass({row, column, value}): any {
    return {
      'user-active': value === 'Approved',
      'user-in-active': value === 'Initiated'
    };
  }

  companyEvent: CompanyEventModel;

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();

  closeApproveDialog() {
    this.bsModalRef.hide();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  fetchCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchCompanyEvent(this.selected[0].CompanyEventId).subscribe((res) => {
        console.log(res);
        this.companyEvent = new CompanyEventModel();
        this.companyEvent.mapCompanyEvent(res);
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  @ViewChild('approveRosterTemplate')
  approveRosterTemplate: any;
  rosterApproveResponse: RosterApproveResponseModel;
  rosterAlreadyApproved: boolean;

  approveRosterEvent() {
    this.workLocation = '';
    this.spinner.show();
    this.rosterApproveResponse = null;
    this.rosterAlreadyApproved = false;
    let rosterApproveReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      rosterApproveReq.push(tempRoster);
    }
    this.rosterService.checkForRosterApproved(rosterApproveReq).subscribe((res)=>{
      if(res && res.alreadyApprovedRosters && res.alreadyApprovedRosters.length > 0){
        this.rosterApproveResponse = res;
        this.rosterAlreadyApproved = true;
        this.fetchCompanyEvents().then((r) => {
          this.spinner.hide();
          this.bsModalRef = this.modalService.show(this.approveRosterTemplate, {
            class: 'modal-dialog-centered modal-lg',
            initialState: {
              companyEvent: null,
              showCompanyEventSelect: true
            },
            backdrop: 'static'
          });
        }).catch((err) => {
          this.spinner.hide();
          this.handleReqErr.handlePageLoadError(err);
        });
      }else{
        this.fetchCompanyEvents().then((r) => {
          this.spinner.hide();

          this.bsModalRef = this.modalService.show(this.approveRosterTemplate, {
            class: 'modal-dialog-centered modal-lg',
            initialState: {
              companyEvent: null,
              showCompanyEventSelect: true
            },
            backdrop: 'static'
          });
        }).catch((err) => {
          this.spinner.hide();
          this.handleReqErr.handlePageLoadError(err);
        });
      }
    },(err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });


   /* if (this.selected && this.selected.length === 1) {

    } else {

      this.iziToast.warning({
        title: 'No Roster Selected', message: 'Please select a Roster to Approve.', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
    }*/


    /*this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Approve the Roster? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onApproveConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });*/
  }

  workLocation : string;
  onApproveConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    this.rosterApproveResponse = null;
    this.rosterAlreadyApproved = false;
    let rosterApproveReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      tempRoster.CompanyEventId = tr.CompanyEventId;
      tempRoster.WorkLocation = this.workLocation;
      rosterApproveReq.push(tempRoster);
    }
    this.rosterService.approveRoster(rosterApproveReq).subscribe((res) => {
      console.log(res);
      this.rosterApproveResponse = res;
      if(this.rosterApproveResponse.rostersApproved){
        this.spinner.hide();
        this.bsModalRef.hide();
        this.iziToast.success({
          title: 'Roster Approved', message: 'Roster Approved Successful!', position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.loadData();
      }else{
        this.rosterAlreadyApproved = true;
        this.spinner.hide();
      }

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }


  @ViewChild('releaseRosterErrorTemplate')
  releaseRosterErrorTemplate: any;
  rosterReleaseResponse: RosterReleaseResponseModel;

  releaseRosterEvent() {
    this.spinner.show();
    this.rosterReleaseResponse = null;
    let rosterReleaseReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      rosterReleaseReq.push(tempRoster);
    }
    this.rosterService.checkForRosterReleased(rosterReleaseReq).subscribe((res)=>{
      if(res && res.alreadyReleasedRosters && res.alreadyReleasedRosters.length > 0){
        this.rosterReleaseResponse = res;
        this.spinner.hide();
        this.bsModalRef = this.modalService.show(this.releaseRosterErrorTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }else{
        this.spinner.hide();
        this.iziToast.question({
          close: false,
          closeOnEscape: true,
          overlay: true,
          timeout: 0,
          id: 'question',
          title: 'Do you really want to Release the Roster? ',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', (instance, toast) => {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
              this.onReleaseConfirm();
            }, true],
            ['<button>NO</button>', (instance, toast) => {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

            }],
          ]
        });
      }
    },(err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }

  onReleaseConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    let rosterReleaseReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      tempRoster.CompanyEventId = tr.CompanyEventId;
      rosterReleaseReq.push(tempRoster);
    }
    this.rosterService.releaseRoster(rosterReleaseReq).subscribe((res) => {
      console.log(res);
      if(res && res.alreadyReleasedRosters && res.alreadyReleasedRosters.length > 0){
        this.rosterReleaseResponse = res;
        this.spinner.hide();
        this.bsModalRef = this.modalService.show(this.releaseRosterErrorTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }else{
        this.spinner.hide();
        this.iziToast.success({
          title: 'Roster Released', message: 'Roster Released Successful!', position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.loadData();
      }

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  @ViewChild('returnToHomeRosterErrorTemplate')
  returnToHomeRosterErrorTemplate: any;
  rosterReturnToHomeResponse: RosterReturnToHomeResponseModel;

  markRosterAsReturnToHomeEvent() {
    this.spinner.show();
    this.rosterReleaseResponse = null;
    let rosterReleaseReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      rosterReleaseReq.push(tempRoster);
    }
    this.rosterService.checkForRosterReturnToHome(rosterReleaseReq).subscribe((res)=>{
      if(res && res.alreadyReturnToHomeRosters && res.alreadyReturnToHomeRosters.length > 0){
        this.rosterReturnToHomeResponse = res;
        this.spinner.hide();
        this.bsModalRef = this.modalService.show(this.returnToHomeRosterErrorTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }else{
        this.spinner.hide();
        this.iziToast.question({
          close: false,
          closeOnEscape: true,
          overlay: true,
          timeout: 0,
          id: 'question',
          title: "Do you really want to mark the Roster(s) as 'Return to home' ? ",
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', (instance, toast) => {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
              this.markRosterAsReturnToHomeConfirm();
            }, true],
            ['<button>NO</button>', (instance, toast) => {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

            }],
          ]
        });
      }
    },(err)=>{
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
/*
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: "Do you really want to mark the Roster(s) as 'Return to home' ? ",
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.markRosterAsReturnToHomeConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });*/
  }

  markRosterAsReturnToHomeConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    let rosterReturnToHomeReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      tempRoster.CompanyEventId = tr.CompanyEventId;
      rosterReturnToHomeReq.push(tempRoster);
    }
    this.rosterService.markRosterAsReturnToHome(rosterReturnToHomeReq).subscribe((res) => {
      console.log(res);
      if(res && res.alreadyReturnToHomeRosters && res.alreadyReturnToHomeRosters.length > 0){
        this.rosterReturnToHomeResponse = res;
        this.spinner.hide();
        this.bsModalRef = this.modalService.show(this.returnToHomeRosterErrorTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }else{
        this.spinner.hide();
        this.iziToast.success({
          title: 'Roster Updated', message: 'Marked as "Return to Home" Successful!', position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.loadData();
      }

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

    /*this.spinner.show();
    /!*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*!/
    let rosterReleaseReq:RosterModel[] = [];
    for(let tr of this.selected){
      let tempRoster = new RosterModel();
      tempRoster.RosterId = tr.RosterId;
      rosterReleaseReq.push(tempRoster);
    }
    this.rosterService.markRosterAsReturnToHome(rosterReleaseReq).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({
        title: 'Roster Updated', message: 'Marked as "Return to Home" Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });*/
  }


  editRoster() {

    this.bsModalRef = this.modalService.show(ModifyRosterDetailsPageComponent, {
      class: 'modal-dialog-centered',
      initialState: {
        isEditEvent: true,
        pageStatus: this.pageStatus,
        rosterId: this.selected[0].RosterId,
        rosterName: this.selected[0].RosterName,
        status: this.selected[0].Status
      },
      backdrop: 'static'
    });
  }

  assignRoster() {
    this.checkActiveCompanyEventsAndLoad();
  }

  /*pullRoster() {
    this.checkCompanyEventInvitesAndLoad();
  }*/


  checkActiveCompanyEventsAndLoad() {
    this.spinner.show();
    this.companyEventService.fetchAllActiveCompanyEvents(this.selected[0].MasterEventId).subscribe((res) => {
      console.log(res);
      if (res) {

        let rostersToAssign:RosterModel[] = [];
        for(let tr of this.selected){
          let tempRoster = new RosterModel();
          tempRoster.RosterId = tr.RosterId;
          rostersToAssign.push(tempRoster);
        }

        this.spinner.hide();
        this.bsModalRef = this.modalService.show(AssignRosterToCompanyEventPageComponent, {
          class: 'modal-dialog-centered',
          initialState: {
            rostersToAssign: rostersToAssign,
            masterEventId: this.selected[0].MasterEventId,
            isAssignRoster: true,
          },
          backdrop: 'static'
        });
      } else {
        this.spinner.hide();
        this.iziToast.warning({
          title: 'No Company Events Available for the Master Event.',
          message: 'No Company Events Available to assign Roster to it.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  /*checkCompanyEventInvitesAndLoad() {
    this.spinner.show();
    this.companyEventService.fetchAllCompanyEventInvites(this.selected[0].MasterEventId).subscribe((res) => {
      console.log(res);
      if (res) {
        this.spinner.hide();
        this.bsModalRef = this.modalService.show(AssignRosterToCompanyEventPageComponent, {
          class: 'modal-dialog-centered',
          initialState: {
            rosterId: this.selected[0].RosterId,
            masterEventId: this.selected[0].MasterEventId,
            isAssignRoster: true,
          },
          backdrop: 'static'
        });
      } else {
        this.spinner.hide();
        this.iziToast.warning({
          title: 'No Company Events Invites Available for the Master Event.',
          message: 'No Company Events Available Invites to assign Roster to it.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }*/

  prepareCompanyEventName(companyEventId: number) {
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }

  prepareRosterName(rosterId: number) {
    return this.commonUtil.prepareRosterName(rosterId);
  }
}
