import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';

export class ProfileModel {
  FirstName: string;
  LastName: string;
  BusinessName: string;
  eMail_ID: string;
  FullName: string;
  PhoneNumberMask: string;
  PhoneNumber: string;
  Password: string;
  ConfirmPassword: string;
  CompanyName: string;
  LastUpdated: string;
  EmailAlerts: boolean;
  SMSAlerts: boolean;

  CompanyId: number;
  UserId: number;
  UserRole: number;
  Status: number;
  StatusValue:string;
  RoleName:string;
  sendEmailAlert: boolean;
  sendSMSAlert: boolean;

  DF01: string;
  DF02: string;
  DF03: string;
  DF04: string;
  DF05: string;
  DF06: string;
  DF07: string;
  DF08: string;
  DF09: string;
  DF10: string;

  validate(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    //Name
   // validator.requiredString(error, 'FirstName', this.FirstName, 'First Name');
    validator.validateName(error, 'FirstName', this.FirstName,   'First Name');
   // validator.requiredString(error, 'LastName', this.LastName, 'Last Name');
    validator.requiredString(error, 'BusinessName', this.BusinessName, 'Business Name');
    validator.validateName(error, 'LastName', this.LastName,   'Last Name');
    validator.requiredNumber(error, 'CompanyId', this.CompanyId,  'AEIC Company');
    //EMAIL
    validator.validateEmail(error, 'eMail_ID', this.eMail_ID, 'Email');
    //Mobile number
    validator.validateMobileNumber(error, 'PhoneNumber', this.PhoneNumber, 'Mobile number');
    //Password
    validator.strongPasswordValidator(error, 'Password', this.Password, 'Password');
    //Confirm Password
    validator.validatePasswordMatch(error, 'ConfirmPassword', this.ConfirmPassword, this.Password, 'Confirm Password');
    return error;
  }

  validateProfile(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    //Name
    //validator.requiredString(error, 'FirstName', this.FirstName, 'First Name');
    validator.validateName(error, 'FirstName', this.FirstName,   'First Name');
    validator.validateName(error, 'LastName', this.LastName, 'Last Name');
   // validator.requiredString(error, 'BusinessName', this.BusinessName, 'Business Name');
    //validator.requiredStringLength(error, 'LastName', this.LastName, 3, 'Last Name');
    //Mobile number
    validator.validateMobileNumber(error, 'PhoneNumber', this.PhoneNumber, 'Mobile number');
    return error;
  }

  validateAddUser(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    //Name
    validator.validateName(error, 'FirstName', this.FirstName, 'First Name');
    //validator.requiredStringLength(error, 'FirstName', this.FirstName, 3, 'First Name');
    validator.validateName(error, 'LastName', this.LastName, 'Last Name');
    //validator.requiredStringLength(error, 'LastName', this.LastName, 3, 'Last Name');
    validator.requiredString(error, 'BusinessName', this.BusinessName, 'Business Name');

    validator.validateEmail(error, 'eMail_ID', this.eMail_ID, 'Email');
    //Mobile number
    validator.validateMobileNumber(error, 'PhoneNumber', this.PhoneNumber, 'Mobile number');

    validator.requiredNumber(error, 'CompanyId', this.CompanyId, 'Company');
    validator.requiredNumber(error, 'UserRole', this.UserRole, 'User Role');
    validator.requiredNumber(error, 'Status', this.Status, 'Status');


    return error;
  }
  validateOnUpdateUser(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    //Name
    validator.validateName(error, 'FirstName', this.FirstName, 'First Name');
    //validator.requiredStringLength(error, 'FirstName', this.FirstName, 3, 'First Name');
    validator.validateName(error, 'LastName', this.LastName, 'Last Name');
    validator.requiredString(error, 'BusinessName', this.BusinessName, 'Business Name');
   // validator.requiredStringLength(error, 'LastName', this.LastName, 3, 'Last Name');
    //Mobile number
    validator.validateMobileNumber(error, 'PhoneNumber', this.PhoneNumber, 'Mobile number');

    validator.requiredNumber(error, 'CompanyId', this.CompanyId, 'Company');
    validator.requiredNumber(error, 'UserRole', this.UserRole, 'User Role');
    validator.requiredNumber(error, 'Status', this.Status, 'Status');


    return error;
  }

  mapProfileFields(profile){
    this.FirstName = profile['FirstName'];
    this.UserId = profile['UserId'];
    this.LastName = profile['LastName'];
    this.BusinessName = profile['BusinessName'];
    this.PhoneNumber = profile['PhoneNumber'];
    this.EmailAlerts = profile['EmailAlerts'];
    this.SMSAlerts = profile['SMSAlerts'];
    this.CompanyName = profile['CompanyName'];
    this.StatusValue = profile['StatusValue'];
    this.RoleName = profile['RoleName'];
    this.eMail_ID = profile['eMail_ID'];
    this.LastUpdated = profile['LastUpdated'];


    this.DF01 = profile['DF01'];
    this.DF02 = profile['DF02'];
    this.DF03 = profile['DF03'];
    this.DF04 = profile['DF04'];
    this.DF05 = profile['DF05'];
    this.DF06 = profile['DF06'];
    this.DF07 = profile['DF07'];
    this.DF08 = profile['DF08'];
    this.DF09 = profile['DF09'];
    this.DF10 = profile['DF10'];
  }
}
