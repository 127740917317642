import { Component, OnInit } from '@angular/core';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {BsModalService} from 'ngx-bootstrap';
import {CommonUtil} from '../../util/common-util';
import {Location} from '@angular/common';
import {FieldResourceService} from '../../providers/field-resource-service';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {FieldResourceAuthModel} from '../../model/field-resource-auth-model';

@Component({
  selector: 'app-field-resource-login-page',
  templateUrl: './field-resource-login-page.component.html',
  styleUrls: ['./field-resource-login-page.component.css']
})
export class FieldResourceLoginPageComponent implements OnInit {

  constructor(private handleReqErr: HandleRequestError,private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
              private router: Router, private location: Location, private fieldResourceService: FieldResourceService,
              public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService) {

  }

  authToken: string;
  frDetails: FieldResourceAuthModel;

  ngOnInit() {
    this.route.params.subscribe( (params) => {
      this.authToken = params['authToken'];
      this.loadData();
    });
  }

  loadData(){
  let infoToast = this.iziToast.info({
      title: 'Login In Progress!',
      message: 'Please wait.',
      position: 'center',
      timeout: 0,
      class:'LoginProgressClass',
      close: false,
      overlay: true
    });
    this.fieldResourceService.login(this.authToken).subscribe((res)=>{
      this.iziToast.destroy();
      this.frDetails = res;
      this.fieldResourceService.setFRUserToken(this.frDetails);
      this.iziToast.success({
        title: 'Login Successful!', message: 'You logged in successfully.', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.router.navigate(['fieldResource','tickets']);
      console.log(res);
    }, (err)=>{
      this.iziToast.destroy();
      this.iziToast.error({
        title: 'Login Failed!', message: 'Invalid Token Or Token Expired, please check you e-mail for recent login link and try again.', position: 'center',
        timeout: 0,
        close: false,
        overlay: true
      });
      console.log(err);
    });
  }

}
