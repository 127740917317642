import {Component, ElementRef, OnInit, Optional, ViewChild} from '@angular/core';
import {RosterModel} from '../../model/roster-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TicketService} from '../../providers/ticket-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {Location} from '@angular/common';
import {FileUploader} from 'ng2-file-upload';
import {TicketModel} from '../../model/ticket-model';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CompanyEventModel} from '../../model/company-event-model';

@Component({
  selector: 'app-create-multiple-tickets-page',
  templateUrl: './create-multiple-tickets-page.component.html',
  styleUrls: ['./create-multiple-tickets-page.component.css']
})
export class CreateMultipleTicketsPageComponent implements OnInit {

  rosters: RosterModel[];
  rostersRows: RosterModel[];
  adminCompanyId: number;
  adminCompanyName: string;
  pageStatus: string;
  userRole: number;

  selectedRoster: RosterModel;
  selectedRosterId: number;

  createTicketStep: number = 1;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private companyEventService: CompanyEventsService, private ticketService: TicketService,
              private location: Location,private router: Router,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }


  ngOnInit() {
    this.loadData();
  }
  showWACSelection = false;
  checkAndEnableRoleBasedOption(){
    if(this.userRole === environment.userRoles.SuperAdministrator
      || this.userRole === environment.userRoles.Administrator
      || this.userRole === environment.userRoles.TicketAssignmentCoordinator
    ){
      this.showWACSelection = true;
    }
  }

  loadData() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkAndEnableRoleBasedOption();
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllRostersForCreateTicket());

    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });


  }

  fetchAllRostersForCreateTicket() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllRostersForCreateTicket().subscribe((res) => {
        console.log(res);
        if (res) {
          this.rosters = res;
          this.prepareRosters();
          this.prepareRostersRows();
        } else {
          this.rosters = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  wacUsers: ProfileModel[];
  selectedWACUserId: number;

  fetchAllWACOfRespondingCompany() {
    if(!this.showWACSelection){
      return;
    }
    this.spinner.show();
    this.ticketService.fetchAllActiveWACUsersOfRespondingCompany(this.selectedRoster.RespondingCompanyID).subscribe((res) => {
      console.log(res);

      if (res) {
        this.wacUsers = res;
        this.prepareWACUsers();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.wacUsers = [];
        this.iziToast.warning({
          title: 'No WAC found.',
          message: 'No WAC found for the selected Responding Company.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }
  workLocation:string;

  fetchCompanyEventAndAllWACOfRespondingCompany() {
    if(!this.showWACSelection){
      return;
    }
    this.spinner.show();
    this.fetchCompanyEvents().then(()=>{
      this.ticketService.fetchAllActiveWACUsersOfRespondingCompany(this.selectedRoster.RespondingCompanyID).subscribe((res) => {
        console.log(res);

        if (res) {
          this.wacUsers = res;
          this.prepareWACUsers();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.wacUsers = [];
          this.iziToast.warning({
            title: 'No WAC found.',
            message: 'No WAC found for the selected Responding Company.',
            position: 'center',
            timeout: 5000,
            close: true,
            overlay: false
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(err);
      });
    }).catch((err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }
  companyEvent: CompanyEventModel;

  fetchCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchCompanyEvent(this.selectedRoster.CompanyEventId).subscribe((res) => {
        console.log(res);
        this.companyEvent = new CompanyEventModel();
        this.companyEvent.mapCompanyEvent(res);
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareWACUsers() {
    for (let tu of this.wacUsers) {
      tu.FullName = tu.FirstName.trim() + ' ' + tu.LastName.trim();
    }
  }

  cancel(){
      this.location.back();
  }

  backToTickets(){
    this.router.navigate(['/home','tickets']);
  }

  prepareRostersRows() {
    this.rostersRows = [];
    for (let r1 of this.rosters) {
      let matchFound = false;
      for (let r2 of this.rostersRows) {
        if (r1.FormattedCompanyEventRosterName === r2.FormattedCompanyEventRosterName) {
          matchFound = true;
          break;
        }
      }
      if (!matchFound) {
        this.rostersRows.push(r1);
      }
    }
  }

  prepareRosters() {
    if (!this.rosters || this.rosters.length <= 0) {
      return;
    }
    for (let ce of this.rosters) {
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
      //ce.FormattedCompanyEventRosterName = ce.EventName + ' - ' + this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
      if(ce.CompanyEventName && ce.CompanyEventName !== 'null'){
        ce.FormattedCompanyEventRosterName = ce.EventName +' - '+ ce.CompanyEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
      }else{
        ce.FormattedCompanyEventRosterName = ce.EventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
      }
    }
  }

  prepareCompanyEventName(companyEventId) {
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }

  onCompanyEventSelected() {
    this.selectedRoster = null;
    this.wacUsers = null;
    for (let tr of this.rostersRows) {
      if (this.selectedRosterId === tr.RosterId) {
        this.selectedRoster = tr;
        this.fetchCompanyEventAndAllWACOfRespondingCompany();
        break;
      }
    }


  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'selectedRosterId', this.selectedRosterId, 'Company Event And Responding Company');
    if (!this.selectedFiles  || this.selectedFiles.length <=0) {
      validator.requiredString(this.validationError, 'selectedFile', null, 'This');
    }
    if (this.validationError && (this.validationError['selectedRosterId'] || this.validationError['selectedFile'])) {
      return;
    }

  }

  public hasAnotherDropZoneOver: boolean = false;
  @ViewChild('uploadFileSelect')
  uploadFileSelect: ElementRef;
  allowedFileTypes = ['pdf','xps','oxps'];
  public uploader: FileUploader = new FileUploader({url: "",allowedFileType: ['pdf','xps','oxps', 'application']});
 // selectedFile: File;
  selectedFiles: File[] = [];
  invalidFiles: File[];
  uploadedPercentage: any;
  uploadProgress: number;
  showUploadProgress: boolean = false;

  checkForFileType(): boolean{
    for(let qf of this.uploader.queue ){
      let f = qf._file;
      let fileExtension = f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length);
      if (this.allowedFileTypes.indexOf(fileExtension) === -1) {
        this.iziToast.error({
          title: 'Invalid file type',
          message: 'Please drop only PDF or XPS file.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: true
        });
        return false;
      }
    }
    return true;
  }

  checkForFileTypeInFileSelector(): boolean{
    for(let qf of this.uploadFileSelect.nativeElement.files ){
      let f = qf._file;
      let fileExtension = f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length);
      if (this.allowedFileTypes.indexOf(fileExtension) === -1) {
        this.iziToast.error({
          title: 'Invalid file type',
          message: 'Please select only PDF or XPS file.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: true
        });
        return false;
      }
    }
    return true;
  }

  public fileOverAnother(e: any): void {
    // this.hasAnotherDropZoneOver = e;
    console.log('File updated');
    this.selectedFiles = [];
    this.invalidFiles = [];

    if (this.uploader.queue.length > 0 && this.uploader.queue.length <= 10) {
      /*if(this.checkForFileType()){
        for(let qf of this.uploader.queue ) {
          this.selectedFiles.push(qf._file);
        }
      }else{
        this.selectedFiles = [];
        this.uploader.clearQueue();
      }*/
      /*for(let qf of this.uploader.queue ) {
        this.selectedFiles.push(qf._file);
      }*/
      for(let qf of this.uploader.queue ) {
        let f = qf._file;
        let fileExtension = f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length);
        if (this.allowedFileTypes.indexOf(fileExtension) === -1) {
          this.invalidFiles.push(f);
        }else{
          this.selectedFiles.push(f);
        }
      }

    }else{
      for(let qf of this.uploader.queue ) {
        this.selectedFiles.push(qf._file);
      }
      if(this.selectedFiles.length > 10){
        this.selectedFiles.splice(10, this.selectedFiles.length);
      }
      if(this.uploader.queue.length > 10){
        this.iziToast.error({
          title: 'More than 10 Files selected',
          message: 'Maximum 10 files are allowed to upload.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: true
        });
      }

      //this.selectedFiles = [];
      //this.uploader.clearQueue();

    }
    for(let f of e){
      let fileExtension = f.name.substring(f.name.lastIndexOf('.') + 1, f.name.length);
      if (this.allowedFileTypes.indexOf(fileExtension) === -1 && this.invalidFiles.indexOf(f) === -1) {
        this.invalidFiles.push(f);
      }
    }
    this.validateFields();
  }
  clearFileQueue(){
    this.selectedFiles = [];
    this.invalidFiles = [];
    this.uploader.clearQueue();
  }

  fileSelected(e){
    // this.hasAnotherDropZoneOver = e;
    /*console.log(this.uploadFileSelect.nativeElement);
    for(let f of e){
      this.uploader.addToQueue(f);
    }*/
    this.fileOverAnother(e);
    //this.selectedFiles = [];
    /*this.invalidFiles = [];
    if (this.uploadFileSelect.nativeElement.files.length > 0 && this.uploadFileSelect.nativeElement.files.length <= 10) {
      if(this.checkForFileTypeInFileSelector()){
        for(let f of this.uploadFileSelect.nativeElement.files ) {
          this.selectedFiles.push(f);
        }
      }else{
        this.selectedFiles = [];
        this.uploadFileSelect.nativeElement.value = '';
      }
    }else{
      this.iziToast.error({
        title: 'More than 10 Files selected',
        message: 'Maximum 10 files are allowed to upload.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFiles = [];
      this.uploadFileSelect.nativeElement.value = '';
    }
    this.validateFields();*/

  }

  getWACCellClass({ row, column, value }): any {
    return {
      'ng-select-cell': true
    };
  }
  multiTickets: TicketModel[];
  multiTicketsRows: TicketModel[];
  documentSrc: any;
  showStepTwo(){
    this.submitAttempted = true;
    this.validateFields();
    if (this.validationError && (this.validationError['selectedRosterId'] || this.validationError['selectedFile'])) {
      return;
    }
    this.multiTickets = [];
    this.multiTicketsRows = [];
    for(let f of this.selectedFiles){
      let mt = new TicketModel();
      mt.file = f;
      mt.fileName = f.name;
      mt.RespondingCompanyId = this.selectedRoster.RespondingCompanyID;
      mt.CompanyEventId = this.selectedRoster.CompanyEventId;
      this.multiTickets.push(mt);
    }
    this.multiTicketsRows.push(...this.multiTickets);
    this.documentSrc = this.multiTickets[0].file;
    this.createTicketStep = 2;
  }

  viewFile(file){
    this.documentSrc = file;
  }

  applyWACToAll(){
    for(let mt of this.multiTickets){
      mt.WAC_Id = this.selectedWACUserId;
    }
    this.multiTicketsRows = [];
    this.multiTicketsRows.push(...this.multiTickets);
  }
  applyWorkLocationToAll(){
    for(let mt of this.multiTickets){
      mt.WorkLocation = this.workLocation;
    }
    this.multiTicketsRows = [];
    this.multiTicketsRows.push(...this.multiTickets);
  }

  removeTicket(row){
    console.log(row);
    console.log(this.multiTickets.indexOf(row));
    this.multiTickets.splice(this.multiTickets.indexOf(row), 1);
    this.multiTicketsRows = [];
    this.multiTicketsRows.push(...this.multiTickets);
  }

  backToStep1(){
    this.multiTickets = [];
    this.createTicketStep = 1;
  }
  createMultipleTickets(){
    console.log(this.multiTickets);
    console.log(this.multiTicketsRows);
    if(!this.multiTicketsRows || this.multiTicketsRows.length <= 0){
      this.iziToast.error({title: 'No Tickets found to Add', message: 'Please add at-least one document to create a ticket!.', position: 'topRight'});
      return;
    }
    window.scroll(0, 0);
    this.spinner.show();
    for(let mt of this.multiTicketsRows){
      mt.dynamicFields = this.companyEvent.existingDynamicFields;
    }
    this.ticketService.createMultipleTicket(this.multiTicketsRows).subscribe((event: HttpEvent<any>) => {

      //this.uploadFileSelect.nativeElement.value = '';

      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.Response:
          this.showUploadProgress = false;
          this.uploadedPercentage = 0;
          this.uploadProgress = 0;
          this.spinner.hide();
          this.iziToast.success({title: 'Ticket added', message: 'Ticket added successfully!.', position: 'topRight'});
          this.multiTickets = [];
          this.multiTicketsRows.push(...this.multiTickets);
          this.backToTickets();
          break;
        case 1: {
          this.showUploadProgress = true;
          if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
            this.uploadedPercentage = event['loaded'] / event['total'] * 100;
            this.uploadProgress = Math.round(this.uploadedPercentage);
          }
          break;
        }

      }
    }, (err) => {
      this.spinner.hide();
      this.showUploadProgress = false;
      this.uploadedPercentage = 0;
      this.uploadProgress = 0;
      console.log(err);
      this.handleReqErr.handlePageLoadError(err);

    });
  }
}
