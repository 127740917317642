import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MasterEventModel} from '../../model/master-event-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterEventService} from '../../providers/master-event-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {RosterCrewDetailsModel} from '../../model/roster-crew-details-model';
import {RosterService} from '../../providers/roster-service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModifyCrewDetailsPageComponent} from '../modify-crew-details-page/modify-crew-details-page.component';
import {RosterApproveRequestModel, RosterApproveResponseModel, RosterModel} from '../../model/roster-model';
import {Subscription} from 'rxjs';
import {CommonUtil} from '../../util/common-util';
import {Location} from '@angular/common';
import {AuthService} from '../../providers/auth-service';
import {environment} from '../../../environments/environment';
import {ValidationError} from '../../validators/validation-error';
import {CompanyEventModel, DynamicField} from '../../model/company-event-model';
import {CompanyEventsService} from '../../providers/company-events-service';

@Component({
  selector: 'app-roster-details-page',
  templateUrl: './roster-details-page.component.html',
  styleUrls: ['./roster-details-page.component.css']
})
export class RosterDetailsPageComponent implements OnInit, OnDestroy {

  moduleStatus = environment.moduleStatus;
  companyEvent: CompanyEventModel;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
              private router: Router, private location: Location, private companyEventService: CompanyEventsService
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();

  showApproveRosterButton: boolean = false;
  showUpdateDynamicFieldButton: boolean = false;
  showUpdateWorkLocationButton: boolean = false;

  roster: RosterModel;
  viewDynamicFields: DynamicField[];
  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  loadData() {
    this.showUpdateDynamicFieldButton = false;
    this.showUpdateWorkLocationButton = false;
    this.showApproveRosterButton = false;

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchRosterDetails());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  fetchRosterDetails() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
        console.log(res);
        this.roster = res;
       // this.viewDynamicFields = [];
        //this.viewDynamicFields = [...this.roster.dynamicFields];
        if (this.roster.Status === this.moduleStatus.RosterInitiated
          && this.roster.RequestingCompanyId === this.adminCompanyId
          && this.roster.CompanyEventStatus !== environment.moduleStatus.CompanyEventClosed
        ) {
          this.showApproveRosterButton = true;
        }
        if(this.roster.RequestingCompanyId === this.adminCompanyId && this.roster.Status === this.moduleStatus.RosterApproved
          && this.roster.CompanyEventStatus !== environment.moduleStatus.CompanyEventClosed){
          this.showUpdateDynamicFieldButton = true;
        }
        if(this.roster.RequestingCompanyId === this.adminCompanyId && this.roster.Status === this.moduleStatus.RosterApproved
          && this.roster.CompanyEventStatus !== environment.moduleStatus.CompanyEventClosed){
          this.showUpdateWorkLocationButton = true;
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  ngOnDestroy(): void { //fromCE
  }

  fromCE: string;
  rosterId: number;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.fromCE = params['fromCE'];
      this.rosterId = params['rosterId'];
      this.loadData();
    });
  }

  prepareRosterName(rosterId: number) {
    return this.commonUtil.prepareRosterName(rosterId);
  }

  backToList() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  fetchCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchCompanyEvent(this.roster.CompanyEventId).subscribe((res) => {
        console.log(res);
        this.companyEvent = new CompanyEventModel();
        this.companyEvent.mapCompanyEvent(res);
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  @ViewChild('approveRosterTemplate')
  approveRosterTemplate: any;
  bsModalRef: BsModalRef;
  rosterApproveResponse: RosterApproveResponseModel;
  rosterAlreadyApproved: boolean;
  workLocation:string;

  approveRosterEvent() {
    this.workLocation = '';
    this.spinner.show();
    this.rosterApproveResponse = null;
    this.rosterAlreadyApproved = false;
    this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
      console.log(res);
      this.roster = res;
      if (this.roster.Status === this.moduleStatus.RosterInitiated
        && this.roster.RequestingCompanyId === this.adminCompanyId
        && this.roster.CompanyEventStatus !== environment.moduleStatus.CompanyEventClosed
      ) {
        this.showApproveRosterButton = true;
        this.fetchCompanyEvents().then((r) => {
          this.spinner.hide();

          this.bsModalRef = this.modalService.show(this.approveRosterTemplate, {
            class: 'modal-dialog-centered modal-lg',
            initialState: {
              companyEvent: null,
              showCompanyEventSelect: true
            },
            backdrop: 'static'
          });
        }).catch((err) => {
          this.spinner.hide();
          this.handleReqErr.handlePageLoadError(err);
        });
      } else {
        this.spinner.hide();
        this.iziToast.question({
          title: 'Roster already approved', message: 'Roster already approved, page is refreshed with current status!', position: 'center',
          close: false,
          overlay: true,
          buttons: [
            ['<button>Ok</button>', (instance, toast) => {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

            }],
          ]
        });
        this.loadData();
      }

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }

  @ViewChild('updateRosterTemplate')
  updateRosterTemplate: any;

  updateRosterFields() {
    this.spinner.show();
    this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
      console.log(res);
      this.roster = res;
      this.fetchCompanyEvents().then((r) => {
        this.spinner.hide();

        this.bsModalRef = this.modalService.show(this.updateRosterTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }).catch((err) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(err);
      });

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }

  @ViewChild('updateWorkLocationTemplate')
  updateWorkLocationTemplate: any;

  updateWorkLocation() {
    this.spinner.show();
    this.workLocation = null;
    this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
      console.log(res);
      this.roster = res;
      this.fetchCompanyEvents().then((r) => {
        this.spinner.hide();
        this.workLocation = this.roster.WorkLocation;
        this.bsModalRef = this.modalService.show(this.updateWorkLocationTemplate, {
          class: 'modal-dialog-centered modal-lg',
          initialState: {
            companyEvent: null,
            showCompanyEventSelect: true
          },
          backdrop: 'static'
        });
      }).catch((err) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(err);
      });

    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }

  onApproveConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    this.rosterApproveResponse = null;
    this.rosterAlreadyApproved = false;
    let rosterApproveReq:RosterModel[] = [];
    let tempRoster = new RosterModel();
    tempRoster.RosterId = this.roster.RosterId;
    tempRoster.CompanyEventId = this.roster.CompanyEventId;
    tempRoster.WorkLocation = this.workLocation;

    rosterApproveReq.push(tempRoster);
    this.rosterService.approveRoster(rosterApproveReq).subscribe((res) => {
      console.log(res);
      this.rosterApproveResponse = res;
      if(this.rosterApproveResponse.rostersApproved){
        this.spinner.hide();
        this.bsModalRef.hide();
        this.iziToast.success({
          title: 'Roster Approved', message: 'Roster Approved Successful!', position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        this.loadData();
      }else{
        this.rosterAlreadyApproved = true;
        this.spinner.hide();
        this.loadData();
      }
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }


  updateRosterFieldsonConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    let rosterApproveReq = new RosterApproveRequestModel();
    rosterApproveReq.RosterId = this.roster.RosterId;
    rosterApproveReq.CompanyEventId = this.roster.CompanyEventId;
    rosterApproveReq.dynamicFields = this.roster.dynamicFields;

    this.rosterService.updateRosterDynamicFields(rosterApproveReq).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.bsModalRef.hide();
      //this.loadData();
      this.iziToast.success({
        title: 'Roster Dynamic Fields updated', message: 'Updated Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  updateWorkLocationOnConfirm() {
    this.spinner.show();
    /*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*/
    let tr = new RosterModel();
    tr.RosterId = this.roster.RosterId;
    tr.WorkLocation = this.workLocation;

    this.rosterService.updateRosterWorkLocation(tr).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.bsModalRef.hide();
      //this.loadData();
      this.iziToast.success({
        title: 'Roster Work Location updated', message: 'Updated Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  closeApproveDialog() {
    this.bsModalRef.hide();
  }

  prepareCompanyEventName(companyEventId: number) {
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }


}
