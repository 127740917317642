import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProfileModel} from '../model/profile-model';
import {environment} from '../../environments/environment';
import {AuthService} from './auth-service';
import {ServiceResponse} from '../model/service-response';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';


@Injectable()
export class UserManagementService {

  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  getUserDetailsById(UserId: number, CompanyId: number): Observable<ProfileModel> {
    return this.http.post<ProfileModel>(this.environment.serviceHostUrl + 'userMgmt/getUserDetailsById', {
      UserId: UserId,
      CompanyId: CompanyId
    }, this.authService.prepareOptions());
  }

  getAllUsers(): Observable<ProfileModel[]> {
    return this.http.post<ProfileModel[]>(this.environment.serviceHostUrl + 'userMgmt/getAllUsers', {}, this.authService.prepareOptions());
  }

  addUser(user: ProfileModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'userMgmt/addUser', user, this.authService.prepareOptions());
  }

  updateUser(user: ProfileModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'userMgmt/updateUser', user, this.authService.prepareOptions());
  }


  updateUserStatus(UserIds: string, Status: number): Observable<ProfileModel> {
    return this.http.post<ProfileModel>(this.environment.serviceHostUrl + 'userMgmt/updateUserStatus', {
      UserIds: UserIds,
      Status: Status
    }, this.authService.prepareOptions());
  }

  uploadUsers(file: File, companyId: number): Observable<any>{
    let input = new FormData();
    input.append('CompanyUsers', file);
    input.append('CompanyId', ''+companyId);
    let options = this.authService.prepareOptions();
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(this.environment.serviceHostUrl + 'userMgmt/uploadUsers', input, options);
  }
}
