import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';
import {DynamicField} from './company-event-model';

export class TicketModel {
  TicketId : number;
  RespondingCompanyId : number;
  RequestingCompanyId : number;
  WAC_Id : number;
  Status : number;
  CreatedDate : string;
  UpdatedDate : string;
  CreatedBy : number;
  UpdatedBy : number;
  DF01 : string;
  DF02 : string;
  DF03 : string;
  DF04 : string;
  DF05 : string;
  DF06 : string;
  DF07 : string;
  DF08 : string;
  DF09 : string;
  DF10 : string;
  TicketDescription : string;
  CompanyEventId : number;
  CompanyEventName: string;
  CompanyEventStatus: number;
  RespondingCompanyName : string;
  RequestingCompanyName : string;
  CreatedByName : string;
  UpdatedByName : string;
  WACName : string;
  StatusValue : string;
  MasterEventId : number;
  EventName : string;
  Comment : string;
  WorkLocation : string;

  file: File;
  fileName: string;
  CreatedDateTxt: string;
  UpdatedDateTxt: string;

  Closed:boolean;
  Deleted:boolean;
  DeleteReason:string;
  dynamicFields: DynamicField[];

  validateTACInsert = function () {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'WAC_Id', this.WAC_Id, 'WAC');
    validator.requiredNumber(error, 'CompanyEventId', this.CompanyEventId, 'Company Event');
    validator.requiredNumber(error, 'RespondingCompanyId', this.RespondingCompanyId, 'Responding Company');
    //validator.requiredString(error, 'TicketDescription', this.TicketDescription, 'Ticket Description');
    return error;
  };

  validateSupportInsert = function () {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'CompanyEventId', this.CompanyEventId, 'Company Event');
    validator.requiredNumber(error, 'RespondingCompanyId', this.RespondingCompanyId, 'Responding Company');
    return error;
  };

  validateTACUpdate = function () {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'TicketId', this.TicketId, 'TicketId');
    validator.requiredNumber(error, 'WAC_Id', this.WAC_Id, 'WAC');
    validator.requiredNumber(error, 'Status', this.Status, 'Status');
    validator.requiredNumber(error, 'CompanyEventId', this.CompanyEventId, 'Company Event');
    validator.requiredNumber(error, 'RespondingCompanyId', this.RespondingCompanyId, 'Responding Company');
    //validator.requiredString(error, 'TicketDescription', this.TicketDescription, 'Ticket Description');
    return error;
  };

  validateSupportUpdate = function () {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'TicketId', this.TicketId, 'TicketId');
    validator.requiredNumber(error, 'CompanyEventId', this.CompanyEventId, 'Company Event');
    validator.requiredNumber(error, 'RespondingCompanyId', this.RespondingCompanyId, 'Responding Company');
    return error;
  };

  mapFields(ticket){
    this.TicketId =  ticket.TicketId;
    this.RespondingCompanyId =  ticket.RespondingCompanyId;
    this.RespondingCompanyName =  ticket.RespondingCompanyName;
    this.RequestingCompanyName =  ticket.RequestingCompanyName;
    this.WAC_Id =  ticket.WAC_Id;
    this.Status =  ticket.Status;
    this.TicketDescription =  ticket.TicketDescription;
    this.CompanyEventId =  ticket.CompanyEventId;
    this.DF01 =  ticket.DF01;
    this.DF02 =  ticket.DF02;
    this.DF03 =  ticket.DF03;
    this.DF04 =  ticket.DF04;
    this.DF05 =  ticket.DF05;
    this.DF06 =  ticket.DF06;
    this.DF07 =  ticket.DF07;
    this.DF07 =  ticket.DF07;
    this.DF08 =  ticket.DF08;
    this.DF09 =  ticket.DF09;
    this.DF10 =  ticket.DF10;
    this.Closed =  ticket.Closed;
    this.Deleted =  ticket.Deleted;
    this.DeleteReason =  ticket.DeleteReason;
  }
}

export class TicketDocument {
  TicketId : number;
  TicketDocumentId : number;
  OriginalFileName : string;
  UploadedAt : string;
  UploadedByName : string;
}

export class TicketCrewModel {
  TicketCrewId: number;
  TicketId: number;
  PrimaryCrew: boolean;
  CrewLead: boolean;
  TeamLead: boolean;
  PrimaryCrewSelectedValue: string;
  RosterId: number;
  RosterCrewDetailsId: number;
  CrewAssignedTicketCount: number;
  CrewName: string;
  ResourceType : string;
  CrewId: string;
  WorkLocation: string;

  //For Reporting
  PersonnelId:string;
  TeamType:string;
  CellPhone:string;
  Email:string;
  Gender:string;
  DepartureCity:string;
  DepartureState:string;
  Status:boolean;
  RosterName:string;
  CompanyEventName:string;
  CompanyEventId:number;

}

export class TicketWorkLocationModel {
  WorkLocation: string;
}
export class TicketWACModel {
  FirstName: string;
  LastName: string;
  DisplayName: string;
  UserId: number;
}
/*export class MultiTicketsModel {
  file: File;
  fileName: string;
  wacId: number;
  RespondingCompanyId: number;
  CompanyEventId: number;
  TicketDescription: number;
}*/


export class TicketSearchModel{
  TicketId: string;
  MasterEventIds: number[] = [];
  WACIds: number[] = [];
  WorkLocations: string[] = [];
  CompanyEventIds: number[] = [];
  TicketStatus: number[] = [];
  RespondingCompanyIds: number[] = [];
  IsDeleted: boolean = false;
  IsClosed: boolean = false;
  dynamicFields: DynamicField[] = [];
}

export class TicketDeleteCloseRequestModel{
  ticketIds: number[];
  deleteReason: string;
}
