import {Injectable} from "@angular/core";


@Injectable()
export class CommonUtil {

  prepareCompanyEventName(companyEventId: number){
    let tceid = ''+companyEventId;
    let ceSuffix = '000000';
    if(tceid.length > ceSuffix.length){
      let cename = 'CE'+tceid;
      return cename;
    }
    let cename = 'CE'+ceSuffix.substring(0, ceSuffix.length-tceid.length)+tceid;
    return cename;
  }

  prepareRosterName(rosterId: number){
    let t = ''+rosterId;
    let suffix = '000000';
    if(t.length > suffix.length){
      let rname = 'R'+t;
      return rname;
    }
    let rname = 'R'+suffix.substring(0, suffix.length-t.length)+t;
    return rname;
  }

  prepareTicketName(ticketId: number){
    let t = ''+ticketId;
    let suffix = '000000';
    if(t.length > suffix.length){
      let rname = 'T'+t;
      return rname;
    }
    let rname = 'T'+suffix.substring(0, suffix.length-t.length)+t;
    return rname;
  }
}
