import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material';
import {CommonService} from '../../providers/common-service';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../providers/auth-service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService, private commonService: CommonService) {
  }

  drawerMode: string = 'side';
  hasBackdrop: boolean = false;
  navigationEndURL: string;

  @ViewChild(MatDrawer)
  drawer: MatDrawer;

  showUserManagement: boolean = false;
  showCompanyManagement: boolean = false;
  showMasterEvents: boolean = false;
  showCompanyEvents: boolean = false;
  showRosters: boolean = false;
  showParkingLot: boolean = false;
  showTicketing: boolean = false;
  showReports: boolean = false;

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  checkRoleAndEnablePages() {
    if(this.userRole === environment.userRoles.SuperAdministrator){
      this.showUserManagement = true;
      this.showCompanyManagement = true;
      this.showMasterEvents = true;
      this.showCompanyEvents = true;
      this.showRosters = true;
      this.showParkingLot = true;
      this.showTicketing = true;
      this.showReports = true;
    }else if(this.userRole === environment.userRoles.Administrator){
      this.showUserManagement = true;
      this.showMasterEvents = true;
      this.showCompanyEvents = true;
      this.showRosters = true;
      this.showParkingLot = true;
      this.showTicketing = true;
      this.showReports = true;
    }else if(this.userRole === environment.userRoles.ResourceUnitLead){
      this.showMasterEvents = true;
      this.showCompanyEvents = true;
      this.showRosters = true;
      this.showParkingLot = true;
      this.showReports = true;
      this.showTicketing = true;
    }else if(this.userRole === environment.userRoles.OnboardingResourceCoordinator){
      this.showMasterEvents = true;
      this.showCompanyEvents = true;
      this.showRosters = true;
      this.showParkingLot = true;
      this.showReports = true;
    }else if(this.userRole === environment.userRoles.TicketAssignmentCoordinator){
      this.showTicketing = true;
      this.showCompanyEvents = true;
      this.showMasterEvents = true;
    }else if(this.userRole === environment.userRoles.SupportPersonnel){
      this.showTicketing = true;
      this.showCompanyEvents = true;
      this.showMasterEvents = true;
    }else if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
      this.showTicketing = true;
      this.showCompanyEvents = true;
      this.showMasterEvents = true;
    }
    //TODO Field Resource
  }


  ngOnInit() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkRoleAndEnablePages();

    console.log(this.drawer);
    this.drawer.open();
    this.showOrHideDrawer();
    this.commonService.toggleEvent.subscribe((v) => {
      console.log(this.drawer);
      if (this.drawer.opened) {
        this.drawer.close();
      } else {
        this.drawer.open();
      }
     /* try{
        window.dispatchEvent(new Event('resize'));
      }catch (e) {
        console.log(e)
      }*/
    });


    /* this.router.events.subscribe(
       (event: any) => {
         if (event instanceof NavigationEnd) {
           console.log('this.router.url', this.router.url);
           this.navigationEndURL = this.router.url;
           if(this.router.url === '/home'){
             this.openRoleDefaultPage();
           }
         }
       }
     );*/
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showOrHideDrawer();
  }

  showOrHideDrawer() {
    if (document.documentElement.clientWidth < 1367) {
      this.drawer.close();
      this.drawerMode = 'over';
      this.hasBackdrop = true;
    } else {
      this.drawer.open();
      this.drawerMode = 'side';
      this.hasBackdrop = false;
    }
  }

  closeDrawer() {
    if (this.hasBackdrop) {
      this.drawer.close();
    }

  }

}
