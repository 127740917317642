import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MasterEventModel} from '../../model/master-event-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {MasterEventService} from '../../providers/master-event-service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../providers/auth-service';

@Component({
  selector: 'app-master-events-page',
  templateUrl: './master-events-page.component.html',
  styleUrls: ['./master-events-page.component.css']
})
export class MasterEventsPageComponent implements OnInit, OnDestroy {

  pageLoadSubscription: Subscription;

  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }

  masterEvents: MasterEventModel[];
  masterEventsRows: MasterEventModel[];
  selected: MasterEventModel[] = [];

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  showModifyFunctionality: boolean = false;

  checkAndEnableModifyOption() {
    if (this.userRole === environment.userRoles.SuperAdministrator || this.userRole === environment.userRoles.Administrator) {
      this.showModifyFunctionality = true;
    } else {
      this.showModifyFunctionality = false;
    }
  }

  bsModalRef: BsModalRef;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService,
              private masterEventService: MasterEventService, private handleReqErr: HandleRequestError,
              private commonService: CommonService, private modalService: BsModalService, private authService: AuthService,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  loadData() {
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkAndEnableModifyOption();
    this.disableEditBtn = true;
    this.disableDeleteBtn = true;
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllMasterEvents());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  ngOnInit() {


    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }


  fetchAllMasterEvents() {
    return new Promise(((resolve, reject) => {
      this.masterEventService.fetchAllMasterEvents().subscribe((res) => {
        console.log(res);
        if (res) {
          this.masterEvents = res;
          this.masterEventsRows = [...this.masterEvents];
        } else {
          this.masterEvents = [];
          this.masterEventsRows = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  disableDeleteBtn = true;
  disableEditBtn = true;
  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.masterEvents.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.masterEventsRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableEditBtn = true;
    this.disableDeleteBtn = true;
  }


  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.EventName, val) || this.matchContent(d.EventType, val) ||
      this.matchContent(d.CreatedDate, val) || this.matchContent(d.UpdatedByName, val) ||
      this.matchContent(d.StatusValue, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.masterEvents) {
      if (this.selected.length === this.masterEvents.length) {
        this.iziToast.warning({
          title: 'All Master Events selected',
          message: 'You have selected all the Master Events. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    /*let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      if (sel.Status === 1 && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      if (sel.Status === 2 && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }*/

    if (this.selected.length === 1) {
      if ((this.userRole === environment.userRoles.SuperAdministrator || this.userRole === environment.userRoles.Administrator)
        && this.selected[0].Status !== environment.moduleStatus.MasterEventClosed) {
        this.disableEditBtn = false;
      } else {
        this.disableEditBtn = true;
      }
    } else {
      this.disableEditBtn = true;
    }

    /* if (this.selected.length > 0) {
       this.disableActivateBtn = !allInActiveUsers;
       this.disableInActivateBtn = !allActiveUsers;
     } else {
       this.disableActivateBtn = true;
       this.disableInActivateBtn = true;
     }*/

    let enableDeleteButton = true;
    for (let sel of this.selected) {
      if (sel.CompanyEventCounts > 1) {
        enableDeleteButton = false;
        break;
      }
    }
    if (this.selected.length > 0) {
      this.disableDeleteBtn = !enableDeleteButton;
    } else {
      this.disableDeleteBtn = true;
    }

  }

  getUserStatusClass({row, column, value}): any {
    return {
      'user-active': value === 'Active',
      'user-in-active': value === 'In-Active'
    };
  }

  deleteMasterEvent() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Delete the Master Event? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onDeleteConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onDeleteConfirm() {
    this.spinner.show();
    let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }
    this.masterEventService.deleteMasterEvent(eventIds.join(',')).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({
        title: 'Master Event Deleted', message: 'Master Event deleted Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  addMasterEvent() {
    this.bsModalRef = this.modalService.show(ModifyMasterEventPageComponent, {
      class: 'modal-dialog-centered',
      initialState: {isEditEvent: false},
      backdrop: 'static'
    });
  }


  editMasterEvent() {
    this.bsModalRef = this.modalService.show(ModifyMasterEventPageComponent, {
      class: 'modal-dialog-centered',
      initialState: {isEditEvent: true, masterEventID: this.selected[0].EventId},
      backdrop: 'static'
    });
  }
}
