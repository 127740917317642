import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {TicketService} from '../../providers/ticket-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {TicketDocument, TicketModel} from '../../model/ticket-model';
import {CompanyDetailsModel} from '../../model/company-details-model';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {FileUploader} from 'ng2-file-upload';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {StatusDetailsModel} from '../../model/status-details-model';
import {TicketDocumentViewerPageComponent} from '../ticket-document-viewer-page/ticket-document-viewer-page.component';
import {environment} from '../../../environments/environment';
import {FieldResourceService} from '../../providers/field-resource-service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-update-ticket-page',
  templateUrl: './update-ticket-page.component.html',
  styleUrls: ['./update-ticket-page.component.css']
})
export class UpdateTicketPageComponent implements OnInit {

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;
  ticketId: number;
  ticket: TicketModel;
  ticketDocuments: TicketDocument[];
  respondingCompanies: CompanyDetailsModel[];
  wacUsers: ProfileModel[];
  bsModalRefViewDocument: BsModalRef;
  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;
  fromFieldResource: boolean = false;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private companyEventService: CompanyEventsService, public bsModalRef: BsModalRef, private ticketService: TicketService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
              private fieldResourceService: FieldResourceService,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  showWACSelection = false;
  showDocModificationOption = false;
  showUpdateStatus = false;
  showWorkLocation= false;
  showDynamicFields= false;
  checkAndEnableRoleBasedOption(){
    if(this.userRole === environment.userRoles.SuperAdministrator
      || this.userRole === environment.userRoles.Administrator
      || this.userRole === environment.userRoles.TicketAssignmentCoordinator
    ){
      this.showWACSelection = true;
      this.showDocModificationOption = true;
      this.showUpdateStatus = true;
      this.showWorkLocation = true;
      this.showDynamicFields = true;
    }else if(this.userRole === environment.userRoles.SupportPersonnel){
      this.showDocModificationOption = true;
      this.showUpdateStatus = false;
      this.showWorkLocation = true;
      this.showDynamicFields = true;
    }else if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
      this.showUpdateStatus = true;
      this.showWACSelection = true;
    }
  }
  ngOnInit() {
    this.fromFieldResource = this.modalService.config.initialState['fromFieldResource'];
    this.ticketId = this.modalService.config.initialState['ticketId'];

    if(this.fromFieldResource){
      this.showUpdateStatus = true;
      this.showDocModificationOption = false;
      this.showWACSelection = false;
      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.fetchTicketDetailsByFR());
      baseDataCalls.push(this.fetchALLTicketDocumentsByFR());
      this.prepareFieldResourceStatus();
      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(e);
      });

    }else{
      this.adminCompanyId = this.authService.userDetails.companyId;
      this.adminCompanyName = this.authService.userDetails.companyName;
      this.userRole = this.authService.userDetails.userRole;

      this.checkAndEnableRoleBasedOption();
      this.spinner.show();
      let baseDataCalls = [];
      baseDataCalls.push(this.fetchTicketDetails());
      // baseDataCalls.push(this.fetchAllRespondingCompanies());
      baseDataCalls.push(this.fetchTicketDocuments());
      baseDataCalls.push(this.getModuleStatus());

      Promise.all(baseDataCalls).then((r) => {
        this.spinner.hide();
      }).catch((e) => {
        console.log(e);
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(e);
      });
    }


  }

  prepareFieldResourceStatus(){
    let tms: StatusDetailsModel[] = [];
    let sm = new StatusDetailsModel();
    sm.StatusId = environment.moduleStatus.TicketingCrewAssigned;
    sm.StatusValue='Crew Assigned';
    tms.push(sm);

    sm = new StatusDetailsModel();
    sm.StatusId = environment.moduleStatus.TicketingHeld;
    sm.StatusValue='Held';
    tms.push(sm);

    sm = new StatusDetailsModel();
    sm.StatusId = environment.moduleStatus.TicketingVerifyRestored;
    sm.StatusValue='Verify Restored';
    tms.push(sm);

    sm = new StatusDetailsModel();
    sm.StatusId = environment.moduleStatus.TicketingUnableToWork;
    sm.StatusValue='Unable To Work';
    tms.push(sm);

    this.ticketModuleStatus = tms;
  }

  fetchTicketDetailsByFR() {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchTicketDetailsByFR(this.ticketId).subscribe((res) => {
        this.ticket = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchALLTicketDocumentsByFR() {
    return new Promise(((resolve, reject) => {
      this.fieldResourceService.fetchALLTicketDocumentsByFR(this.ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchAllRespondingCompanies() {
    return new Promise(((resolve, reject) => {
      this.commonService.getAllRespondingCompanies().subscribe((res) => {
        this.respondingCompanies = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchTicketDetails() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDetails(this.ticketId).subscribe((res) => {
        this.ticket = res;
        this.ticketService.fetchAllActiveWACUsersOfRespondingCompany(this.ticket.RespondingCompanyId).subscribe((res) => {
          console.log(res);
          if (res) {
            this.wacUsers = res;
            this.prepareWACUsers();
          } else {
            this.wacUsers = [];
          }
          resolve(true);
        }, (err) => {
          reject(err);
        });

      }, (err) => {
        reject(err);
      });
    }));
  }

  fetchTicketDocuments() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchTicketDocuments(this.ticketId).subscribe((res) => {
        this.ticketDocuments = res;
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  fetchAllWACOfRespondingCompany() {
    this.spinner.show();
    this.ticketService.fetchAllActiveWACUsersOfRespondingCompany(this.ticket.RespondingCompanyId).subscribe((res) => {
      console.log(res);
      if (res) {
        this.wacUsers = res;
        this.prepareWACUsers();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.wacUsers = [];
        this.iziToast.warning({
          title: 'No WAC found.',
          message: 'No WAC found for the selected Responding Company.',
          position: 'center',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  prepareWACUsers() {
    for (let tu of this.wacUsers) {
      tu.FullName = tu.FirstName.trim() + ' ' + tu.LastName.trim();
    }
  }

  @ViewChild('uploadFileSelect')
  uploadFileSelect: ElementRef;
  allowedFileTypes = ['pdf', 'xps', 'oxps'];
  public uploader: FileUploader = new FileUploader({url: "",allowedFileType: ['pdf', 'xps', 'oxps', 'application']});
  selectedFile: File;

  uploadedPercentage: any;
  uploadProgress: number;
  showUploadProgress: boolean = false;

  ticketModuleStatus: StatusDetailsModel[];

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'RespondingCompanyId', this.ticket.RespondingCompanyId, 'Responding Company');
    validator.requiredNumber(this.validationError, 'Status', this.ticket.Status, 'Status');
    if (!this.ticketDocuments || this.ticketDocuments.length === 0) {
      if (this.selectedFile) {
        validator.requiredString(this.validationError, 'selectedFile', this.selectedFile.name, 'This');
      } else {
        validator.requiredString(this.validationError, 'selectedFile', null, 'This');
      }
    }
    //STATUS USED
    if (this.ticket.Status !== environment.moduleStatus.TicketingUnassigned) {
      validator.requiredNumber(this.validationError, 'WAC_Id', this.ticket.WAC_Id, 'This');
    }

    if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
      validator.requiredNumber(this.validationError, 'WAC_Id', this.ticket.WAC_Id, 'This');
    }

    if (this.validationError && (this.validationError['RespondingCompanyId'] || this.validationError['selectedFile'] || this.validationError['WAC_Id'])) {
      return;
    }

  }
  checkAndUpdateStatus(){
    if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
        //Do nothing
    }else{
      if(this.ticket.WAC_Id &&
        this.ticket.Status === environment.moduleStatus.TicketingUnassigned){
        this.ticket.Status = environment.moduleStatus.TicketingWACAssigned;
      }else if(!this.ticket.WAC_Id &&
        this.ticket.Status === environment.moduleStatus.TicketingWACAssigned){
        this.ticket.Status = environment.moduleStatus.TicketingUnassigned;
      }
    }

  }


  getModuleStatus() {
    return new Promise(((resolve, reject) => {
      this.commonService.getModuleStatus('Ticketing').subscribe((res) => {
        console.log(res);
        if(this.userRole === environment.userRoles.WorkloadAssignmentCoordinator){
          let tms: StatusDetailsModel[] = [];
          for(let tr of res){
            if(tr.StatusId !== environment.moduleStatus.TicketingUnassigned
                && tr.StatusId !== environment.moduleStatus.TicketingClosed
              ){
              tms.push(tr);
            }
          }
          this.ticketModuleStatus = tms;
        }else{
          let tms: StatusDetailsModel[] = [];
          for(let tr of res){
            if(tr.StatusId !== environment.moduleStatus.TicketingClosed
            ){
              tms.push(tr);
            }
          }
          this.ticketModuleStatus = tms;
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }
  removeTicketDocument(){
    this.ticketDocuments = null;
  }
  prepareCompanyEventName(companyEventId){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }
  public fileOverAnother(e: any): void {
    // this.hasAnotherDropZoneOver = e;
    console.log('File updated');

    if (this.uploader.queue.length === 1) {
      this.selectedFile = this.uploader.queue[0]._file;
      if (this.selectedFile && e[0] && this.selectedFile.name !== e[0].name) {
        this.iziToast.error({
          title: 'Invalid file type',
          message: 'Please drop only PDF or XPS file.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: true
        });
      } else if (this.selectedFile.name && this.selectedFile.name.indexOf('.') > -1) {
        let fileExtension = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.') + 1, this.selectedFile.name.length);
        if (this.allowedFileTypes.indexOf(fileExtension) === -1) {
          this.selectedFile = null;
          this.uploader.clearQueue();
          this.iziToast.error({
            title: 'Invalid file type',
            message: 'Please drop only PDF or XPS file.',
            position: 'topRight',
            timeout: 5000,
            close: true,
            overlay: true
          });
        }
      }
    } else if (this.uploader.queue.length > 1) {
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    } else {
      this.iziToast.error({
        title: 'Invalid file type',
        message: 'Please drop only PDF or XPS file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }
    this.validateFields();
  }

  close() {
    this.bsModalRef.hide();
  }

  fileSelected(e) {
    // this.hasAnotherDropZoneOver = e;
    console.log(this.uploadFileSelect.nativeElement);
    if (this.uploadFileSelect.nativeElement.files.length === 1) {
      this.selectedFile = this.uploadFileSelect.nativeElement.files[0];
    } else if (this.uploadFileSelect.nativeElement.files.length > 1) {
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    } else {
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }
    //this.selectedFile = this.uploadFileSelect.files;
    //this.fileName = this.selectedFile.name;
  }

  validateFieldsForFR() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'Status', this.ticket.Status, 'Status');
  }
  updateTicketByFR(){
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    this.validateFields();
    if (this.validationError && (this.validationError['Status'])) {
      return;
    }
    window.scroll(0, 0);
    this.spinner.show();
    this.fieldResourceService.updateTicketStatusByFR(this.ticketId, this.ticket.Status, this.ticket.Comment).subscribe((res)=>{
      this.spinner.hide();
      this.bsModalRef.hide();
      this.iziToast.success({title: 'Ticket updated', message: 'Ticket updated successfully!.', position: 'topRight'});
    },(err) => {
      this.spinner.hide();
      console.log(err);
      if (err.status === 422 || err.status === 400) {
        if (err.error.message) {
          this.errors.push(err.error.message);
        } else {
          this.handleReqErr.handleReqError(err, this.validationError);
        }
      } else {
        this.handleReqErr.handlePageLoadError(err);
      }

    } );
  }

  updateTicket() {
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    this.validateFields();
    if (this.validationError && (this.validationError['RespondingCompanyId'] || this.validationError['selectedFile'] || this.validationError['WAC_Id'] || this.validationError['Status'])) {
      return;
    }
    window.scroll(0, 0);
    this.spinner.show();
    if (this.selectedFile) {
      this.ticketService.updateTicketWithFile(this.selectedFile, this.ticket).subscribe((event: HttpEvent<any>) => {
        this.uploadFileSelect.nativeElement.value = '';
        this.selectedFile = null;
        this.uploader.clearQueue();
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.Response:
            this.showUploadProgress = false;
            this.uploadedPercentage = 0;
            this.uploadProgress = 0;
            this.bsModalRef.hide();
            this.spinner.hide();
            this.iziToast.success({title: 'Ticket updated', message: 'Ticket updated successfully!.', position: 'topRight'});
            break;
          case 1: {
            this.showUploadProgress = true;
            if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
              this.uploadedPercentage = event['loaded'] / event['total'] * 100;
              this.uploadProgress = Math.round(this.uploadedPercentage);
            }
            break;
          }

        }
      }, (err) => {
        this.spinner.hide();
        this.showUploadProgress = false;
        this.uploadedPercentage = 0;
        this.uploadProgress = 0;
        console.log(err);
        if (err.status === 422 || err.status === 400) {
          if (err.error.message) {
            this.errors.push(err.error.message);
          } else {
            //this.errors.push(err.error);
            this.handleReqErr.handleReqError(err, this.validationError);
          }
        } else {
          this.handleReqErr.handlePageLoadError(err);
        }

      });
    } else {
      this.ticketService.updateTicket(this.ticket).subscribe((res) => {
        this.bsModalRef.hide();
        this.spinner.hide();
        this.iziToast.success({title: 'Ticket updated', message: 'Ticket updated successfully!.', position: 'topRight'});
      }, (err) => {
        this.spinner.hide();
        console.log(err);
        if (err.status === 422 || err.status === 400) {
          if (err.error.message) {
            this.errors.push(err.error.message);
          } else {
            //this.errors.push(err.error);
            this.handleReqErr.handleReqError(err, this.validationError);
          }
        } else {
          this.handleReqErr.handlePageLoadError(err);
        }

      });
    }

  }

  viewTicketDocument(ticketDocumentId: number, originalFileName:string){
    this.bsModalRefViewDocument = this.modalService.show(TicketDocumentViewerPageComponent, {
      class: 'modal-dialog-centered modal-lg second',
      initialState: {
        ticketDocumentId: ticketDocumentId,
        fromFieldResource: this.fromFieldResource,
        ticketDocumentName: originalFileName
      },
      backdrop: 'static'
    });
  }

  downloadTicketDocumentByFR(ticketDocumentId: number, filename: string) {
    this.spinner.show();
    this.fieldResourceService.fetchTicketDocumentByFR(ticketDocumentId).subscribe((res) => {
      console.log(res);
      this.createAndDownloadBlobFile(res, filename);
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      if(err.status === 404){
        this.iziToast.error({
          title: 'File Missing',
          message: 'Requested file is not available to download.',
          position: 'center', timeout: 2000
        });
      }else{
        this.iziToast.error({
          title: 'Error getting Documents',
          message: 'Error while getting the documents, please try again.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        console.log(err);
      }
    });
  }


  downloadTicketDocument(ticketDocumentId: number, filename: string) {
    this.spinner.show();
    this.ticketService.fetchTicketDocument(ticketDocumentId).subscribe((res) => {
      console.log(res);
      this.createAndDownloadBlobFile(res, filename);
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      if(err.status === 404){
        this.iziToast.error({
          title: 'File Missing',
          message: 'Requested file is not available to download.',
          position: 'center', timeout: 2000
        });
      }else{
        this.iziToast.error({
          title: 'Error getting Documents',
          message: 'Error while getting the documents, please try again.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
        console.log(err);
      }
    });
  }

  createAndDownloadBlobFile(body, filename) {
   // var blob = new Blob([body], {type: 'application/octet-stream'});
    saveAs(body, filename);
    /*if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link['download'] !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }*/
  }
}
