import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {AuthService} from './providers/auth-service';
import {LocalDataService} from './providers/local-data-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SignUpPageComponent} from './pages/sign-up-page/sign-up-page.component';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserProfilePageComponent} from './pages/user-profile-page/user-profile-page.component';
import {ForgotPasswordPageComponent} from './pages/forgot-password-page/forgot-password-page.component';
import {CommonService} from './providers/common-service';
import {UserDetailsModel} from './model/user-details-model';
import {SetFirstPasswordPageComponent} from './pages/set-first-password-page/set-first-password-page.component';
import {environment} from '../environments/environment';
import {Location} from '@angular/common';
import {FieldResourceService} from './providers/field-resource-service';
import {HelpPageComponent} from './pages/help-page/help-page.component';
import {HandleRequestError} from './providers/handle-request-error';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'mara-web';
  bsModalRef: BsModalRef;
  userFullName: string;
  isAuthenticated = false;
  isFieldResource = false;
  isloaded = false;
  showSideMenuToggleBtn = false;

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;


  constructor(private spinner: NgxSpinnerService, private modalService: BsModalService, private route: ActivatedRoute,
              private authService: AuthService, private localDataService: LocalDataService, private fieldResourceService: FieldResourceService,
              private router: Router, public iziToast: Ng2IzitoastService, private commonService: CommonService, private location: Location,) {

    localStorage.removeItem('lastAccessed');
    this.authService.activeToken.subscribe((token) => {
      this.refreshToken(token);
    });

    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', '' + Date.now());
    } else {
      this.localDataService.readUserToken().subscribe((data) => {
        if (data) {
          this.authService.setToken(data);
        }
      });
    }
    window.addEventListener('storage', (event) => {
      console.log('eve', event);
      if (event['key'] === 'getSessionStorage') {
        if (sessionStorage.getItem('USER_TOKEN')) {
          localStorage.setItem('sessionStorage', sessionStorage.getItem('USER_TOKEN'));
          localStorage.removeItem('sessionStorage');
        }

      } else if (event['key'] === 'sessionStorage' && !sessionStorage.length) {
        let data = event['newValue'];
        if (data != 'null' || !data) {
          sessionStorage.setItem('USER_TOKEN', data);
        }

        this.localDataService.readUserToken().subscribe((data) => {
          if (data) {
            this.authService.setToken(data);
          }
        });
      } else if (event['key'] === 'clearSessionStorage' && event['newValue'] === 'true') {
        sessionStorage.clear();
        /*this.router.navigate(['']);
        this.isAuthenticated = false;*/
        this.logout(false, false);
        this.iziToast.success({
          title: 'Logged out', message: 'You are logged out Successful!', position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    });

    /* window.onbeforeunload = function() {
       //sessionStorage.clear();
     };*/

  }

  ngOnInit(): void {
    // this.checkForSessionTimeoutAndShowMessage();

    this.isAuthenticated = false;

    this.router.events.subscribe(
      (event: any) => {
        //console.log('Location Href',this.location.path());
        if (event instanceof NavigationEnd) {
          if (this.location.path().indexOf('/fieldResource/') != -1) {
            this.isFieldResource = true;
            if (this.fieldResourceService.frDetails) {
              this.userFullName = this.fieldResourceService.frDetails.FirstName + ' ' + this.fieldResourceService.frDetails.LastName;
            } else {
              let fr = this.localDataService.readFRUserToken();
              if (fr) {
                this.userFullName = fr.FirstName + ' ' + fr.LastName;
              }
            }
          }
          console.log('this.router.url', this.router.url);
          this.navigationEndURL = this.router.url;
          if (this.navigationEndURL !== '/') {
            this.showLinkToManage = false;
          }

          if (this.router.url === '/forgotPassword') {
            this.openForgotPasswordModel();
          } else if (this.router.url === '/auth') {
            this.logout(true, false);
          } else if (this.router.url === '/home') {
            this.openRoleDefaultPage();
          } else if (this.router.url === '/auth/login?sessionTimeOut=true') {
            this.showSessionTimeoutMessage();
          }else if(this.router.url === '/auth/login') {
            if(this.isAuthenticated){
              this.openRoleDefaultPage();
            }
          }


        }
      }
    );
    this.showOrHideDrawerToggle();

  }

  startTokenRefresh() {
    this.tokenRefresher = setInterval(() => {
      this.refreshTokenOnInterval();
    }, 10 * 60 * 1000);
  }

  tokenRefresher: any;

  openRoleDefaultPage() {
    if (this.isAuthenticated) {
      if (this.userRole === environment.userRoles.SuperAdministrator) {
        this.router.navigate(['home', 'manageUsers']);
      } else if (this.userRole === environment.userRoles.Administrator) {
        this.router.navigate(['home', 'manageUsers']);
      } else if (this.userRole === environment.userRoles.ResourceUnitLead) {
        this.router.navigate(['home', 'manageRosters', 'current']);
      } else if (this.userRole === environment.userRoles.OnboardingResourceCoordinator) {
        this.router.navigate(['home', 'manageRosters', 'current']);
      } else if (this.userRole === environment.userRoles.TicketAssignmentCoordinator) {
        this.router.navigate(['home', 'tickets']);
      } else if (this.userRole === environment.userRoles.SupportPersonnel) {
        this.router.navigate(['home', 'tickets']);
      } else if (this.userRole === environment.userRoles.WorkloadAssignmentCoordinator) {
        this.router.navigate(['home', 'tickets']);
      } else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['']);
    }

  }

  openForgotPasswordModel() {
    this.bsModalRef = this.modalService.show(ForgotPasswordPageComponent, {class: 'modal-dialog-centered ', backdrop: 'static'});
  }

  openSetPasswordModel() {
    this.bsModalRef = this.modalService.show(SetFirstPasswordPageComponent, {class: 'modal-dialog-centered ', backdrop: 'static'});
  }

  navigationEndURL: string;
  isActivated = false;

  refreshToken(token: string) {
    this.spinner.show();
    this.authService.refreshToken().subscribe((res) => {
      this.isloaded = true;
      this.spinner.hide();
      if (res && res.token) {
        let userDetails = new UserDetailsModel();
        userDetails.mapDetails(res);
        this.authService.userDetails = userDetails;
        this.localDataService.storeUserToken(res.token).subscribe((r) => {
          this.authService.token = res.token;
          this.userFullName = res.userFullName;
          this.isAuthenticated = true;
          this.startSessionTimeoutInterval();
          this.adminCompanyId = this.authService.userDetails.companyId;
          this.adminCompanyName = this.authService.userDetails.companyName;
          this.userRole = this.authService.userDetails.userRole;

          if (res.status === environment.moduleStatus.UserManagementActive) {
            this.isActivated = true;
          }
          if (res.status === environment.moduleStatus.UserManagementActive && this.navigationEndURL === '/auth/login') {
            this.openRoleDefaultPage();
          }
        });
        this.startTokenRefresh();
      }
    }, (err) => {
      this.isloaded = true;
      this.spinner.hide();
    });
  }

  logoutFR() {
    this.isFieldResource = false;
    this.userFullName = null;
    this.localDataService.clearFRUserToken();
    this.fieldResourceService.frDetails = null;
    this.router.navigate(['']).then((d) => {
      window.location = window.location;
    });
  }

  logout(clear, sessionTimeOut) {
    if (clear) {
      localStorage.setItem('clearSessionStorage', 'true');
      localStorage.removeItem('clearSessionStorage');
    }
    this.isAuthenticated = false;
    if (this.tokenRefresher) {
      clearInterval(this.tokenRefresher);
    }
    this.localDataService.clearUserToken().subscribe((r1) => {
      this.router.navigate(['/auth','login']);
      this.authService.logout().subscribe((r) => {
        this.authService.token = null;
        console.log('window.location', window.location);
        if (sessionTimeOut) {
          window.location.href = window.location.origin + '/auth/login?sessionTimeOut=true';
        } else {
          window.location.href = window.location.origin + '/auth/login';
        }
      }, (e) => {
        console.log(e);
        this.authService.token = null;
        console.log('window.location', window.location);
        if (sessionTimeOut) {
          window.location.href = window.location.origin + '/auth/login?sessionTimeOut=true';
        } else {
          window.location.href = window.location.origin + '/auth/login';
        }
      });
    });

  }

  refreshTokenOnInterval() {
    this.authService.refreshToken().subscribe((res) => {
      if (res && res.token) {
        let userDetails = new UserDetailsModel();
        userDetails.mapDetails(res);
        this.authService.userDetails = userDetails;
        this.localDataService.storeUserToken(res.token).subscribe((r) => {
          this.authService.token = res.token;
          this.userFullName = res.userFullName;
          this.isAuthenticated = true;
          this.adminCompanyId = this.authService.userDetails.companyId;
          this.adminCompanyName = this.authService.userDetails.companyName;
          this.userRole = this.authService.userDetails.userRole;

        });
      }
    }, (err) => {
      if (err.status === 403) {
        this.logout(true, true);
      } else {
        this.logout(true, false);
      }
      console.log('Error refreshing the token: ', err);
    });
  }

  showLogin(): void {
    this.showModel(LoginPageComponent, {class: 'modal-dialog-centered ',});
  }

  showLinkToManage = false;

  openSignupModel() {
    this.showModel(SignUpPageComponent, {class: 'modal-dialog-centered modal-lg ', backdrop: 'static'});
  }

  openHelpPage() {
    this.showModel(HelpPageComponent, {class: 'modal-dialog-centered modal-lg ', backdrop: 'static'});
  }


  showProfilePage() {
    this.showModel(UserProfilePageComponent, {class: 'modal-dialog-centered modal-lg ', backdrop: 'static'});
  }

  showModel(page, config) {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = this.modalService.show(page, config);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showOrHideDrawerToggle();
  }

  isCollapsed: boolean = false;

  @ViewChild('drawerButton')
  drawerButton: ElementRef;

  showOrHideDrawerToggle() {
    if (document.documentElement.clientWidth < 1367) {
      this.showSideMenuToggleBtn = true;
      if (this.drawerButton && this.drawerButton.nativeElement) {
        this.drawerButton.nativeElement.style.display = 'block';
      }

    } else {
      this.showSideMenuToggleBtn = false;
      if (this.drawerButton && this.drawerButton.nativeElement) {
        this.drawerButton.nativeElement.style.display = 'none';
      }

    }
  }


  toggleSideBar() {
    console.log('Toggle menu');
    this.commonService.toggleEvent.next('toggle');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    this.setLastTimeAccessInLocalStorage();
  }

  @HostListener('keypress', ['$event.target'])
  onKeypress(btn) {
    this.setLastTimeAccessInLocalStorage();
  }

  @HostListener('keydown', ['$event.target'])
  onKeydown(btn) {
    this.setLastTimeAccessInLocalStorage();
  }

  setLastTimeAccessInLocalStorage() {
    console.log('A');
    localStorage.setItem('lastAccessed', '' + new Date().getTime());
  }

  startSessionTimeoutInterval() {
    console.log('Starting Activity monitor!');
    let activityCheckerInterval = setInterval(() => {
      let lastAccessTime = localStorage.getItem('lastAccessed');
      if (lastAccessTime) {
        console.log(new Date().getTime());
        console.log(parseInt(lastAccessTime));
        console.log(new Date().getTime() - parseInt(lastAccessTime));
        //Check for 2 hours to logout
        if (new Date().getTime() - parseInt(lastAccessTime) > (2 * 60 * 60 * 1000)) {
          console.log('Start logout process!');
          this.iziToast.question({
            close: true,
            closeOnEscape: false,
            overlay: true,
            timeout: 13500,
            id: 'warning',
            title: 'You are about to logged out due to inactivity.',
            message: 'Please click the button, to Stay logged in.',
            position: 'center',
            buttons: [
              ['<button><b>Stay login</b></button>', (instance, toast) => {

                instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
                this.setLastTimeAccessInLocalStorage();
              }, true],

            ]
          });
          this.startLogoutProcessWithFinalCheck();
          clearInterval(activityCheckerInterval);
        }
      }
    }, 15 * 1000);
  }

  startLogoutProcessWithFinalCheck() {
    setTimeout(() => {
      let lastAccessTime = localStorage.getItem('lastAccessed');
      if (lastAccessTime) {
        //Check for 2 hours to logout
        if (new Date().getTime() - parseInt(lastAccessTime) > (2 * 60 * 60 * 1000)) {
          console.log('Logging Out!');
          localStorage.removeItem('lastAccessed');
          this.logout(true, true);
        } else {
          this.startSessionTimeoutInterval();
        }
      }
    }, 15 * 1000);
  }


  showSessionTimeoutMessage() {
    console.log('s11');
    this.iziToast.question({
      close: false,
      closeOnEscape: false,
      overlay: true,
      timeout: 0,
      title: 'You have been logged out due to inactivity. ',
      message: 'Please log back in when you are ready to continue.',
      position: 'center',
      buttons: [
        ['<button><b>OK</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }, true],

      ]
    });
  }


}
