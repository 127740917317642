import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {RosterCrewDetailsModel} from '../../model/roster-crew-details-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {RosterApproveRequestModel, RosterModel} from '../../model/roster-model';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {ActivatedRoute} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyCrewDetailsPageComponent} from '../modify-crew-details-page/modify-crew-details-page.component';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../providers/auth-service';

@Component({
  selector: 'app-roster-crew-details-page',
  templateUrl: './roster-crew-details-page.component.html',
  styleUrls: ['./roster-crew-details-page.component.css']
})
export class RosterCrewDetailsPageComponent implements OnInit {

  pageLoadSubscription: Subscription;
  ngOnDestroy(): void {
    this.pageLoadSubscription.unsubscribe();
  }

  isCompanyEventClosed = false;
  isRosterApproved = false;
  userNotImpactedCompany = false;

  showActivateCrewBtn: boolean = false;
  showDeactivateCrewBtn: boolean = false;
  showEditCrewBtn: boolean = false;
  showDeleteCrewBtn: boolean = false;
  showAddCrewBtn: boolean = false;

  disableStatusToggleBtn= true;
  rosterCrews: RosterCrewDetailsModel[];
  rosterCrewRows: RosterCrewDetailsModel[];
  selected: RosterCrewDetailsModel[] = [];
  rosterId: number;
  bsModalRef: BsModalRef;
  roster: RosterModel;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError,
              private commonService: CommonService, private modalService: BsModalService,
              private route: ActivatedRoute
  ) {
    this.route.params.subscribe( (params) => {
      this.rosterId = params['rosterId'];
      this.loadData();
    });
  }


  getCrewStatusClass({row, column, value}): any {
    return {
      'user-active': value,
      'user-in-active': !value
    };
  }

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  loadData() {
    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.disableStatusToggleBtn = true;
    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableDeleteBtn = true;
    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllRosterCrews());
    baseDataCalls.push(this.fetchRosterDetails());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  ngOnInit() {
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }


  fetchAllRosterCrews() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllRosterCrews(this.rosterId).subscribe((res) => {
        console.log(res);
        if(res){
          this.rosterCrews = this.prepareRosterCrewDetails(res);
          this.rosterCrewRows = [...this.rosterCrews];
        }else{
          this.rosterCrews = [];
          this.rosterCrewRows = [];
        }

        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }
  fetchRosterDetails() {
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchRosterDetails(this.rosterId).subscribe((res) => {
        console.log(res);
        this.roster = res;
        this.isCompanyEventClosed =  (this.roster.CompanyEventStatus === environment.moduleStatus.CompanyEventClosed);
        this.isRosterApproved = (this.roster.Status === environment.moduleStatus.RosterApproved);
        this.userNotImpactedCompany = (this.adminCompanyId !== this.roster.RequestingCompanyId);
          /*
        if(this.roster && this.roster.Status === environment.moduleStatus.RosterInitiated){
          this.disableApproveBtn = false;
        }*/

        if(this.roster.Status === environment.moduleStatus.RosterApproved && this.adminCompanyId === this.roster.RequestingCompanyId){
          this.showEditCrewBtn = true;
          this.showDeleteCrewBtn = false;
          this.showAddCrewBtn = true;
          this.showActivateCrewBtn = true;
          this.showDeactivateCrewBtn = true;
        }else if(this.roster.Status === environment.moduleStatus.RosterInitiated){
          this.showEditCrewBtn = true;
          this.showDeleteCrewBtn = true;
          this.showAddCrewBtn = true;
          this.showActivateCrewBtn = true;
          this.showDeactivateCrewBtn = true;
        }else if(this.roster.Status === environment.moduleStatus.RosterReleased && this.adminCompanyId === this.roster.RespondingCompanyID ){
          this.showEditCrewBtn = true;
          this.showDeleteCrewBtn = true;
          this.showAddCrewBtn = true;
          this.showActivateCrewBtn = true;
          this.showDeactivateCrewBtn = true;
        }else{
          this.showEditCrewBtn = false;
          this.showDeleteCrewBtn = false;
          this.showAddCrewBtn = false;
          this.showActivateCrewBtn = false;
          this.showDeactivateCrewBtn = false;
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  prepareRosterCrewDetails(crews){
    let tempCrews = [];
    if(!crews){
      return tempCrews;
    }
    for(let tc of crews){
      let c = new RosterCrewDetailsModel();
      c.mapDetails(tc);
      tempCrews.push(c);
    }
    return tempCrews;
  }
  disableDeleteBtn = true;
  disableEditBtn = true;
  disableApproveBtn = true;
  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.rosterCrews.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.rosterCrewRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
    this.disableEditBtn = true;
    this.disableApproveBtn = true;
    this.disableDeleteBtn = true;
  }


  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.CrewId, val) || this.matchContent(d.LastName, val) ||
      this.matchContent(d.FirstName, val) || this.matchContent(d.CellPhone, val) ||
      this.matchContent(d.PersonnelId, val) || this.matchContent(d.Email, val) ||
      this.matchContent(d.Gender, val) || this.matchContent(d.TeamLeadTxt, val) ||
      this.matchContent(d.CrewLeadTxt, val) || this.matchContent(d.TeamType, val) ||
      this.matchContent(d.ResourceType, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.rosterCrews) {
      if (this.selected.length === this.rosterCrews.length) {
        this.iziToast.warning({
          title: 'All Crews selected',
          message: 'You have selected all the Crews. Total selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }
    /*let allActiveUsers = true;
    let allInActiveUsers = true;

    for (let sel of this.selected) {
      if (sel.Status === 1 && allActiveUsers) {
        allActiveUsers = true;
      } else {
        allActiveUsers = false;
      }

      if (sel.Status === 2 && allInActiveUsers) {
        allInActiveUsers = true;
      } else {
        allInActiveUsers = false;
      }
    }*/

    if (this.selected.length === 1) {
      this.disableEditBtn = false;
    } else {
      this.disableEditBtn = true;
    }

    /* if (this.selected.length > 0) {
       this.disableActivateBtn = !allInActiveUsers;
       this.disableInActivateBtn = !allActiveUsers;
     } else {
       this.disableActivateBtn = true;
       this.disableInActivateBtn = true;
     }*/

    let enableDeleteButton = true;
    for (let sel of this.selected) {
      /*if (sel.CompanyEventCounts > 1) {
        enableDeleteButton = false;
        break;
      }*/
    }
    this.disableStatusToggleBtn = true;
    if (this.selected.length > 0) {
      this.disableStatusToggleBtn = false;
      this.disableDeleteBtn = !enableDeleteButton;
    } else {
      this.disableDeleteBtn = true;
    }

  }



  deleteCrew() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Delete the Roster Crew Member? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onDeleteConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onDeleteConfirm() {
    this.spinner.show();
    let rcdIds = [];
    for (let sel of this.selected) {
      rcdIds.push(sel.RosterCrewDetailsId);
    }
    this.rosterService.deleteRosterCrewsDetails(rcdIds.join(','), this.roster.RosterId).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({title: 'Crew Deleted',
        message: 'Crew deleted successfully.', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false});
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }

  addCrew() {
    this.bsModalRef = this.modalService.show(ModifyCrewDetailsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditCrew: false, rosterId: this.rosterId, companyEventId: this.roster.CompanyEventId, rosterStatus: this.roster.Status},
      backdrop: 'static'
    });
  }


  editCrew() {
    this.bsModalRef = this.modalService.show(ModifyCrewDetailsPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditCrew: true, rosterCrewDetailsId: this.selected[0].RosterCrewDetailsId, rosterId: this.rosterId, companyEventId: this.roster.CompanyEventId, rosterStatus: this.roster.Status},
      backdrop: 'static'
    });
  }

  close(){
    this.bsModalRef.hide();
  }


  activateSelectedCrews() {
    this.spinner.show();

    let rosterCrewIds:number[] = [];
    for(let crew of this.selected){
      rosterCrewIds.push(crew.RosterCrewDetailsId);
    }
    this.rosterService.activateRosterCrews(rosterCrewIds).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.loadData();
      this.iziToast.success({
        title: 'Roster Crews Activated', message: 'status updated!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      //this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }


  deactivateSelectedCrews() {
    this.spinner.show();

    let rosterCrewIds:number[] = [];
    for(let crew of this.selected){
      rosterCrewIds.push(crew.RosterCrewDetailsId);
    }
    this.rosterService.deActivateRosterCrews(rosterCrewIds).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.loadData();
      this.iziToast.info({
        title: 'Roster Crews De-Activated', message: 'status updated!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      //this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }


  /*deleteRosterCrewsDetails() {
    this.spinner.show();
    this.rosterService.deleteRosterCrewsDetails(this.selected[0].RosterCrewDetailsId).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({
        title: 'Crew Deleted',
        message: 'Crew deleted successfully.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false
      });
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);

    });
  }*/


  /*approveRosterEvent() {
    this.iziToast.question({
      close: false,
      closeOnEscape: true,
      overlay: true,
      timeout: 0,
      id: 'question',
      title: 'Do you really want to Approve the Roster? ',
      position: 'center',
      buttons: [
        ['<button><b>YES</b></button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
          this.onApproveConfirm();
        }, true],
        ['<button>NO</button>', (instance, toast) => {

          instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

        }],
      ]
    });
  }

  onApproveConfirm() {
    this.spinner.show();
    /!*let eventIds = [];
    for (let sel of this.selected) {
      eventIds.push(sel.EventId);
    }*!/

    let rosterApproveReq = new RosterApproveRequestModel();
    rosterApproveReq.RosterId = this.roster.RosterId;
    rosterApproveReq.CompanyEventId = this.roster.CompanyEventId;
    rosterApproveReq.dynamicFields = this.roster.dynamicFields;
    this.rosterService.approveRoster(rosterApproveReq).subscribe((res) => {
      console.log(res);
      this.spinner.hide();
      this.iziToast.success({title: 'Roster Approved', message: 'Roster Approved Successful!', position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: false});
      this.loadData();
    }, (err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });
  }*/

}
