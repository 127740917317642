import {Validators} from '../validators/validators';
import {ValidationError} from '../validators/validation-error';

export class RosterCrewDetailsModel {
  RosterId : number;
  CompanyEventId : number;
  Status : boolean;
  RosterCrewDetailsId : number;
  CrewAssignedTicketCount : number;
  CrewId : string;
  LastName : string;
  FirstName : string;
  CellPhone : string;
  Gender : string;
  TeamLead : boolean;
  TeamLeadTxt : string;
  CrewLead : boolean;
  CrewLeadTxt : string;
  TeamType : string;
  ResourceType : string;

  PersonnelId : string;
  Email : string;
  DepartureCity : string;
  DepartureState : string;

  validateInsert(): ValidationError {
    let error  = new ValidationError();
    let validator = new Validators();
    validator.requiredNumber(error, 'RosterId', this.RosterId, 'RosterId');
    validator.requiredBoolean(error, 'Status', this.Status, 'Status');
    validator.requiredString(error, 'CrewId', this.CrewId, 'CrewId');
    validator.requiredString(error, 'PersonnelId', this.PersonnelId, 'Personnel Id');
    validator.validateEmail(error, 'Email', this.Email, 'Email');
    validator.validateName(error, 'LastName', this.LastName, 'Last Name');
    validator.validateName(error, 'FirstName', this.FirstName, 'First Name');
    validator.validateRosterCrewCellPhone(error, 'CellPhone', this.CellPhone, 'Cell Phone');
    validator.requiredString(error, 'Gender', this.Gender, 'Gender');
    validator.requiredString(error, 'TeamLeadTxt', this.TeamLeadTxt, 'Team Lead');
    validator.requiredString(error, 'CrewLeadTxt', this.CrewLeadTxt, 'Crew Lead');
    validator.requiredString(error, 'TeamType', this.TeamType, 'Team Type');
    validator.requiredString(error, 'ResourceType', this.ResourceType, 'Resource Type');
    return error;
  };

  validateUpdate(): ValidationError {
    let error  = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'CrewId', this.CrewId, 'CrewId');
    validator.requiredBoolean(error, 'Status', this.Status, 'Status');
    validator.validateName(error, 'LastName', this.LastName, 'Last Name');
    validator.validateName(error, 'FirstName', this.FirstName, 'First Name');
    validator.validateRosterCrewCellPhone(error, 'CellPhone', this.CellPhone, 'Cell Phone');
    validator.requiredString(error, 'Gender', this.Gender, 'Gender');
    validator.requiredString(error, 'TeamLeadTxt', this.TeamLeadTxt, 'Team Lead');
    validator.requiredString(error, 'CrewLeadTxt', this.CrewLeadTxt, 'Crew Lead');
    validator.requiredString(error, 'TeamType', this.TeamType, 'Team Type');
    validator.requiredString(error, 'ResourceType', this.ResourceType, 'Resource Type');
    validator.requiredNumber(error, 'RosterCrewDetailsId', this.RosterCrewDetailsId, 'RosterCrewDetailsId');
    return error;
  };

  mapDetails(crewDetails){
    this.RosterId =  crewDetails.RosterId;
    this.CrewId =  ''+crewDetails.CrewId;
    this.PersonnelId =  ''+crewDetails.PersonnelId;
    this.Email =  ''+crewDetails.Email;
    this.DepartureCity =  ''+crewDetails.DepartureCity;
    this.DepartureState =  ''+crewDetails.DepartureState;
    this.LastName =  crewDetails.LastName;
    this.FirstName =  crewDetails.FirstName;
    this.CellPhone =  ''+crewDetails.CellPhone;
    this.Gender =  crewDetails.Gender;
    this.TeamLead = crewDetails.TeamLead;
    this.CrewLead = crewDetails.CrewLead;
    this.TeamType =  crewDetails.TeamType;
    this.ResourceType =  crewDetails.ResourceType;
    this.RosterCrewDetailsId =  crewDetails.RosterCrewDetailsId;
    this.Status =  crewDetails.Status;

    if(crewDetails.TeamLead === true){
      this.TeamLeadTxt = 'Yes';
    }else{
      this.TeamLeadTxt =  'No';
    }
    if(crewDetails.CrewLead === true){
      this.CrewLeadTxt = 'Yes';
    }else{
      this.CrewLeadTxt =  'No';
    }

  }

}
