import {ValidationError} from '../validators/validation-error';
import {Validators} from '../validators/validators';

export class RosterEquipmentDetailsModel {
  EquipmentId: string;
  EquipmentType: string;
  EquipmentDesc: string;
  EquipmentFuelType: string;
  AssignedEquipmentCrewID: string;
  RosterEquipmentDetailsId: number;
  RosterId: number;

  validateInsert(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'EquipmentId', this.EquipmentId, 'Equipment Id');
    validator.requiredString(error, 'EquipmentType', this.EquipmentType, 'Equipment Type');
    /*validator.requiredString(error, 'Equipment1Desc', this.Equipment1Desc, 'Equipment1 Desc');
    validator.requiredString(error, 'Equipment1FuelType', this.Equipment1FuelType, 'Equipment1 Fuel Type');
    validator.requiredString(error, 'DepartureLocationETD', this.DepartureLocationETD, 'Departure Location ETD');
    validator.requiredString(error, 'DepartureCity', this.DepartureCity, 'Departure City');
    validator.requiredString(error, 'DepartureState', this.DepartureState, 'Departure State');
    validator.requiredString(error, 'DepartureZip', this.DepartureZip, 'Departure Zip');
    validator.requiredString(error, 'RequiresLodging', this.RequiresLodging, 'Requires Lodging');
    validator.validateEmail(error, 'Email', this.Email, 'Email');
    validator.requiredString(error, 'PersonnelId', this.PersonnelId, 'Personnel Id');
    validator.requiredString(error, 'RosterEquipmentDetailsId', this.RosterEquipmentDetailsId, 'RosterEquipmentDetails Id');*/
    return error;
  };


  validateUpload(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'EquipmentId', this.EquipmentId, 'Equipment Id');
    validator.requiredString(error, 'EquipmentType', this.EquipmentType, 'Equipment Type');
    /*validator.requiredString(error, 'Equipment1Desc', this.Equipment1Desc, 'Equipment1 Desc');
    validator.requiredString(error, 'Equipment1FuelType', this.Equipment1FuelType, 'Equipment1 Fuel Type');
    validator.requiredString(error, 'DepartureLocationETD', this.DepartureLocationETD, 'Departure Location ETD');
    validator.requiredString(error, 'DepartureCity', this.DepartureCity, 'Departure City');
    validator.requiredString(error, 'DepartureState', this.DepartureState, 'Departure State');
    validator.requiredString(error, 'DepartureZip', this.DepartureZip, 'Departure Zip');
    validator.requiredString(error, 'RequiresLodging', this.RequiresLodging, 'Requires Lodging');
    validator.validateEmail(error, 'Email', this.Email, 'Email');
    validator.requiredString(error, 'PersonnelId', this.PersonnelId, 'Personnel Id');
    validator.requiredString(error, 'RosterEquipmentDetailsId', this.RosterEquipmentDetailsId, 'RosterEquipmentDetails Id');*/
    return error;
  };

  validateUpdate(): ValidationError {
    let error = new ValidationError();
    let validator = new Validators();
    validator.requiredString(error, 'EquipmentId', this.EquipmentId, 'Equipment Id');
    validator.requiredString(error, 'EquipmentType', this.EquipmentType, 'Equipment Type');
    /*validator.requiredString(error, 'Equipment1Desc', this.Equipment1Desc, 'Equipment1 Desc');
    validator.requiredString(error, 'Equipment1FuelType', this.Equipment1FuelType, 'Equipment1 Fuel Type');
    validator.requiredString(error, 'DepartureLocationETD', this.DepartureLocationETD, 'Departure Location ETD');
    validator.requiredString(error, 'DepartureCity', this.DepartureCity, 'Departure City');
    validator.requiredString(error, 'DepartureState', this.DepartureState, 'Departure State');
    validator.requiredString(error, 'DepartureZip', this.DepartureZip, 'Departure Zip');
    validator.requiredString(error, 'RequiresLodging', this.RequiresLodging, 'Requires Lodging');
    validator.validateEmail(error, 'Email', this.Email, 'Email');
    validator.requiredString(error, 'PersonnelId', this.PersonnelId, 'Personnel Id');*/
    validator.requiredNumber(error, 'RosterEquipmentDetailsId', this.RosterEquipmentDetailsId, 'RosterEquipmentDetails Id');
    return error;
  };

  mapDetails(equipmentDetails){
    if(equipmentDetails) {
      this.EquipmentId =  ''+equipmentDetails.EquipmentId;
      this.EquipmentType =  equipmentDetails.EquipmentType;
      this.EquipmentDesc =  equipmentDetails.EquipmentDesc;
      this.EquipmentFuelType =  equipmentDetails.EquipmentFuelType;
      this.AssignedEquipmentCrewID =  equipmentDetails.AssignedEquipmentCrewID;
      this.RosterEquipmentDetailsId =  equipmentDetails.RosterEquipmentDetailsId;
      this.RosterId =  equipmentDetails.RosterId;
    }
  }
}
