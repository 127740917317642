export class UserDetailsModel {
  userId: number;
  companyId: number;
  companyType: number;
  userRole: number;
  userFullName: string;
  companyName: string;
  mfaVerified: boolean;

  mapDetails(response){
    this.userId = response['userId'];
    this.companyId = response['companyId'];
    this.companyType = response['companyType'];
    this.userRole = response['userRole'];
    this.userFullName = response['userFullName'];
    this.companyName = response['companyName'];
    this.mfaVerified = response['mfaVerified'];
  }
}
