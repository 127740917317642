import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth-service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {MasterEventModel, MasterEventTypeModel} from '../model/master-event-model';
import {ServiceResponse} from '../model/service-response';
import {LocalDataService} from './local-data-service';
import {EnvironmentService} from './environment-service';

@Injectable()
export class MasterEventService {

  constructor(private http: HttpClient, private authService: AuthService, private environment: EnvironmentService) {

  }

  fetchMasterEvent(eventId: number): Observable<MasterEventModel> {
    return this.http.post<MasterEventModel>(this.environment.serviceHostUrl + 'masterEvent/fetchMasterEvent', {eventId:eventId},this.authService.prepareOptions());
  }

  fetchAllMasterEvents(): Observable<MasterEventModel[]> {
    return this.http.get<MasterEventModel[]>(this.environment.serviceHostUrl + 'masterEvent/fetchAllMasterEvents', this.authService.prepareOptions());
  }

  fetchAllActiveMasterEvents(): Observable<MasterEventModel[]> {
    return this.http.get<MasterEventModel[]>(this.environment.serviceHostUrl + 'masterEvent/fetchAllActiveMasterEvents', this.authService.prepareOptions());
  }

  fetchAllMasterEventsType(): Observable<MasterEventTypeModel[]> {
    return this.http.get<MasterEventTypeModel[]>(this.environment.serviceHostUrl + 'masterEvent/fetchAllMasterEventsType', this.authService.prepareOptions());
  }

  addMasterEvent(masterEvent: MasterEventModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'masterEvent/addMasterEvent', masterEvent, this.authService.prepareOptions());
  }

  updateMasterEvent(masterEvent: MasterEventModel): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'masterEvent/updateMasterEvent', masterEvent, this.authService.prepareOptions());
  }

  deleteMasterEvent(masterEventIds: string): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl + 'masterEvent/deleteMasterEvent', {masterEventIds:masterEventIds}, this.authService.prepareOptions());
  }

}
