import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {CompanyEventsService} from '../../providers/company-events-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../../providers/common-service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {CommonUtil} from '../../util/common-util';
import {RosterModel, RosterTicketModel} from '../../model/roster-model';
import {ValidationError} from '../../validators/validation-error';
import {Validators} from '../../validators/validators';
import {TicketService} from '../../providers/ticket-service';
import {ProfileModel} from '../../model/profile-model';
import {FileUploader} from 'ng2-file-upload';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TicketModel} from '../../model/ticket-model';
import {CompanyEventModel} from '../../model/company-event-model';

@Component({
  selector: 'app-modify-ticket-page',
  templateUrl: './create-ticket-page.component.html',
  styleUrls: ['./create-ticket-page.component.css']
})
export class CreateTicketPageComponent implements OnInit {

  rosters: RosterModel[];
  rostersRows: RosterModel[];
  adminCompanyId: number;
  adminCompanyName: string;
  pageStatus: string;
  userRole: number;

  selectedRoster: RosterModel;
  selectedRosterId: number;

  showWACSelection: boolean = false;

  constructor(public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService, private authService: AuthService,
              private companyEventService: CompanyEventsService,public bsModalRef: BsModalRef, private ticketService: TicketService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError, private route: ActivatedRoute,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  ngOnInit() {
    this.loadData();
  }

  checkAndEnableRoleBasedOption(){
    if(this.userRole === environment.userRoles.SuperAdministrator
      || this.userRole === environment.userRoles.Administrator
      || this.userRole === environment.userRoles.TicketAssignmentCoordinator
    ){
      this.showWACSelection = true;
    }
  }

  loadData() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;
    this.checkAndEnableRoleBasedOption();
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllRostersForCreateTicket());

    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });


  }

  fetchAllRostersForCreateTicket() {
    return new Promise(((resolve, reject) => {
      this.ticketService.fetchAllRostersForCreateTicket().subscribe((res) => {
        console.log(res);
        if (res) {
          this.rosters = res;
          this.prepareRosters();
          this.prepareRostersRows();
        } else {
          this.rosters = [];
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  wacUsers: ProfileModel[];
  selectedWACUserId: number;



  prepareWACUsers(){
    for(let tu of this.wacUsers){
      tu.FullName = tu.FirstName.trim()+' ' + tu.LastName.trim();
    }
  }

  prepareRostersRows(){
    this.rostersRows = [];
    for (let r1 of this.rosters) {
      let matchFound = false;
      for(let r2 of this.rostersRows){
        if(r1.FormattedCompanyEventRosterName === r2.FormattedCompanyEventRosterName){
          matchFound = true;
          break;
        }
      }
      if(!matchFound){
        this.rostersRows.push(r1);
      }
    }
  }

  prepareRosters() {
    if (!this.rosters || this.rosters.length <= 0) {
      return;
    }
    for (let ce of this.rosters) {
      ce['adminCompanyName'] = this.adminCompanyName;
      ce['adminCompanyId'] = this.adminCompanyId;
      if(ce.CompanyEventName && ce.CompanyEventName !== 'null'){
        ce.FormattedCompanyEventRosterName = ce.EventName +' - '+ ce.CompanyEventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
      }else{
        ce.FormattedCompanyEventRosterName = ce.EventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
      }
      //ce.FormattedCompanyEventRosterName = ce.EventName +' - '+ this.commonUtil.prepareCompanyEventName(ce.CompanyEventId) + ' - ' + ce.RespondingCompanyName;
    }
  }
  prepareCompanyEventName(companyEventId){
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }
  onCompanyEventSelected(){
    this.selectedRoster = null;
    this.wacUsers = null;
    this.companyEvent = null;
    for(let tr of this.rostersRows){
      if(this.selectedRosterId === tr.RosterId){
        this.selectedRoster = tr;
        this.fetchCompanyEventAndAllWACOfRespondingCompany();
        break;
      }
    }
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  fetchCompanyEventAndAllWACOfRespondingCompany() {
    if(!this.showWACSelection){
      return;
    }
    this.spinner.show();
    this.fetchCompanyEvents().then(()=>{
      this.ticketService.fetchAllActiveWACUsersOfRespondingCompany(this.selectedRoster.RespondingCompanyID).subscribe((res) => {
        console.log(res);

        if (res) {
          this.wacUsers = res;
          this.prepareWACUsers();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.wacUsers = [];
          this.iziToast.warning({
            title: 'No WAC found.',
            message: 'No WAC found for the selected Responding Company.',
            position: 'center',
            timeout: 5000,
            close: true,
            overlay: false
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.handleReqErr.handlePageLoadError(err);
      });
    }).catch((err) => {
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(err);
    });

  }
  companyEvent: CompanyEventModel;

  fetchCompanyEvents() {
    return new Promise(((resolve, reject) => {
      this.companyEventService.fetchCompanyEvent(this.selectedRoster.CompanyEventId).subscribe((res) => {
        console.log(res);
        this.companyEvent = new CompanyEventModel();
        this.companyEvent.mapCompanyEvent(res);
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  errors: string[] = [];
  validationError: ValidationError = new ValidationError();
  submitAttempted = false;

  validateFields() {
    if (!this.submitAttempted) {
      return;
    }
    this.validationError = new ValidationError();
    let err = null;
    let validator = new Validators();
    validator.requiredNumber(this.validationError, 'selectedRosterId', this.selectedRosterId, 'Company Event And Responding Company');
    if (this.validationError && this.validationError['selectedRosterId']) {
      return;
    }

  }

  close(){
    this.bsModalRef.hide();
  }



  workLocation:string;

  createTicket(){
    this.submitAttempted = true;
    this.errors = [];
    this.validationError = new ValidationError();
    let validator = new Validators();
    if(this.selectedFile){
      validator.requiredString(this.validationError, 'selectedFile', this.selectedFile.name, 'This');
    }else{
      validator.requiredString(this.validationError, 'selectedFile', null, 'This');
    }
    validator.requiredNumber(this.validationError, 'selectedRosterId', this.selectedRosterId, 'Company Event');
    if (this.validationError && (this.validationError['selectedRosterId'] || this.validationError['selectedFile'])) {
      return;
    }
    window.scroll(0, 0);
    this.spinner.show();
    let ticket = new TicketModel();
    ticket.CompanyEventId = this.selectedRoster.CompanyEventId;
    ticket.RespondingCompanyId = this.selectedRoster.RespondingCompanyID;
    ticket.WAC_Id = this.selectedWACUserId;
    ticket.WorkLocation = this.workLocation;
    ticket.dynamicFields = this.companyEvent.existingDynamicFields;
    this.ticketService.createNewTicket(this.selectedFile, ticket ).subscribe((event: HttpEvent<any>) => {

      this.uploadFileSelect.nativeElement.value = '';
      this.selectedFile = null;
      this.uploader.clearQueue();
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.Response:
          this.showUploadProgress = false;
          this.uploadedPercentage = 0;
          this.uploadProgress = 0;
          this.bsModalRef.hide();
          this.spinner.hide();
          this.iziToast.success({title: 'Ticket added', message: 'Ticket added successfully!.', position: 'topRight'});
          break;
        case 1: {
          this.showUploadProgress = true;
          if (Math.round(this.uploadedPercentage) !== Math.round(event['loaded'] / event['total'] * 100)) {
            this.uploadedPercentage = event['loaded'] / event['total'] * 100;
            this.uploadProgress = Math.round(this.uploadedPercentage);
          }
          break;
        }

      }
    }, (err) => {
      this.spinner.hide();
      this.showUploadProgress = false;
      this.uploadedPercentage = 0;
      this.uploadProgress = 0;
      console.log(err);
      if(err.status === 422 || err.status === 400){
        if(err.error.message){
          this.errors.push(err.error.message);
        }else{
          //this.errors.push(err.error);
          this.handleReqErr.handleReqError(err, this.validationError);
        }


      }else{
        this.handleReqErr.handlePageLoadError(err);
      }

    });
  }


  public hasAnotherDropZoneOver: boolean = false;
  @ViewChild('uploadFileSelect')
  uploadFileSelect: ElementRef;
  allowedFileTypes = ['pdf','xps','oxps'];
  public uploader: FileUploader = new FileUploader({url: "",allowedFileType: ['pdf','xps','oxps', 'application']});
  selectedFile: File;

  uploadedPercentage: any;
  uploadProgress: number;
  showUploadProgress: boolean = false;


  public fileOverAnother(e: any): void {
    // this.hasAnotherDropZoneOver = e;
    console.log('File updated');

    if (this.uploader.queue.length === 1) {
      this.selectedFile = this.uploader.queue[0]._file;
      if(this.selectedFile && e[0] && this.selectedFile.name !== e[0].name){
        this.iziToast.error({
          title: 'Invalid file type',
          message: 'Please drop only PDF or XPS file.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: true
        });
      }else if(this.selectedFile.name && this.selectedFile.name.indexOf('.') > -1 ){
        let fileExtension = this.selectedFile.name.substring(this.selectedFile.name.lastIndexOf('.')+1,this.selectedFile.name.length);
        if(this.allowedFileTypes.indexOf(fileExtension) === -1){
          this.selectedFile = null;
          this.uploader.clearQueue();
          this.iziToast.error({
            title: 'Invalid file type',
            message: 'Please drop only PDF or XPS file.',
            position: 'topRight',
            timeout: 5000,
            close: true,
            overlay: true
          });
        }
      }
    }else if(this.uploader.queue.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }else{
      this.iziToast.error({
        title: 'Invalid file type',
        message: 'Please drop only PDF or XPS file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploader.clearQueue();
    }
    this.validateFields();
  }


  fileSelected(e){
    // this.hasAnotherDropZoneOver = e;
    /*console.log(this.uploadFileSelect.nativeElement);
    for(let f of e){
      this.uploader.addToQueue(f);
    }*/
    this.fileOverAnother(e);
    /*if(this.uploadFileSelect.nativeElement.files.length === 1){
      this.selectedFile = this.uploadFileSelect.nativeElement.files[0];
    }else if(this.uploadFileSelect.nativeElement.files.length > 1){
      this.iziToast.error({
        title: 'Multiple Files selected',
        message: 'Only one file is allowed to upload, please select one file.',
        position: 'topRight',
        timeout: 5000,
        close: true,
        overlay: true
      });
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }else{
      this.selectedFile = null;
      this.uploadFileSelect.nativeElement.value = '';
    }*/

    //this.selectedFile = this.uploadFileSelect.files;
    //this.fileName = this.selectedFile.name;
  }

}
