import { ManageCompanyPageComponent } from './../manage-company-page/manage-company-page.component';
import { CommonService } from './../../providers/common-service';
import { HandleRequestError } from './../../providers/handle-request-error';
import { AuthService } from './../../providers/auth-service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { CompanyDetailsModel } from '../../model/company-details-model';
import { CompanyManagementService } from './../../providers/company-management-service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-company-management-page',
  templateUrl: './company-management-page.component.html',
  styleUrls: ['./company-management-page.component.css']
})
export class CompanyManagementPageComponent implements OnInit, OnDestroy {

    pageLoadSubscription: Subscription;
    ngOnDestroy(): void {
      this.pageLoadSubscription.unsubscribe();
    }
    bsModalRef: BsModalRef;
    expanded: any = {};
  constructor(
    public iziToast: Ng2IzitoastService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private companymanageService: CompanyManagementService,
    private handleReqErr: HandleRequestError,
    private modalService: BsModalService,
    private router: Router
  ) { }
  selected: CompanyDetailsModel[] = [];

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if (this.selected && this.company) {
      if (this.selected.length === this.company.length) {
        this.iziToast.warning({
          title: 'All Users selected',
          message: 'You have selected all the users. Total users selected:' + this.selected.length,
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      }
    }

    if (this.selected.length === 1) {
      this.disableEditBtn = false;
    } else {
      this.disableEditBtn = true;
    }

  }

  disableEditBtn = true;

  ngOnInit() {
    this.loadData();
    this.pageLoadSubscription = this.modalService.onHide.subscribe(() => {
      this.loadData();
    });
  }
  loadData() {
    this.spinner.show();
    this.selected = [];
    this.disableEditBtn = true;

    let baseDataCalls = [];
    baseDataCalls.push(this.getAllCompany());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }
  @ViewChild(DatatableComponent) table: DatatableComponent;
  company: CompanyDetailsModel[];
  companyRows: CompanyDetailsModel[];

  getAllCompany() {
    return new Promise((( resolve, reject) => {
      this.companymanageService.getAllCompany().subscribe((res) => {
        console.log(res);
        console.log(res)
        this.company = res;
        this.companyRows = [...this.company];
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }

  dataFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.company.filter((d) => {
      return this.checkFilter(d, val);
    });
    console.log(temp);
    // update the rows[...this.rows]
    this.companyRows = [...temp];
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    this.table.selected = [];
  }

  checkFilter(d, val) {
    if(val){
      try{
        val = val.trim();
      }catch (e) {
        console.log(e);
      }
    }
    return this.matchContent(d.CompanyName, val) || this.matchContent(d.CompanyAddress, val) || this.matchContent(d.TypeName, val) ||
      this.matchContent(d.CompanyCity, val) || this.matchContent(d.CompanyState, val) ||  this.matchContent(d.CompanyZipCode, val) ||
      this.matchContent(d.CompanyDomain, val) || this.matchContent(d.CompanyEmailID, val) || this.matchContent(d.CompanyPhoneNumber, val) ||
      !val;
  }

  matchContent(field, searchVal) {
    if (field) {
      return field.toLowerCase().indexOf(searchVal) !== -1;
    } else {
      return false;
    }
  }

  getCompanyStatusClass({row, column, value}): any {
    return {
      'company-active': value === 'true',
      'company-in-active': value === 'false',
    };
  }

  addCompany() {
    this.bsModalRef = this.modalService.show(ManageCompanyPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditCompany: false},
      backdrop: 'static'
    });
  }


  editCompany() {
    this.bsModalRef = this.modalService.show(ManageCompanyPageComponent, {
      class: 'modal-dialog-centered modal-lg',
      initialState: {isEditCompany: true, editingCompanyName: this.selected[0].CompanyName,
        CompanyId: this.selected[0].CompanyId, TypeId: this.selected[0].TypeId},
      backdrop: 'static'
    });
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
}
