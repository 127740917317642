import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {RosterModel} from '../../model/roster-model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../providers/auth-service';
import {RosterService} from '../../providers/roster-service';
import {HandleRequestError} from '../../providers/handle-request-error';
import {CommonService} from '../../providers/common-service';
import {CommonUtil} from '../../util/common-util';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ModifyMasterEventPageComponent} from '../modify-master-event-page/modify-master-event-page.component';
import {CompanyEventAndRosterDetails, CompanyEventRosterModel} from '../../model/company-event-model';

@Component({
  selector: 'app-company-event-responding-company-page',
  templateUrl: './company-event-responding-company-page.component.html',
  styleUrls: ['./company-event-responding-company-page.component.css']
})
export class CompanyEventRespondingCompanyPageComponent implements OnInit {

  adminCompanyId: number;
  adminCompanyName: string;
  userRole: number;

  rosters: RosterModel[];
  rostersRows: RosterModel[];
  selected: RosterModel[] = [];

  companyEventAndRosterDetails: CompanyEventAndRosterDetails;
  constructor(public iziToast: Ng2IzitoastService,public bsModalRef: BsModalRef, private spinner: NgxSpinnerService, private authService: AuthService,
              private rosterService: RosterService, private handleReqErr: HandleRequestError,
              private commonService: CommonService, private modalService: BsModalService, private commonUtil: CommonUtil,
  ) {
    /*this.modalService.onHide.subscribe(() => {
      this.loadData();
    });*/
  }

  companyEventId: number;

  close(){
    this.bsModalRef.hide();
  }

  loadData() {

    this.adminCompanyId = this.authService.userDetails.companyId;
    this.adminCompanyName = this.authService.userDetails.companyName;
    this.userRole = this.authService.userDetails.userRole;

    this.companyEventId = this.modalService.config.initialState['companyEventId'];

    this.selected = [];
    this.spinner.show();
    let baseDataCalls = [];
    baseDataCalls.push(this.fetchAllRostersForCompanyEvent());
    Promise.all(baseDataCalls).then((r) => {
      this.spinner.hide();
    }).catch((e) => {
      console.log(e);
      this.spinner.hide();
      this.handleReqErr.handlePageLoadError(e);
    });
  }

  ngOnInit() {
    this.loadData();
  }

  showTable = false;
  fetchAllRostersForCompanyEvent() {
    this.showTable = false;
    return new Promise(((resolve, reject) => {
      this.rosterService.fetchAllRostersForCompanyEvent(this.companyEventId).subscribe((res) => {
        console.log(res);
        this.companyEventAndRosterDetails = new CompanyEventAndRosterDetails();
        if (res) {
          this.companyEventAndRosterDetails.companyEventBaseDetails = [];
          this.companyEventAndRosterDetails.companyEventBaseDetails.push(...res[0]);
          if(res.length > 1){
            this.companyEventAndRosterDetails.rosters = [];
            this.companyEventAndRosterDetails.rosters.push(...res[1]);
            for(let ce of this.companyEventAndRosterDetails.companyEventBaseDetails){
              let foundRespondingCompany = false;
              for(let ro of this.companyEventAndRosterDetails.rosters){
                if(ce.RespondingCompanyId === ro.RespondingCompanyID){
                  foundRespondingCompany = true;
                  break;
                }
              }
              if(!foundRespondingCompany){
                let cem = new CompanyEventRosterModel();
                cem.RespondingCompanyID = ce.RespondingCompanyId;
                cem.RespondingCompanyName = ce.RespondingCompanyName;
                this.companyEventAndRosterDetails.rosters.push(cem);
              }
            }
          }else{
            this.companyEventAndRosterDetails.rosters = [];
            for(let ce of this.companyEventAndRosterDetails.companyEventBaseDetails){
              let cem = new CompanyEventRosterModel();
              cem.RespondingCompanyID = ce.RespondingCompanyId;
              cem.RespondingCompanyName = ce.RespondingCompanyName;
              this.companyEventAndRosterDetails.rosters.push(cem);
            }
          }



            this.showTable = true;
          console.log(this.companyEventAndRosterDetails);
        }
        resolve(true);
      }, (err) => {
        reject(err);
      });
    }));
  }


  getCompanyEventClass({row, column, value, adminCompanyName}): any {
    return {
      'our-company-event': row['RequestingCompanyName'] === row['adminCompanyName'],
      'other-company-event': row['RequestingCompanyName'] !== row['adminCompanyName']
    };
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;


  updateFilter(event) {
    console.log(event);
    const val = event.target.value.toLowerCase();
    console.log(val);
    this.table.offset = 0;
    this.table.selected = [];
  }


  prepareCompanyEventName(companyEventId: number) {
    return this.commonUtil.prepareCompanyEventName(companyEventId);
  }

  prepareRosterName(rosterId: number) {
    if(rosterId === 0 || !rosterId){
      return '';
    }
    return this.commonUtil.prepareRosterName(rosterId);
  }


}
