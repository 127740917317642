import {RosterCrewDetailsModel} from './roster-crew-details-model';
import {RosterEquipmentDetailsModel} from './roster-equipment-details-model';
import {TicketCrewModel, TicketModel} from './ticket-model';
import {DynamicField} from './company-event-model';

export class ReportDataModel {
  rosterCrews: RosterCrewDetailsModel[];
  rosterEquipments: RosterEquipmentDetailsModel[];
  ticketData: ReportTicketDataModel;
}

export class ReportTicketDataModel {
  ticketCrews: TicketCrewModel[];
  ticketDynamicFields: DynamicField[];
  tickets: TicketModel[];
}
