import { Component, OnInit } from '@angular/core';
import {ProfileModel} from '../../model/profile-model';
import {ValidationError} from '../../validators/validation-error';
import {UserService} from '../../providers/user-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Ng2IzitoastService} from 'ng2-izitoast';
import {HandleRequestError} from '../../providers/handle-request-error';
import {LoginPageComponent} from '../login-page/login-page.component';
import {AuthService} from '../../providers/auth-service';
import {BsModalRef} from 'ngx-bootstrap';
import {Validators} from '../../validators/validators';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.css']
})
export class UserProfilePageComponent implements OnInit {

  signUp: ProfileModel;
  errors: string[] = [];
  info: string[] = [];
  validationError: ValidationError = new ValidationError();
  validationCPError: ValidationError = new ValidationError();
  oldPassword:string;
  newPassword:string;
  newConfirmPassword:string;
  validateCPField = false;
  cpErrors: string[] = [];
  cpInfo: string[] = [];

  validateCPFields(){
    if(!this.validateCPField){
      return;
    }
    this.validationCPError = new ValidationError();
    let error = new ValidationError();
    let validator = new Validators();
    validator.strongPasswordValidator(error, 'oldPassword', this.oldPassword, 'Old Password');
    validator.strongPasswordValidator(error, 'newPassword', this.newPassword, 'New Password');
    validator.validatePasswordMatch(error, 'newConfirmPassword', this.newConfirmPassword, this.newPassword, 'Confirm Password');
    if (error && Object.keys(error).length > 0) {
      this.validationCPError = error;
      return;
    }
  }

  updatePassword(){
    this.validateCPField = true;
    this.cpErrors = [];
    this.cpInfo = [];
    this.validationCPError = new ValidationError();
    this.validateCPFields();
    if ( this.validationCPError && Object.keys( this.validationCPError).length > 0) {
      return;
    }
    this.spinner.show();
    this.userService.updatePassword(this.oldPassword, this.newPassword).subscribe((res) => {
      this.spinner.hide();
      if (res) {
        this.bsModalRef.hide();
        this.authService.setToken(this.authService.token);
        // this.info.push('Profile updated successfully!.');
        this.iziToast.success({
          title: 'Password updated',
          message: 'Password updated successfully!.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      } else {
        this.cpErrors.push('Internal Server Error, please try again later.');
      }
    }, (err) => {
      this.spinner.hide();
      this.validationCPError = this.handleReqErr.handleReqError(err, this.cpErrors);
    });
  }
  constructor(public bsModalRef: BsModalRef, public iziToast: Ng2IzitoastService, private spinner: NgxSpinnerService,
              private userService: UserService, private handleReqErr: HandleRequestError,
              private authService: AuthService) { }

  ngOnInit() {
    this.signUp = new ProfileModel();
    this.spinner.show();
    this.userService.getUserDetails().subscribe((res)=>{
      this.signUp.mapProfileFields(res);
      this.spinner.hide();
    },(err)=>{
      this.handleReqErr.handlePageLoadError(err);
    });
  }
  validateField = false;
  validateFields(){
    if(!this.validateField){
      return;
    }
    this.validationError = new ValidationError();
    let err = this.signUp.validateProfile();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
  }

  updateUser(){
    this.validateField = true;
    this.errors = [];
    this.info = [];
    this.validationError = new ValidationError();
    let err = this.signUp.validateProfile();
    if (err && Object.keys(err).length > 0) {
      this.validationError = err;
      return;
    }
    this.spinner.show();
    this.userService.updateProfile(this.signUp).subscribe((res) => {
      this.spinner.hide();
      if (res) {
        this.bsModalRef.hide();
        this.authService.setToken(this.authService.token);
       // this.info.push('Profile updated successfully!.');
        this.iziToast.success({
          title: 'Profile updated',
          message: 'Profile updated successfully!.',
          position: 'topRight',
          timeout: 5000,
          close: true,
          overlay: false
        });
      } else {
        this.errors.push('Internal Server Error, please try again later.');
      }
    }, (err) => {
      this.spinner.hide();
      this.validationError = this.handleReqErr.handleReqError(err, this.errors);
    });
  }

  close(){
    this.bsModalRef.hide();
  }

}
