import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {LoginResponseModel} from '../model/login-response-model';
import {environment} from '../../environments/environment';
import {LoginRequestModel} from '../model/login-request-model';
import {ProfileModel} from '../model/profile-model';
import {ServiceResponse} from '../model/service-response';
import {LocalDataService} from './local-data-service';
import {CompanyDetailsModel} from '../model/company-details-model';
import {UserDetailsModel} from '../model/user-details-model';
import {EnvironmentService} from './environment-service';

@Injectable()
export class AuthService {

  token: string;
  activeToken: Subject<string>;
  userDetails: UserDetailsModel;
  inSignUpProcess: boolean = false;
  signUpSuccess: boolean = false;

  constructor(private http: HttpClient, private localDataService: LocalDataService, private environment: EnvironmentService) {
    this.activeToken = new Subject();
  }

  setToken(tempToken:string){
    this.token = tempToken;
    this.activeToken.next(tempToken);
    this.localDataService.storeUserToken(tempToken).subscribe((r) => {
      console.log('Token Updated!')
    });
  }

  prepareHeaders(){
    let token = this.token;
    let headers = new HttpHeaders();
    headers = headers.set('authorization', 'bearer '+token);
    return headers;
  }
  prepareOptions(){
    return {withCredentials: true, headers:this.prepareHeaders()};
  }

  login(loginReq: LoginRequestModel): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'auth/login',loginReq);
  }


  logout(): Observable<ServiceResponse>{
    return this.http.post<ServiceResponse>(this.environment.serviceHostUrl+'auth/logout',{}, this.prepareOptions());
  }

  verifyCode(loginReq: LoginRequestModel): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'auth/verifyCode',loginReq, this.prepareOptions());
  }

  resendVerificationCode(): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'auth/resendVerificationCode',{}, this.prepareOptions());
  }

  refreshToken(): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'auth/refreshToken',{}, this.prepareOptions());
  }

  signup(signupReq: ProfileModel): Observable<LoginResponseModel>{
    return this.http.post<LoginResponseModel>(this.environment.serviceHostUrl+'auth/signup',signupReq);
  }

  getCompanyName(emailId: string): Observable<any>{
    let body = {};
    body['eMail_ID'] = emailId;
    return this.http.post<any>(this.environment.serviceHostUrl+'auth/getCompanyName',body);
  }

  checkExistingDuplicateCompanyName(companyName: string): Observable<any> {
    let body = {};
    body['CompanyName'] = companyName;
    return this.http.post<any>(this.environment.serviceHostUrl + 'auth/checkExistingDuplicateCompanyName', body);
  }

  getAllActiveCompanies(): Observable<CompanyDetailsModel[]> {
    return this.http.post<CompanyDetailsModel[]>(this.environment.serviceHostUrl + 'auth/getAllActiveCompanies', {});
  }


}
